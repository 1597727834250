import axios from 'axios';

const api = axios.create({
  baseURL: 'http://homologacao-api.conecto.com.br:8930/api',
  // baseURL: 'http://localhost:8930/api',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export default api;
