import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FiUser,
  FiTruck,
  FiShield,
  FiList,
  FiMail,
  FiPhone,
  FiMapPin,
  FiCreditCard,
  FiMenu,
  FiShoppingCart,
  FiArrowLeft,
} from 'react-icons/fi';

import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLogout } from 'react-icons/ai';
import {
  Container,
  ContainerMenuMobile,
  NavMenu,
  Button,
  BadgeNumber,
  ContainerLeft,
  ContainerRight,
  Item,
} from './styles';
import {
  openMenuBase,
  openShowModal,
  viewModal,
} from '../../store/modules/parameter/actions';
import { StoreState } from '../../store/createStore';
import SignIn from '../../pages/SignIn';
import { customerClear, signOut } from '../../store/modules/auth/actions';

const NavBar: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();

  const {
    isSignedIn,
    user: { data },
  } = useSelector((state: StoreState) => state.auth);
  const { summary, checkout } = useSelector(
    (state: StoreState) => state.checkout,
  );

  const handledOpenMenu = useCallback(() => {
    dispatch(openMenuBase());
  }, [dispatch]);

  const handledOpenModal = useCallback(() => {
    dispatch(customerClear());
    dispatch(openShowModal());
    dispatch(viewModal(SignIn, { width: '50%', height: '80%' }));
  }, [dispatch]);

  const handledLogout = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return (
    <Container>
      <ContainerMenuMobile>
        <NavMenu>
          <Button onClick={handledOpenMenu}>
            <FiMenu size={24} color="#FFF" />
          </Button>

          <NavLink to="/checkout">
            <BadgeNumber
              number={checkout.products?.length || summary?.items?.length}
            >
              <span>
                {checkout.products?.length
                  ? checkout.products?.length
                  : summary?.items?.length}
              </span>
            </BadgeNumber>
            <FiShoppingCart size={24} color="#FFF" />
          </NavLink>
        </NavMenu>
      </ContainerMenuMobile>

      <ContainerLeft>
        <Item>
          <FiPhone />
          {t('main.navbar.contact')}
        </Item>
        <Item>
          <FiMail />
          {t('main.navbar.contact_mail')}
        </Item>
      </ContainerLeft>
      <ContainerRight>
        <Item>
          <FiMapPin />
          {t('main.navbar.store_locator')}
        </Item>
        <Item>
          <FiList />
          {t('main.navbar.blog')}
        </Item>
        <Item>
          <FiShield />
          {t('main.navbar.warranty')}
        </Item>
        <Item>
          <FiCreditCard />
          {t('main.navbar.payment')}
        </Item>
        <Item marginRight="50">
          <FiTruck />
          {t('main.navbar.delivery')}
        </Item>
        <Item>
          {isSignedIn ? (
            <>
              <FiUser />
              <span style={{ marginRight: '6px' }}>{data.first_name}</span>
              <Button onClick={!isSignedIn ? handledOpenModal : handledLogout}>
                <AiOutlineLogout />
              </Button>
            </>
          ) : (
            <>
              <Button onClick={!isSignedIn ? handledOpenModal : handledLogout}>
                <FiUser />
                <>{t('main.navbar.enter_and_register')} </>
              </Button>
            </>
          )}
        </Item>
      </ContainerRight>
    </Container>
  );
};

export default NavBar;
