import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Root, ContainerTitle, ContainerOptions, Option } from './styles';

interface RadioOptionProps {
  id: string;
  label: string;
}

interface RadioProps {
  title: string;
  name: string;
  options: RadioOptionProps[];
}

const Radio: React.FC<RadioProps> = ({ title, name, options }) => {
  const inputRef = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Root>
      <ContainerTitle>
        <span>{title}</span>
      </ContainerTitle>

      <ContainerOptions>
        {options.map((option, index) => (
          <Option htmlFor={option.id} key={option.id}>
            <input
              type="radio"
              name={fieldName}
              ref={ref => {
                inputRef.current[index] = ref as HTMLInputElement;
              }}
              value={option.id}
              defaultValue={defaultValue}
              defaultChecked={defaultValue === option.id}
            />
            <span>{option.label}</span>
          </Option>
        ))}
      </ContainerOptions>
    </Root>
  );
};

export default Radio;
