import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { FiUser, FiShoppingBag, FiHeart } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { FaSignOutAlt } from 'react-icons/fa';

import { StoreState } from '../../store/createStore';

import {
  Container,
  ContainerMenuLeft,
  ContainerMenuRight,
  ContainerMenu,
  MenuHeader,
  MenuUser,
  MenuName,
  MenuShortCuts,
  MenuShortCutItem,
  MenuFooter,
  MenuAppVersion,
  MenuUserLogout,
  UserLogoutBtn,
} from './styles';
import { signOut } from '../../store/modules/auth/actions';
import { openMenuBase } from '../../store/modules/parameter/actions';
import CategoryMenu from '../../components/CategoryMenu';

const BaseMenu: React.FC = () => {
  const [t] = useTranslation('common');
  const [username, setUsername] = useState('');

  const { openMenu } = useSelector((state: StoreState) => state.parameter);
  const dispatch = useDispatch();

  const { user } = useSelector((state: StoreState) => state.auth);

  const handledSigout = useCallback(() => {
    dispatch(signOut());
    dispatch(openMenuBase());
  }, [dispatch]);

  const handledOpenMenu = useCallback(() => {
    dispatch(openMenuBase());
  }, [dispatch]);

  useEffect(() => {
    // if (user.data.first_name) setUsername(user.data.first_name);
    // console.log('BaseMenu user:', user.data.first_name);
  }, [user]);

  useEffect(() => {
    console.log('username: logou', username);
  }, [username]);

  return (
    <Container open={openMenu}>
      <ContainerMenuLeft>
        <ContainerMenu>
          <MenuHeader>
            <MenuUser>
              <FiUser size={26} color="#FFF" />
              <MenuName>
                <span>{t('main.menu.enter_and_register')}</span>
                {/* {!user.data.first_name ? (
                  <span>{t('main.menu.enter_and_register')}</span>
                ) : (
                  <>
                    <span>Olá</span>
                    <strong>{user.data.first_name}</strong>
                  </>
                )} */}
              </MenuName>

              <AiOutlineClose
                size={22}
                color="#FFF"
                onClick={handledOpenMenu}
              />
            </MenuUser>
          </MenuHeader>
          <MenuShortCuts>
            <MenuShortCutItem>
              <FiShoppingBag size={16} />
              <span>{t('main.menu.my_requests')}</span>
            </MenuShortCutItem>
            <MenuShortCutItem>
              <FiHeart size={16} />
              <span>{t('main.menu.my_bookmarks')}</span>
            </MenuShortCutItem>
            <MenuShortCutItem>
              <FiUser size={16} />
              <span>{t('main.menu.my_account')}</span>
            </MenuShortCutItem>
          </MenuShortCuts>

          <CategoryMenu />

          <MenuFooter>
            <MenuAppVersion>
              <span>Agencia Conecto</span>
              v1.0.0
            </MenuAppVersion>
            <MenuUserLogout>
              <UserLogoutBtn onClick={handledSigout}>
                <span>Sair</span>
                <FaSignOutAlt size={18} color="#FF9b00" />
              </UserLogoutBtn>
            </MenuUserLogout>
          </MenuFooter>
        </ContainerMenu>
      </ContainerMenuLeft>
      <ContainerMenuRight onClick={handledOpenMenu} />
    </Container>
  );
};

export default BaseMenu;
