import React, { useEffect, useCallback, useState } from 'react';

import { BsGridFill } from 'react-icons/bs';
import { FaThList } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import Skeleton from 'react-loading-skeleton';

import { useSelector, useDispatch } from 'react-redux';
import {
  Root,
  ContainerDesktop,
  ContainerMobile,
  ContainerProducts,
  ContainerProductsFilter,
  ContainerProductsFilterDisposition,
  ContainerProductsFilterActions,
  ContainerProductsResult,
  ContainerProductsResultRow,
  ContainerProductsMore,
  Loading,
} from './styles';

import NavBar from '../../components/Header/NavBar';
import Search from '../../components/Header/Search';
import Menu from '../../components/Header/Menu';
import Filter from '../../components/Header/Filter';
import Product from '../../components/Product';

import { StoreState } from '../../store/createStore';
import { ProductData } from '../../store/modules/product/types';
import ProductItem from '../../components/ProductItem';
import { findProductRequest } from '../../store/modules/product/actions';

interface ProductsProps {
  id: string;
  short_description: string;
  price: string;
  image_url: string;
}

interface FilerProductByCatedory {
  current_page: number;
  data: ProductsProps[];
  from: string;
  path: string;
  to: string;
  total: string;
}

interface FilerProductByOrder {
  type?: 'price' | 'created_at';
  order?: 'desc' | 'asc';
}

interface FilerProductByCatedoryBk {
  currentPage: string;
  data: ProductsProps[];
  firstPageURL: string;
  from: string;
  lastPage: string;
  lastPageURL: string;
  nextPageURL: string;
  path: string;
  perPage: string;
  prevPageURL: string;
  to: string;
  total: string;
}

interface ProductsResponseProps {
  products: ProductsProps[];
}

interface ProductsListProps {
  product: ProductsProps[];
}

const ProductList: React.FC = () => {
  const [productsListRep, setproductsListRep] = useState<ProductData[]>([]);

  const dispacth = useDispatch();
  const { loading, error, products, filters, response } = useSelector(
    (state: StoreState) => state.product,
  );

  const handleMoreProducts = useCallback(async () => {
    try {
      if (filters.moreProducts) filters.moreProducts += 18;
      // dispacth(findProductRequest(filters));
    } catch (err) {
      console.log('handleMoreProducts: err', err);
    }
  }, []);

  const handleOrderProducts = useCallback(async event => {
    try {
      const order = event.target.value.split('_');
      // console.log(order);
      filters.order = order[1];
      // dispacth(findProductRequest(filters));
    } catch (err) {}
  }, []);

  useEffect(() => {
    // initProductList();
    setproductsListRep(products);
  }, [products]);

  useEffect(() => {
    // dispacth(findProductRequest(filters));
  }, []);

  return (
    <Root>
      <ContainerMobile>
        <ContainerProductsResultRow mobile />
      </ContainerMobile>
      <ContainerDesktop width="100%" height="100%">
        <Filter categoryList={false} categoryFilter />
        <ContainerProducts>
          <ContainerProductsFilter>
            <ContainerProductsFilterDisposition>
              <BsGridFill size="36" color="var(--primary)" />
              <FaThList size="36" color="var(--gray-light)" />
              <span>
                {`Mostrando ${response.to} de ${response.total} resultados`}
              </span>
            </ContainerProductsFilterDisposition>
            <ContainerProductsFilterActions>
              <span>Filtro: </span>
              <select onChange={handleOrderProducts}>
                <option value="price_asc">ordem menor preço</option>
                <option value="price_desc">ordem maior preço</option>
              </select>
            </ContainerProductsFilterActions>
          </ContainerProductsFilter>
          <ContainerProductsResult>
            <ContainerProductsResultRow>
              {loading ? (
                <>
                  <Skeleton
                    style={{ marginBottom: '16px', marginRight: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px', marginRight: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px', marginRight: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px', marginRight: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px', marginRight: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px', marginRight: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px', marginRight: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px', marginRight: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                  <Skeleton
                    style={{ marginBottom: '16px' }}
                    duration={1}
                    width={185}
                    height={230}
                  />
                </>
              ) : (
                <>
                  {productsListRep &&
                    productsListRep.map(product => (
                      <ProductItem
                        key={product.product_id}
                        product={product}
                        width={1800}
                        height={260}
                        loading={loading}
                      />
                    ))}
                </>
              )}
            </ContainerProductsResultRow>
            {error ? (
              <ContainerProductsMore>
                <h3>Falha ao consulta os produtos :)</h3>
              </ContainerProductsMore>
            ) : (
              <ContainerProductsMore onClick={() => handleMoreProducts()}>
                <AiOutlineLoading3Quarters size="32" />
                <span>Carregar mais Produtos</span>
              </ContainerProductsMore>
            )}
          </ContainerProductsResult>
        </ContainerProducts>
      </ContainerDesktop>
    </Root>
  );
};

export default ProductList;
