import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100vh;

  max-width: 1500px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: 191px;
`;

export const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  flex-direction: row;
`;
