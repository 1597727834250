/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { action } from 'typesafe-actions';
import { ModalContentProps } from '../../../layout/Master/styles';

import { ParameterActionTypes, ParameterProps } from './types';

export function openMenu(data: ParameterProps) {
  return action(ParameterActionTypes.OPEN_MENU, {
    data,
  });
}

export function openMenuBase() {
  return action(ParameterActionTypes.OPEN_MENU_BASE);
}

export function openShowModal(openForce?: boolean) {
  return action(ParameterActionTypes.OPEN_MODAL, { openForce });
}

export function viewModal(
  content: React.ComponentType,
  props?: ModalContentProps,
) {
  return action(ParameterActionTypes.VIEW_MODAL, { content, props });
}
