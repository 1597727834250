import styled, { css } from 'styled-components';

import { layout } from '../../../package.json';

interface AddressItemProps {
  selected?: number;
}

export const ContainerMyAddress = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;

  @media (max-width: ${layout.mobile}) {
    justify-content: center;
    width: 100%;
    padding: 10px;
  }
`;

export const MyAddressTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 28px;
    font-weight: 600;
  }

  @keyframes moreaddress {
    0% {
      right: 0px;
    }
    50% {
      right: 20px;
    }
    100% {
      right: 0px;
    }
  }

  @media (max-width: ${layout.mobile}) {
    justify-content: space-between;
    padding-bottom: 0px;
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
`;

export const AddressTitle = styled.div`
  grid-area: title;

  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  span {
    color: var(--gray);
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 15px;
  }
`;

export const AddressMore = styled.div`
  display: none;
  font-size: 36px;
  position: relative;
  top: 0;
  right: 0;
  font-size: 36px;
  font-weight: 700;

  animation: moreaddress 1s infinite;
  color: var(--primary);

  @media (max-width: ${layout.mobile}) {
    display: block;
  }
`;

export const AddressItemAdd = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  grid-template-areas: 'logo title';

  margin-bottom: 20px;
  margin-left: 16px;
  min-width: 300px;
  max-width: 400px;

  border: 1px solid var(--cardborder);
  background: var(--cardbg);
  border-radius: 10px;
  padding: 5px;

  justify-content: center;
  align-items: center;

  :hover {
    background: var(--cardhoverbg);
    border: 1px solid var(--cardhoverborder);
    cursor: pointer;
  }
`;

export const AddressItemAddLogo = styled.div`
  grid-area: logo;
`;

export const AddressItemAddText = styled.div`
  grid-area: title;
`;

export const ContainerAddressLists = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  @media (max-width: ${layout.mobile}) {
    display: -webkit-box;
    overflow-x: auto;
  }
`;

export const AddressItem = styled.div<AddressItemProps>`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 20px 20px 20px 20px 20px;

  grid-template-areas:
    'title title'
    'logo street'
    'logo neigh'
    'logo citystate'
    'logo zip'
    'logo action';

  margin-bottom: 20px;
  margin-left: 16px;
  min-width: 350px;
  max-width: 400px;

  border: 1px solid var(--cardborder);
  background: var(--cardbg);
  border-radius: 10px;
  padding: 5px;

  font-family: 'Roboto';

  ${props =>
    props.selected &&
    css`
      background: var(--cardselbg);
      border: 1px solid var(--cardselborder);
    `}

  :hover {
    background: var(--cardhoverbg);
    border: 1px solid var(--cardhoverborder);
    cursor: pointer;
  }
`;

export const AddressLogo = styled.div`
  grid-area: logo;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;

  margin-top: 16px;
`;

export const AddressStreet = styled.div`
  grid-area: street;

  span {
    color: var(--gray);
    font-size: 16px;
    font-family: 'Roboto';
    cursor: pointer;
  }
`;

export const AddressNeigh = styled.div`
  grid-area: neigh;

  span {
    color: var(--gray);
    font-size: 16px;
    cursor: pointer;
  }
`;

export const AddressSeparator = styled.span`
  color: var(--gray);
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
`;

export const AddressCityState = styled.div`
  grid-area: citystate;

  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const AddressCity = styled.div`
  span {
    color: var(--gray);
    font-size: 18px;
    font-family: 'Roboto';
    cursor: pointer;
  }
`;

export const AddressState = styled.div`
  span {
    color: var(--gray);
    font-size: 16px;
    font-family: 'Roboto';
    cursor: pointer;
  }
`;

export const AddressZip = styled.div`
  grid-area: zip;

  span {
    color: var(--gray);
    font-size: 16px;
    font-family: 'Roboto';
    cursor: pointer;
  }
`;

export const AddressAction = styled.div`
  grid-area: action;
  margin-top: 16px;
  margin-right: 5px;

  span {
    color: var(--primary);
    font-size: 16px;
    font-family: 'Roboto';
    cursor: pointer;
  }
`;
