import React, { useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';

import { useSelector, useDispatch } from 'react-redux';
import ProductItem from '../ProductItem';

import {
  Container,
  ContainerProducts,
  ContainerProductsResult,
  ContainerProductsResultRow,
} from './styles';
import { StoreState } from '../../store/createStore';
import { findProductRequest } from '../../store/modules/product/actions';

const ProdutctsList: React.FC = () => {
  const dispatch = useDispatch();

  const { loading, error, products, filters, response } = useSelector(
    (state: StoreState) => state.product,
  );

  useEffect(() => {
    dispatch(findProductRequest(filters));
  }, [dispatch]);

  return (
    <Container>
      <ContainerProducts>
        <ContainerProductsResult>
          <ContainerProductsResultRow>
            {loading ? (
              <>
                <Skeleton
                  style={{ marginBottom: '16px', marginRight: '16px' }}
                  duration={2}
                  width={160}
                  height={176}
                />
                <Skeleton
                  style={{ marginBottom: '16px', marginRight: '16px' }}
                  duration={1}
                  width={160}
                  height={176}
                />
                <Skeleton
                  style={{ marginBottom: '16px', marginRight: '16px' }}
                  duration={1}
                  width={160}
                  height={176}
                />
                <Skeleton
                  style={{ marginBottom: '16px', marginRight: '16px' }}
                  duration={2}
                  width={160}
                  height={176}
                />
              </>
            ) : (
              <>
                {products &&
                  products.map(product => (
                    <ProductItem
                      key={product.product_id}
                      product={product}
                      width={1800}
                      height={260}
                      loading={loading}
                    />
                  ))}
              </>
            )}
          </ContainerProductsResultRow>
        </ContainerProductsResult>
      </ContainerProducts>
    </Container>
  );
};

export default ProdutctsList;
