/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { CategoryActionTypes } from './types';
import api from '../../../services/api';

export function* findCategorys({}: ActionType<
  typeof actions.findCategoryRequest
>) {
  try {
    const { data } = yield call(api.get, 'category/categories');

    yield put(actions.findCategorySuccess(data.data));
  } catch (err) {
    yield put(actions.findCategoryFailure());
  }
}

export function* findCategorySub({
  payload,
}: ActionType<typeof actions.findCategorySubRequest>) {
  try {
    const { filters } = payload;

    const { data } = yield call(api.get, `category/categories/${filters.id}`);

    yield put(
      actions.findCategorySubSuccess(
        data.data.childrens,
        data.data.parent_id,
        data.data.short_description,
      ),
    );
  } catch (err) {
    yield put(actions.findCategorySubFailure());
  }
}

export default all([
  takeLatest(CategoryActionTypes.LOAD_REQUEST, findCategorys),
  takeLatest(CategoryActionTypes.LOAD_REQUEST_SUB, findCategorySub),
]);
