/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import product from './product/sagas';
import category from './category/sagas';
import checkout from './checkout/sagas';
import parameter from './parameter/sagas';

export default function* rootSaga() {
  return yield all([auth, product, category, checkout, parameter]);
}
