import React from 'react';

import {
  Container,
  AccountTitle,
  ContainerFormAccount,
  FormAccount,
  FormAccountBox,
  FormAccountTitle,
  FormAccountInput,
} from './styles';

const AccountCreate: React.FC = () => {
  return (
    <Container>
      <AccountTitle>Informações Pessoais</AccountTitle>
      <ContainerFormAccount>
        <FormAccount>
          <FormAccountBox>
            <FormAccountTitle>Nome</FormAccountTitle>
            <FormAccountInput name="name" type="text" />
          </FormAccountBox>
          <FormAccountBox>
            <FormAccountTitle>CPF</FormAccountTitle>
            <FormAccountInput name="name" type="text" />
          </FormAccountBox>
          <FormAccountBox>
            <FormAccountTitle>Data de Nascimento</FormAccountTitle>
            <FormAccountInput name="name" type="text" />
          </FormAccountBox>
          <FormAccountBox>
            <FormAccountTitle>Telefone</FormAccountTitle>
            <FormAccountInput name="name" type="text" />
          </FormAccountBox>
        </FormAccount>
      </ContainerFormAccount>
    </Container>
  );
};

export default AccountCreate;
