import React, { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import logoImg from '../../../assets/logo.png';

import {
  Container,
  Content,
  AnimationContainer,
  Title,
  ColInput,
} from './styles';

import { StoreState } from '../../../store/createStore';
import { routesEndpoints } from '../../../routes/endpoint';

const SignUpStep05: React.FC = () => {
  const history = useHistory();
  const {
    user: {
      data: { first_name, last_name },
    },
  } = useSelector((state: StoreState) => state.auth);

  useEffect(() => {
    history.push(routesEndpoints.CONFIRMATION_MAIL);
  }, [history]);

  const handleFinish = useCallback(async () => {
    try {
      history.push(routesEndpoints.HOME);
    } catch (err) {
      console.log('error:', err);
    }
  }, []);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="ConectoVarejo" />

          <form>
            <Title>
              <span>Endereço de e-mail confirmado</span>
            </Title>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <p>
                <b>{`${first_name} ${last_name}`}</b>, pronto! Seu e-mail está
                confirmado. Agora você pode aproveitar todas as ofertas do Super
                Conecto!
              </p>
            </ColInput>

            <ColInput>
              <CountdownCircleTimer
                isPlaying
                duration={10}
                colors={[
                  ['#c10101', 0.33],
                  ['#e6ea0f', 0.33],
                  ['#FF9000', 0.33],
                ]}
                onComplete={() => {
                  handleFinish();
                  return [false, 0]; // repeat animation in 1.5 seconds
                }}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </ColInput>
          </form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUpStep05;
