import React, { useCallback, useState } from 'react';

import { FiPlus } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';

import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';

import visa from 'payment-icons/svg/single/visa.svg';

import { useDispatch, useSelector } from 'react-redux';
import ReactCreditCard from '@repay/react-credit-card';
import { AiOutlineCheck } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import {
  ContainerMyPayment,
  MyPaymentTitle,
  PaymentMore,
  ContainerPaymentLists,
  ContainerPaymentItem,
  PaymentItemSelected,
  PaymentItemAdd,
  PaymentItemAddLogo,
  PaymentItemAddText,
} from './styles';
import { StoreState } from '../../store/createStore';
import { userPaymentSelectedRequest } from '../../store/modules/auth/actions';

interface CardPaymentProps {
  title: string;
  summary?: boolean;
}

const CardPayment: React.FC<CardPaymentProps> = ({
  title,
  summary: summaryFlag,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { token } = useSelector((state: StoreState) => state.auth);

  const {
    user: { methods },
  } = useSelector((state: StoreState) => state.auth);

  const handlePayment = useCallback(summary_id => {
    console.log('handlePayment:', summary_id);
    if (summary_id !== undefined)
      dispatch(userPaymentSelectedRequest(summary_id, token));
  }, []);

  const handlePaymentADD = useCallback(() => {
    console.log('handlePaymentADD');
    history.push('/account/payment');
  }, []);
  return (
    <ContainerMyPayment>
      <MyPaymentTitle>
        <PaymentMore>{!summaryFlag && <FaLongArrowAltLeft />}</PaymentMore>
        <span>{title}</span>
        <PaymentMore>
          <FaLongArrowAltRight />
        </PaymentMore>
      </MyPaymentTitle>
      <ContainerPaymentLists>
        <>
          {methods &&
            methods.map(method => (
              <ContainerPaymentItem
                onClick={() => handlePayment(method.id)}
                selected={method.selected}
              >
                {method.selected === 1 && (
                  <PaymentItemSelected>
                    <AiOutlineCheck />
                  </PaymentItemSelected>
                )}

                <ReactCreditCard
                  expiration={method.expiry_date}
                  expirationAfter="validthru"
                  expirationBefore="month/year"
                  hasRadialGradient={false}
                  hasShadow={false}
                  name={method.full_name}
                  number={method.card_number}
                  placeholderName="FULL NAME"
                />
              </ContainerPaymentItem>
            ))}
        </>
        <PaymentItemAdd onClick={handlePaymentADD}>
          <PaymentItemAddLogo>
            <FiPlus size="48" color="var(--green)" />
          </PaymentItemAddLogo>
          <PaymentItemAddText>
            <span>Adicionar Novo Pagamento</span>
          </PaymentItemAddText>
        </PaymentItemAdd>
      </ContainerPaymentLists>
    </ContainerMyPayment>
  );
};

export default CardPayment;
