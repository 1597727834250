/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { CategoryState, CategoryAction, CategoryActionTypes } from './types';

const INITIAL_STATE: CategoryState = {
  categorys: [],
  categorysSub: [],
  error: false,
  loading: false,
  parent_id: '',
  short_description: '',
  filters: {
    id: '',
    short_description: '',
  },
};

export default function category(
  state = INITIAL_STATE,
  action: CategoryAction,
): CategoryState {
  switch (action.type) {
    case CategoryActionTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case CategoryActionTypes.LOAD_SUCCCES:
      return {
        ...state,
        categorys: action.payload.data,
        categorysSub: [],
        loading: false,
        error: false,
      };
    case CategoryActionTypes.LOAD_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case CategoryActionTypes.LOAD_REQUEST_SUB:
      return {
        ...state,
        filters: {
          id: action.payload.filters.id,
        },
        categorysSub: [],
        loading: true,
        error: false,
      };
    case CategoryActionTypes.LOAD_SUCCCES_SUB:
      action.payload.data.filter(category => {
        category.open = false;
        return category;
      });

      return {
        ...state,
        categorysSub: action.payload.data,
        parent_id: action.payload.parent_id,
        short_description: action.payload.short_description,
        loading: false,
        error: false,
      };
    case CategoryActionTypes.LOAD_FAILURE_SUB:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case CategoryActionTypes.CATEGORY_OPEN:
      state.categorys.filter(category => {
        if (category.id === action.payload.category_id) {
          category.open = !category.open;
        } else {
          category.open = false;
        }
        return category;
      });
      return {
        ...state,
        categorys: state.categorys,
        error: true,
        loading: false,
      };

    case CategoryActionTypes.CATEGORY_SUB_OPEN:
      console.log(
        'CategoryActionTypes.CATEGORY_SUB_OPEN: ',
        action.payload.category_id,
        state.categorysSub,
      );
      state.categorysSub.filter(category => {
        if (category.id === action.payload.category_id) {
          console.log('children.id: ', category.id);
          category.open = !category.open;
        } else {
          category.open = false;
        }
        return category;
      });
      return {
        ...state,
        categorysSub: state.categorysSub,
        error: true,
        loading: false,
      };

    default:
      return state;
  }
}
