import React, { useCallback, useEffect, useRef } from 'react';
import {
  FiMail,
  FiLock,
  FiPhone,
  FiUser,
  FiArrowLeft,
  FiArrowRight,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FaBirthdayCake, FaFacebookSquare } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getValidationErrors from '../../../utils/getValidationErrors';

// import logoImg from '../../assets/logo.svg';
import logoImg from '../../../assets/logo.png';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import {
  Container,
  ContainerStep,
  Content,
  AnimationContainer,
  Title,
  ColInput,
} from './styles';
import Radio from '../../../components/Radio';
import {
  customerClear,
  customerStepRequest,
  customerUpdateRequest,
} from '../../../store/modules/auth/actions';
import {
  AuthActionStep,
  CustomerData,
} from '../../../store/modules/auth/types';

import { viewModal } from '../../../store/modules/parameter/actions';
import { StoreState } from '../../../store/createStore';
import SignUpStep01 from '../SignUpStep01';
import SignUpStep03 from '../SignUpStep03';

const SignUpStep02: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const {
    token,
    step,
    user: {
      data: { email },
    },
  } = useSelector((state: StoreState) => state.auth);

  const handleSubmit = useCallback(async (data: CustomerData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        password: Yup.string().required('CPF obrigatório'),
        password_confirm: Yup.string()
          .required('Data de Nascimento obrigatória')
          .min(6, 'No mínimo 6 digitos'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const customerData: CustomerData = {
        new_password: data.password,
        new_password_confirmation: data.password_confirm,
        email,
      };

      dispatch(
        customerUpdateRequest(customerData, token, AuthActionStep.CST_STEP_04),
      );
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        console.log('handleSubmit errors:', errors);
      }
    }
  }, []);

  const handleStep = useCallback(() => {
    dispatch(customerStepRequest(AuthActionStep.CST_STEP_02));
    dispatch(viewModal(SignUpStep01, { width: '50%', height: '80%' }));
  }, []);

  useEffect(() => {
    if (step === 'CST_STEP_04') {
      dispatch(customerClear());
      dispatch(viewModal(SignUpStep03, { width: '50%', height: '80%' }));
    }
  }, [step]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <ContainerStep>
            <FiArrowLeftCircle
              size={36}
              color="var(--primary)"
              onClick={handleStep}
            />
            <img src={logoImg} alt="ConectoVarejo" />
            <FiArrowRightCircle size={36} color="var(--primary)" />
          </ContainerStep>

          <Form
            initialData={{
              password: '123456789',
              password_confirm: '123456789',
            }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Title>
              <span>Vamos configurar o dados de acesso</span>
            </Title>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <Input
                icon={FiUser}
                type="password"
                name="password"
                placeholder="Senha*"
              />
              <Input
                icon={FiUser}
                type="password"
                name="password_confirm"
                placeholder="Senha Confirma*"
              />
            </ColInput>

            <ColInput>
              <Button
                button={{ type: 'submit' }}
                atributes={{
                  buttonType: 'primary',
                  textColor: '#fff',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
              >
                Continuar
              </Button>
            </ColInput>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUpStep02;
