import React, { useCallback } from 'react';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import logoImg from '../../../assets/logo.png';

import { Container, Content, AnimationContainer } from './styles';

import { routesEndpoints } from '../../../routes/endpoint';
import { openShowModal } from '../../../store/modules/parameter/actions';
import { customerSendMailRequest } from '../../../store/modules/auth/actions';
import { StoreState } from '../../../store/createStore';

const SignUpStep03: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    user: {
      data: { first_name, email },
    },
  } = useSelector((state: StoreState) => state.auth);

  const handleFinish = useCallback(async () => {
    try {
      dispatch(openShowModal());
      if (first_name && email)
        dispatch(customerSendMailRequest(first_name, email));

      history.push(routesEndpoints.VERIFICATION_MAIL);
    } catch (err) {
      console.log('error:', err);
    }
  }, [first_name, email]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="ConectoVarejo" />
          <h1>Parabéns agora só falta vc confirma seu email</h1>
          <CountdownCircleTimer
            isPlaying
            duration={5}
            colors={[
              ['#c10101', 0.33],
              ['#e6ea0f', 0.33],
              ['#FF9000', 0.33],
            ]}
            onComplete={() => {
              handleFinish();
              return [false, 0]; // repeat animation in 1.5 seconds
            }}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUpStep03;
