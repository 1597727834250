import styled from 'styled-components';

import { layout } from '../../../package.json';

export const Container = styled.div`
  width: 100%;

  margin-top: 4px;
  /* max-width: 1500px; */
  padding: 0;
  div {
    height: 600px;

    img {
      width: 100%;
      height: 100%;
    }

    p {
      color: #fff;
    }
  }

  @media (max-width: ${layout.mobile}) {
    div {
      height: 300px;
    }
  }
`;
