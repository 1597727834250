/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createStore, applyMiddleware, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { AuthAction, AuthState } from './modules/auth/types';
import { ProductAction, ProductState } from './modules/product/types';
import { CategoryAction, CategoryState } from './modules/category/types';
import { ParameterAction, ParameterState } from './modules/parameter/types';
import rootReducer from './modules/rootReducer';
import { checkoutState } from './modules/checkout/types';

export interface StoreState {
  auth: AuthState;
  product: ProductState;
  category: CategoryState;
  checkout: checkoutState;
  parameter: ParameterState;
}

export type StoreAction =
  | AuthAction
  | ProductAction
  | CategoryAction
  | ParameterAction;

const persistConfig = {
  key: 'cncvarejo',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default (middlewares: Middleware[]) => {
  const enhancer = applyMiddleware(...middlewares);

  const store = createStore(persistedReducer, composeWithDevTools(enhancer));

  const persistor = persistStore(store);

  return { store, persistor };
};
