import styled, { css } from 'styled-components';

import { layout } from '../../../package.json';

interface ContainerBoxProps {
  width?: string;
  height?: string;
  border?: boolean;
  borderColor?: string;
}
interface ContainerMobileProps {
  mobile?: boolean;
}

export const Root = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f9f9f9;
`;

export const ContainerMobile = styled.div<ContainerBoxProps>`
  width: 100%;
  height: 100vh;
  display: none;
  align-items: flex-start;
  justify-content: center;
  background: #f9f9f9;

  position: relative;

  @media (max-width: ${layout.desktop}) {
    display: flex;
  }
`;

export const ContainerDesktop = styled.div<ContainerBoxProps>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: #f9f9f9;

  position: relative;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}

  ${props =>
    props.border &&
    css`
      border: 1px solid red;
    `};

  ${props =>
    props.borderColor &&
    css`
      border: 1px solid ${props.borderColor};
    `};

  @media (max-width: ${layout.desktop}) {
    display: none;
  }

`;

export const ContainerProducts = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 69%;
  height: 100%;

  margin-left: 16px;
  margin-top: 60px;

  color: #646464;
  background: #f9f9f9;
`;

export const ContainerProductsResult = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  height: 100%;
  padding: 20px;
`;

export const ContainerProductsResultRow = styled.div<ContainerMobileProps>`
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  flex-direction: row;

  ${props =>
    props.mobile &&
    css`
      flex-direction: column;
    `};
`;

export const ContainerProductsMore = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  height: 80px;
  width: 100%;

  margin-top: 60px;
  margin-bottom: 120px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 30px;
    width: 350px;
    cursor: pointer;
  }
`;

export const ContainerProductsFilterDisposition = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  width: 500px;

  svg {
    margin-left: 16px;
    :hover {
      cursor: pointer;
      color: var(--primary) !important;
    }
  }

  span {
    margin-left: 20px;
    font-size: 12px;
  }
`;

export const ContainerProductsFilterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 300px;

  border: 1px solid #e2e2e2;
  padding: 5px;
  border-radius: 20px;

  span {
    margin-right: 16px;
  }

  select {
    background-color: white;
    border: none;

    display: inline-block;
    font: inherit;
    color: #949494;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(
        45deg,
        transparent 50%,
        var(--primary) 50%
      ),
      linear-gradient(135deg, var(--primary) 50%, transparent 50%),
      linear-gradient(to right, var(--primary), var(--primary));
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }
`;

export const ContainerProductsFilter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
`;

export const Loading = styled.div`
  width: 300px;
  height: 80px;
  border: 1px solid red;

  span {
    color: #000;
    font-size: 30px;
  }
`;
