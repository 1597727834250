import styled, { css } from 'styled-components';

import { layout } from '../../../package.json';

export const Root = styled.div`
  z-index: 9999;
`;

interface MenuProps {
  open?: boolean;
}

export const ContainerBGMenu = styled.div<MenuProps>`
  display: flex;
  flex-direction: row;

  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  ${props =>
    props.open === false &&
    css`
      display: none;
    `}
`;

export const ContainerMenuLeft = styled.div`
  width: 80vw;
  height: 100vh;
`;

export const ContainerMenuRight = styled.div`
  width: 20vw;
  height: 100vh;
`;

export const ContainerMenu = styled.div`
  background-color: #fff;
  width: 80vw;

  max-width: 480px;
  height: 100vh;
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 40px;

  background-color: var(--primary);
  box-shadow: 0 3px 6px rgba(255, 107, 0, 0.36);
`;

export const MenuUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 40px;

  padding-left: 6px;
  padding-right: 6px;
`;

export const MenuName = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  width: 80%;

  color: #fff;
  span {
    font-size: 11px;
  }

  strong {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const MenuShortCuts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const MenuShortCutItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 33%;

  span {
    font-size: 10px;
    color: var(--gray);
    margin-top: 6px;
  }

  svg {
    color: var(--primary);
  }
`;

export const MenuGategory = styled.div`
  margin-top: 16px;
`;

export const MenuGategoryTitle = styled.div`
  border-bottom: 1px solid #ccc;
  padding-right: 6px;
  padding-left: 6px;
  padding-bottom: 6px;

  span {
    color: var(--primary);
    font-weight: 700;
  }
`;

export const MenuGategoryList = styled.ul``;

export const MenuGategoryItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  border-bottom: 1px solid #ccc;

  width: 100%;
`;

interface MenuGategoryItemTitleProps {
  main?: boolean;
  submenu?: number;
}

export const MenuGategoryItemTitle = styled.div<MenuGategoryItemTitleProps>`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 6px;

  span {
    font-size: 13px;
    font-weight: 500;
    color: #847979;
    ${props =>
      props.main &&
      css`
        font-weight: 800;
      `}

    ${props =>
      props.submenu &&
      css`
        padding-left: ${props.submenu}px;
        color: #a9a7a7;
      `}
  }
`;

export const MenuFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* position: absolute; */
  bottom: 0;
  left: 0;

  width: 80vw;
  height: 50px;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.86);
`;

export const MenuAppVersion = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  width: 80vw;
  height: 50px;

  padding-left: 10px;
  font-size: 11px;

  span {
    font-size: 17px;
    color: var(--primary);
  }
`;

export const MenuUserLogout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 80vw;
  height: 50px;

  padding-right: 10px;
`;

export const UserLogoutBtn = styled.div`
  border: 1px solid var(--primary);
  width: 80px;
  height: 30px;
  border-radius: 10px;
  background-color: #ffdfb5;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-right: 6px;
    color: var(--primary);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100vw;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  max-width: 1500px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: 50px;
  box-shadow: 0 3px 6px rgba(255, 107, 0, 0.36);
`;

export const NavMenu = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 6px 16px;
  margin-top: 10px;
`;

interface BadgeNumberProps {
  number?: number;
}

export const BadgeNumber = styled.div<BadgeNumberProps>`
  width: 18px;
  height: 18px;
  border-radius: 18px;

  border: 1px solid var(--btn-finish);

  position: absolute;
  margin-left: 13px;
  margin-top: -10px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--btn-finish);

  span {
    color: #fff;
    font-size: 12px;
    font-weight: 800;
  }

  ${props =>
    props.number === undefined &&
    css`
      display: none;
    `}

    @media (max-width: ${layout.mobile}) {
      span {
        font-size: 10px;
      }
    }
`;

export const Button = styled.div``;

export const ContainerProducts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  padding-left: 318px;
  padding-top: 10px;

  @media (max-width: ${layout.mobile}) {
    padding-left: 0px;
  }

  @media (max-width: ${layout.desktop}) {
  }

  @media (max-width: ${layout.desktop_wide}) {
  }
`;
