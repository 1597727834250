import styled from 'styled-components';

export const Container = styled.div`
  width: 270px;
`;

export const MenuCategoryFilter = styled.div`
  background: #fbfbfb;

  @media (min-width: 1200px) {
    width: 236px;
  }
`;

export const MenuBreadcrumb = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const MenuBreadcrumbItem = styled.div`
  font-size: 11px;
  color: #646464;
  :hover {
    color: var(--primary);
    cursor: pointer;
  }
`;

export const MenuBreadcrumbDivider = styled.div`
  color: #646464;
  height: 30px;

  margin: 3px 3px;
  margin-top: 16px;
`;

export const FilterSelectTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;

  span {
    font-size: 20px;
    font-weight: bold;
    color: #646464;
  }
`;

export const FilterGroupBox = styled.div`
  width: 270px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`;

export const FilterGroup = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ccc;

  padding-bottom: 16px;

  :last-child {
    padding-bottom: 0px;
  }
`;

export const FilterTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-left: 6px;
  margin-top: 16px;
  margin-bottom: 16px;
  span {
    font-size: 14px;
    font-weight: bold;
    color: #303030;
    :hover {
      color: var(--primary);
      cursor: pointer;
    }
  }
`;

export const FilterOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-left: 26px;
  margin-bottom: 6px;
  span {
    font-size: 12px;
    font-weight: bold;
    color: #646464;

    :hover {
      color: var(--primary);
      cursor: pointer;
    }
  }
`;

export const FilterInput = styled.div`
  width: 210px;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  background-color: #ffffff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 20px;

  span {
    margin-left: 16px;
  }

  svg {
    margin-right: 16px;
  }
`;
export const FilterClear = styled.div`
  margin-top: 16px;
  height: 20px;

  margin-left: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    color: var(--primary);
    font-size: 12px;
    font-weight: 600;
  }
`;

export const FilterRanger = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  .input-range {
    width: 70%;
    color: var(--primary);
  }

  .input-range__track--active,
  .input-range__slider {
    background: var(--primary);
    border: 1px solid var(--primary);
  }
`;
