import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import {
  Root,
  ContainerInput,
  ContainerCheck,
  ContainerError,
  Error,
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  IconLeft?: boolean | true;
  BgColor?: string;
  Color?: string;
  Padding?: string;
  Border?: string;
  BorderBottomRadiusNo?: boolean;
  titleCheck?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  IconLeft,
  BgColor,
  Padding,
  Color,
  Border,
  BorderBottomRadiusNo,
  titleCheck,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const hanleadInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const hanleadInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Root>
      {!titleCheck && (
        <ContainerInput
          isErrored={!!error!}
          isFilled={isFilled}
          isFocused={isFocused}
          BgColor={BgColor}
          Color={Color}
          Padding={Padding}
          Border={Border}
          BorderBottomRadiusNo={BorderBottomRadiusNo}
          error={!!error!}
        >
          {Icon && IconLeft && <Icon />}

          <input
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
            onFocus={hanleadInputFocus}
            onBlur={hanleadInputBlur}
          />

          {Icon && !IconLeft && <Icon />}
        </ContainerInput>
      )}
      {titleCheck && (
        <ContainerCheck>
          <input
            type="checkbox"
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
            onFocus={hanleadInputFocus}
            onBlur={hanleadInputBlur}
          />
          <span>{titleCheck}</span>
        </ContainerCheck>
      )}

      {error && (
        <ContainerError>
          <span>{error}</span>
        </ContainerError>
      )}
    </Root>
  );
};

export default Input;
