import styled, { css } from 'styled-components';

import { layout } from '../../../package.json';

interface ProductItemProps {
  width?: number | 195;
  height?: number | 270;
}

export const Root = styled.div`
  margin-bottom: 4px;
  margin-left: 4px;
  /* position: relative; */

  background: #fff;

  @media (max-width: ${layout.mobile}) {
    position: none;
  }
`;

export const Container = styled.a<ProductItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;
  text-decoration: none;
  color: var(--fontcolor);

  width: 195px;
  height: 270px;

  @media (max-width: ${layout.mobile}) {
    display: flex;
    align-items: center;
    flex-direction: column;

    grid-template-columns: 210px 210px;

    width: 140px;
    height: 150px;

    max-width: 460px;
    margin: 10px auto;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  &:hover {
    border-color: #a5a5a5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transform: scale(1.2, 1.2);
    z-index: 20;
    background: #fff;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 310px;
    position: relative;

    border: 1px solid rgba(255, 107, 0, 0.4);
    border-radius: 5px;
    margin-top: -40px;

    .action {
      display: flex;
      margin-bottom: 16px;
    }
    a {
      text-decoration: none;
      color: var(--fontcolor);
    }

    @media (max-width: ${layout.mobile}) {
      height: 220px;
    }
  }
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 5px;

  img {
    width: 165px;
    height: 165px;
  }

  @media (max-width: ${layout.mobile}) {
    img {
      width: 100%;
      height: 90px;

      &:hover {
        transform: perspective(600px) rotateY(180deg);
      }
    }
  }
`;

export const Action = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  width: 90%;

  cursor: pointer;
  padding: 2px;
  margin-top: 16px;

  span {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
  }
  input {
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    width: 60px;
    height: 30px;
    background: #fff;
    margin-left: 6px;
    color: #646464;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  svg {
    font-size: 26px;
    margin-left: 6px;
  }

  @media (max-width: ${layout.mobile}) {
    width: 95%;
    input {
      width: 30px;
    }
  }
`;

export const Botton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 23px;
  height: 23px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  span {
    font-size: 12px;
    font-weight: 500;
  }

  width: 100%;

  @media (max-width: ${layout.mobile}) {
    font-size: 10px;
    font-weight: 500;
  }
`;

export const ContentStar = styled.div`
  height: 40px;
  display: flex;
  justify-items: center;
  align-items: center;
`;

export const Amount = styled.div`
  height: 40px;
  font-size: 19px;
  font-weight: 600;
`;
