import styled, { css } from 'styled-components';
import { shade } from 'polished';

export interface ButtonAtributesProps {
  buttonType?: 'success' | 'warning' | 'info' | 'primary' | 'error' | 'outline';
  borderColor?: string;
  textColor?: string;
  textSize?: string;
}

export const Root = styled.div<ButtonAtributesProps>`
  border-radius: 10px;
  width: 100%;

  transition: background-color 0.2s;
  background: #0d9e25;
  &:hover {
    background: ${shade(0.2, '#0d9e25')};
    border-radius: 10px;
  }

  ${props =>
    props.buttonType === 'success' &&
    css`
      background: #0d9e25;
      &:hover {
        background: ${shade(0.2, '#0d9e25')};
        border-radius: 10px;
      }
    `}

  ${props =>
    props.buttonType === 'warning' &&
    css`
      background: #f3ee0f;
      &:hover {
        background: ${shade(0.2, '#f3ee0f')};
        border-radius: 10px;
      }
    `}

  ${props =>
    props.buttonType === 'info' &&
    css`
      background: #4796ef;
      &:hover {
        background: ${shade(0.2, '#4796ef')};
        border-radius: 10px;
      }
    `}

  ${props =>
    props.buttonType === 'primary' &&
    css`
      background: var(--primary);
      &:hover {
        background: ${shade(0.2, '#ff9000')};
        border-radius: 10px;
      }
    `}

  ${props =>
    props.buttonType === 'error' &&
    css`
      background: #f30f0f;
      &:hover {
        background: ${shade(0.2, '#f30f0f')};
        border-radius: 10px;
      }
    `}

  ${props =>
    props.buttonType === 'outline' &&
    css`
      background: #fff;
      border: 1px solid ${props.borderColor};
      color: ${props.textColor};
      &:hover {
        background: ${shade(0.2, '#fff')};
        border-radius: 10px;
        border: 1px solid ${props.borderColor};
      }
    `}

`;

export const Container = styled.button<ButtonAtributesProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;

  border: 0;
  padding: 0 16px;
  width: 100%;
  height: 56px;
  font-weight: 500;

  ${props =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}

  ${props =>
    props.textSize &&
    css`
      font-size: ${props.textSize};
    `}
`;

export const ContainerIcone = styled.div``;
