import styled, { css } from 'styled-components';

import { layout } from '../../../../package.json';

interface ContainerProps {
  categoryList?: boolean | false;
  categoryFilter?: boolean | false;
}

export const Container = styled.div<ContainerProps>`
  width: 275px;
  height: 100%;



  color: #e2e2e2;
  margin-top: 2px;
  margin-left: 10%;
  margin-bottom: 20px;
  z-index: 100;

  ${props =>
    props.categoryList &&
    css`
      position: absolute;
      left: 0;
      top: 172px;
    `}

  ${props =>
    props.categoryFilter &&
    css`
      position: unset;
      left: 0;
      top: 0px;
    `}

    @media (max-width: ${layout.mobile}) {
      display: none;
      position: absolute;
      top: 39px;
      left: 0;
      margin-left: 0;
      width: 150px;
    }
`;
