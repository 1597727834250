import styled, { css } from 'styled-components';

import { layout } from '../../../package.json';

interface ContainerMobileProps {
  mobile?: boolean;
}

export const Container = styled.div``;

export const ContainerProducts = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  margin-bottom: 90px;

  color: #646464;
`;

export const ContainerProductsResult = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ContainerProductsResultRow = styled.div<ContainerMobileProps>`
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: row;

  ${props =>
    props.mobile &&
    css`
      flex-direction: column;
    `};

  @media (max-width: ${layout.mobile}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${layout.desktop}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: ${layout.desktop_wide}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;
