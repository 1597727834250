/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import {
  ProductState,
  ProductAction,
  ProductActionTypes,
  ProductData,
} from './types';

const INITIAL_STATE: ProductState = {
  products: [],
  search: [],
  productDetalails: {} as ProductData,
  response: {
    to: '0',
    total: '0',
  },
  error: false,
  loading: false,
  loadingSearch: false,
  filters: {
    categoryId: '',
    priceMin: 0,
    priceMax: 1600,
    moreProducts: 18,
    order: 'desc',
  },
};

export default function product(
  state = INITIAL_STATE,
  action: ProductAction,
): ProductState {
  switch (action.type) {
    case ProductActionTypes.LOAD_REQUEST:
      return {
        ...state,
        filters: {
          categoryId: state.filters.categoryId,
          priceMax: state.filters.priceMax,
          priceMin: state.filters.priceMin,
          moreProducts: state.filters.moreProducts,
          order: action.payload.filters.order,
        },
        loading: true,
        error: false,
      };
    case ProductActionTypes.LOAD_SUCCCES:
      return {
        ...state,
        products: action.payload.data,
        loading: false,
        error: false,
      };
    case ProductActionTypes.RESPONSE_SUCCCES:
      return {
        ...state,
        response: action.payload.data,
      };
    case ProductActionTypes.LOAD_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case ProductActionTypes.SEARCH_REQUEST:
      return {
        ...state,
        error: false,
        loadingSearch: true,
      };
    case ProductActionTypes.SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        search: action.payload.data,
        loadingSearch: false,
        error: false,
      };

    case ProductActionTypes.DETAILS:
      return {
        ...state,
        productDetalails: action.payload.data,
        error: false,
      };
    default:
      return state;
  }
}
