import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const AccountTitle = styled.div`
  border: 1px solid red;
`;

export const ContainerFormAccount = styled.div`
  border: 1px solid red;
`;

export const FormAccount = styled.form`
  border: 1px solid red;
`;

export const FormAccountBox = styled.fieldset`
  border: 1px solid red;
`;

export const FormAccountTitle = styled.fieldset`
  border: 1px solid red;
`;

export const FormAccountInput = styled.input`
  border: 1px solid red;
`;
