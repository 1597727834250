import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store/createStore';
import { findCategoryRequest } from '../../store/modules/category/actions';
import CategoryItem from './CategoryItem';

import {
  MenuGategory,
  MenuGategoryTitle,
  MenuGategoryList,
  MenuGategoryItem,
  MenuGategoryItemTitle,
} from './styles';

const CategoryMenu: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();

  const { categorys, categorysSub, loading } = useSelector(
    (state: StoreState) => state.category,
  );

  useEffect(() => {
    dispatch(findCategoryRequest());
  }, [dispatch]);

  return (
    <>
      <MenuGategory>
        <MenuGategoryTitle>
          <span>{t('main.menu.category')}</span>
        </MenuGategoryTitle>
        <MenuGategoryList>
          {categorys &&
            categorys.map(category => (
              <>
                <CategoryItem categoryData={category} />

                {category.open && (
                  <MenuGategoryList>
                    {loading && (
                      <MenuGategoryItem>
                        <>
                          <MenuGategoryItemTitle submenu={10}>
                            <Skeleton duration={1} width={256} height={20} />
                          </MenuGategoryItemTitle>
                          <MenuGategoryItemTitle submenu={10}>
                            <Skeleton duration={2} width={256} height={20} />
                          </MenuGategoryItemTitle>
                          <MenuGategoryItemTitle submenu={10}>
                            <Skeleton duration={1} width={256} height={20} />
                          </MenuGategoryItemTitle>
                          <MenuGategoryItemTitle submenu={10}>
                            <Skeleton duration={2} width={256} height={20} />
                          </MenuGategoryItemTitle>
                        </>
                      </MenuGategoryItem>
                    )}

                    {categorysSub &&
                      categorysSub.map(categorySub => (
                        <>
                          <CategoryItem
                            categoryData={categorySub}
                            submenu={10}
                          />

                          {categorySub.open && (
                            <MenuGategoryList>
                              {categorySub.childrens &&
                                categorySub.childrens.map(children => (
                                  <>
                                    <CategoryItem
                                      categoryData={children}
                                      submenu={20}
                                    />
                                  </>
                                ))}
                            </MenuGategoryList>
                          )}
                        </>
                      ))}
                  </MenuGategoryList>
                )}
              </>
            ))}
        </MenuGategoryList>
      </MenuGategory>
    </>
  );
};

export default CategoryMenu;
