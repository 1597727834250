import { ActionType } from 'typesafe-actions';
import { ModalContentProps } from '../../../layout/Master/styles';
import * as actions from './actions';

export type ParameterAction = ActionType<typeof actions>;

export interface ParameterProps {
  side: 'left' | 'right';
}

export enum ParameterActionTypes {
  OPEN_MENU = '@parameter/OPEN_MENU',
  OPEN_MENU_BASE = '@parameter/OPEN_MENU_BASE',
  OPEN_MODAL = '@parameter/OPEN_MODAL',
  VIEW_MODAL = '@parameter/VIEW_MODAL',
}

export interface ParameterState {
  readonly isShowLeft: boolean;
  readonly isShowRight: boolean;
  readonly openMenu?: boolean;
  readonly openModal?: boolean;
  readonly viewModal?: React.ComponentType;
  readonly viewModalProps?: ModalContentProps;
}
