import styled, { css } from 'styled-components';

import { layout, color } from '../../../package.json';

export const Container = styled.div`
  width: 100%;
  height: 41px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 16px;

  background-color: var(--navbar);
  color: var(--navbarcolor);
`;

export const ContainerMenuMobile = styled.div`
  display: none;
  @media (max-width: ${layout.mobile}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const NavMenu = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 6px 16px;
  margin-top: 10px;
`;

interface BadgeNumberProps {
  number?: number;
}

export const BadgeNumber = styled.div<BadgeNumberProps>`
  width: 15px;
  height: 15px;
  border-radius: 15px;

  border: 1px solid var(--btn-finish);

  position: absolute;
  margin-left: 13px;
  margin-top: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--btn-finish);

  span {
    color: #fff;
    font-size: 12px;
    font-weight: 800;
  }

  ${props =>
    props.number === undefined &&
    css`
      display: none;
    `}

    @media (max-width: ${layout.mobile}) {
      span {
        font-size: 10px;
      }
    }
`;

export const Button = styled.div``;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 220px;

  @media (max-width: ${layout.desktop}) {
    display: none;
  }

  @media (max-width: ${layout.desktop_wide}) {
    margin-left: 110px;
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: row;

  margin-right: 130px;

  @media (max-width: ${layout.mobile}) {
    display: none;
  }

  @media (max-width: ${layout.desktop}) {
    margin-right: 80px;
  }
`;

interface ItemProps {
  marginRight?: string;
  notHover?: boolean;
}

export const Item = styled.div<ItemProps>`
  display: flex;
  justify-content: space-between;

  margin-left: 16px;

  ${props =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight}px;
    `}

  :hover {
    color: var(--navbarhover);
    cursor: pointer;
  }

  ${props =>
    props.notHover &&
    css`
      :hover {
        color: var(--white);
        cursor: unset;
      }
    `}
  svg {
    margin-right: 6px;
  }

  @media (max-width: ${layout.desktop_wide}) {
    font-size: 12px;
  }
`;
