import React, { useCallback, useEffect, useRef } from 'react';
import {
  FiMail,
  FiLock,
  FiPhone,
  FiUser,
  FiArrowLeft,
  FiArrowRight,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FaBirthdayCake, FaFacebookSquare } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getValidationErrors from '../../../utils/getValidationErrors';

// import logoImg from '../../assets/logo.svg';
import logoImg from '../../../assets/logo.png';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import {
  Container,
  ContainerStep,
  Content,
  AnimationContainer,
  Title,
  ColInput,
} from './styles';
import Radio from '../../../components/Radio';
import {
  customerClear,
  customerStepRequest,
  customerUpdateRequest,
} from '../../../store/modules/auth/actions';
import {
  AuthActionStep,
  CustomerData,
} from '../../../store/modules/auth/types';
import SignUp from '..';
import { viewModal } from '../../../store/modules/parameter/actions';
import { StoreState } from '../../../store/createStore';
import SignUpStep02 from '../SignUpStep02';

const SignUpStep01: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { token, step, user } = useSelector((state: StoreState) => state.auth);

  const handleSubmit = useCallback(async (data: CustomerData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        cpf_cnpj: Yup.string().required('CPF obrigatório'),
        birth: Yup.string()
          .required('Data de Nascimento obrigatória')
          .min(6, 'No mínimo 6 digitos'),
        phone_mobile: Yup.string().required('Celular obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const preRequest = data.genre ? data.genre[0] : '';
      if (data.genre) data.genre = preRequest;
      console.log('handleSubmit:', data);

      const customerData: CustomerData = {
        date_of_birth: data.birth,
        customer_id: data.cpf_cnpj,
        gender: data.genre,
        phone: data.phone_mobile,
      };

      dispatch(
        customerUpdateRequest(customerData, token, AuthActionStep.CST_STEP_03),
      );
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        console.log('handleSubmit errors:', errors);
      }
    }
  }, []);

  const handleStep = useCallback(() => {
    dispatch(customerStepRequest(AuthActionStep.CST_STEP_01));
    dispatch(viewModal(SignUp, { width: '50%', height: '80%' }));
  }, []);

  useEffect(() => {
    if (step === 'CST_STEP_03') {
      dispatch(customerClear());
      dispatch(viewModal(SignUpStep02, { width: '50%', height: '80%' }));
    }
  }, [step]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <ContainerStep>
            <FiArrowLeftCircle
              size={36}
              color="var(--primary)"
              onClick={handleStep}
            />
            <img src={logoImg} alt="ConectoVarejo" />
            <FiArrowRightCircle size={36} color="var(--primary)" />
          </ContainerStep>

          <Form
            initialData={{
              cpf_cnpj: '33336662869',
              birth: '18/07/1986',
              phone_mobile: '11972347951',
            }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Title>
              <span>Gostaríamos de saber um pouco mais sobre você</span>
            </Title>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <Input icon={FiUser} name="cpf_cnpj" placeholder="CPF*" />
            </ColInput>

            <ColInput>
              <Input
                icon={FaBirthdayCake}
                name="birth"
                placeholder="Data de Nascimento*"
              />
            </ColInput>

            <ColInput>
              <Input
                icon={FiPhone}
                name="phone_mobile"
                placeholder="Celular*"
              />
            </ColInput>

            <ColInput>
              <Radio
                title="Sexo"
                name="genre"
                options={[
                  { id: 'male', label: 'Masculino' },
                  { id: 'female', label: 'Feminino' },
                  { id: 'others', label: 'Outros' },
                  { id: 'nogenre', label: 'Não informar' },
                ]}
              />
              {/* <Input
                type="radio"
                value="female"
                titleCheck="Feminino"
                name="genre"
                placeholder="Sexo"
              />
              <Input
                type="radio"
                value="male"
                titleCheck="Masculino"
                name="genre"
                placeholder="Sexo"
              />
              <Input
                type="radio"
                value="others"
                titleCheck="Outros"
                name="genre"
                placeholder="Sexo"
              />
              <Input
                type="radio"
                value="nogenre"
                titleCheck="Não informar"
                name="genre"
                placeholder="Sexo"
              /> */}
            </ColInput>

            <ColInput>
              <Button
                button={{ type: 'submit' }}
                atributes={{
                  buttonType: 'primary',
                  textColor: '#fff',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
              >
                Continuar
              </Button>
            </ColInput>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUpStep01;
