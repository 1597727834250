import React, { useCallback, useState } from 'react';

import Skeleton from 'react-loading-skeleton';

import {
  FiMenu,
  FiShoppingCart,
  FiUser,
  FiShoppingBag,
  FiHeart,
} from 'react-icons/fi';

import { AiOutlineClose } from 'react-icons/ai';

import { FaSignOutAlt } from 'react-icons/fa';

import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdKeyboardArrowRight,
} from 'react-icons/md';

import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Root,
  ContainerBGMenu,
  ContainerMenuLeft,
  ContainerMenuRight,
  ContainerMenu,
  MenuHeader,
  MenuUser,
  MenuName,
  MenuShortCuts,
  MenuShortCutItem,
  MenuGategory,
  MenuGategoryTitle,
  MenuGategoryList,
  MenuGategoryItem,
  MenuGategoryItemTitle,
  MenuFooter,
  MenuAppVersion,
  MenuUserLogout,
  UserLogoutBtn,
  Content,
  ContentHeader,
  NavMenu,
  BadgeNumber,
  Button,
  Container,
  ContainerProducts,
} from './styles';

import { StoreState } from '../../store/createStore';
import {
  openCategory,
  openCategorySub,
  findCategorySubRequest,
} from '../../store/modules/category/actions';
import { findProductRequest } from '../../store/modules/product/actions';
import ProdutctsList from '../../components/ProdutctsList';
import { signOut } from '../../store/modules/auth/actions';

const MobileHome: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();

  const { categorys, categorysSub, loading } = useSelector(
    (state: StoreState) => state.category,
  );

  const { filters } = useSelector((state: StoreState) => state.product);

  const { user } = useSelector((state: StoreState) => state.auth);

  const {
    checkout: { products },
  } = useSelector((state: StoreState) => state.checkout);

  const handledOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handledOpenCategory = useCallback(category_id => {
    dispatch(openCategory(category_id));
    dispatch(findCategorySubRequest({ id: category_id }));
  }, []);

  const handledOpenCategorySub = useCallback(category_id => {
    dispatch(openCategorySub(category_id));
  }, []);

  const handledFindProductByCategory = useCallback(category_id => {
    filters.categoryId = category_id;
    // dispatch(findProductRequest(filters));
    setOpenMenu(false);
  }, []);

  const handledSigout = useCallback(() => {
    dispatch(signOut());
  }, []);

  return (
    <Root>
      <ContainerBGMenu open={openMenu}>
        <ContainerMenuLeft>
          <ContainerMenu>
            <MenuHeader>
              <MenuUser>
                <FiUser size={26} color="#FFF" />
                <MenuName>
                  <span>Olá</span>
                  <strong>{user.data.first_name}</strong>
                </MenuName>
                <AiOutlineClose
                  size={22}
                  color="#FFF"
                  onClick={() => setOpenMenu(!openMenu)}
                />
              </MenuUser>
            </MenuHeader>
            <MenuShortCuts>
              <MenuShortCutItem>
                <FiShoppingBag size={16} />
                <span>Meus pedidos</span>
              </MenuShortCutItem>
              <MenuShortCutItem>
                <FiHeart size={16} />
                <span>Favoritos</span>
              </MenuShortCutItem>
              <MenuShortCutItem>
                <FiUser size={16} />
                <span>Mnha conta</span>
              </MenuShortCutItem>
            </MenuShortCuts>
            <MenuGategory>
              <MenuGategoryTitle>
                <span>Categoria</span>
              </MenuGategoryTitle>
              <MenuGategoryList>
                {categorys &&
                  categorys.map(category => (
                    <>
                      <MenuGategoryItem
                        onClick={() => handledOpenCategory(category.id)}
                      >
                        <MenuGategoryItemTitle main>
                          <span>{category.short_description}</span>
                          {category.open ? (
                            <MdKeyboardArrowUp size={17} color="#ccc" />
                          ) : (
                            <MdKeyboardArrowDown size={17} color="#ccc" />
                          )}
                        </MenuGategoryItemTitle>
                      </MenuGategoryItem>
                      {category.open && (
                        <MenuGategoryList>
                          {loading && (
                            <MenuGategoryItem>
                              <>
                                <MenuGategoryItemTitle submenu={10}>
                                  <Skeleton
                                    duration={1}
                                    width={256}
                                    height={20}
                                  />
                                </MenuGategoryItemTitle>
                                <MenuGategoryItemTitle submenu={10}>
                                  <Skeleton
                                    duration={2}
                                    width={256}
                                    height={20}
                                  />
                                </MenuGategoryItemTitle>
                                <MenuGategoryItemTitle submenu={10}>
                                  <Skeleton
                                    duration={1}
                                    width={256}
                                    height={20}
                                  />
                                </MenuGategoryItemTitle>
                                <MenuGategoryItemTitle submenu={10}>
                                  <Skeleton
                                    duration={2}
                                    width={256}
                                    height={20}
                                  />
                                </MenuGategoryItemTitle>
                              </>
                            </MenuGategoryItem>
                          )}

                          {categorysSub &&
                            categorysSub.map(categorySub => (
                              <>
                                <MenuGategoryItem
                                  onClick={() =>
                                    handledOpenCategorySub(categorySub.id)
                                  }
                                >
                                  <MenuGategoryItemTitle submenu={10} main>
                                    <span>{categorySub.short_description}</span>
                                    {categorySub.open ? (
                                      <MdKeyboardArrowUp
                                        size={17}
                                        color="#ccc"
                                      />
                                    ) : (
                                      <MdKeyboardArrowDown
                                        size={17}
                                        color="#ccc"
                                      />
                                    )}
                                  </MenuGategoryItemTitle>
                                </MenuGategoryItem>
                                {categorySub.open && (
                                  <MenuGategoryList>
                                    {categorySub.childrens &&
                                      categorySub.childrens.map(children => (
                                        <>
                                          <MenuGategoryItem
                                            onClick={() =>
                                              handledFindProductByCategory(
                                                children.id,
                                              )}
                                          >
                                            <MenuGategoryItemTitle submenu={20}>
                                              <span>
                                                {children.short_description}
                                              </span>
                                              <MdKeyboardArrowRight
                                                size={17}
                                                color="#ccc"
                                              />
                                            </MenuGategoryItemTitle>
                                          </MenuGategoryItem>
                                        </>
                                      ))}
                                  </MenuGategoryList>
                                )}
                              </>
                            ))}
                        </MenuGategoryList>
                      )}
                    </>
                  ))}
              </MenuGategoryList>
            </MenuGategory>
            <MenuFooter>
              <MenuAppVersion>
                <span>Agencia Conecto</span>
                v1.0.0
              </MenuAppVersion>
              <MenuUserLogout>
                <UserLogoutBtn onClick={handledSigout}>
                  <span>Sair</span>
                  <FaSignOutAlt size={18} color="#FF9b00" />
                </UserLogoutBtn>
              </MenuUserLogout>
            </MenuFooter>
          </ContainerMenu>
        </ContainerMenuLeft>
        <ContainerMenuRight onClick={() => setOpenMenu(!openMenu)} />
      </ContainerBGMenu>
      <Container>
        <ContentHeader>
          <NavMenu>
            <Button onClick={handledOpenMenu}>
              <FiMenu size={24} color="#FF9b00" />
            </Button>

            <NavLink to="/checkout">
              {products && products?.length > 0 && (
                <BadgeNumber number={1}>
                  <span>{products?.length}</span>
                </BadgeNumber>
              )}
              <FiShoppingCart size={24} color="#FF9b00" />
            </NavLink>
          </NavMenu>
        </ContentHeader>
        <Content>
          {!openMenu && (
            <ContainerProducts>
              <ProdutctsList />
            </ContainerProducts>
          )}
        </Content>
      </Container>
    </Root>
  );
};

export default MobileHome;
