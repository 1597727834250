import React, { useCallback, useEffect, useRef } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FaFacebookSquare } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getValidationErrors from '../../utils/getValidationErrors';

// import logoImg from '../../assets/logo.svg';
import logoImg from '../../assets/logo.png';

import { signInRequest } from '../../store/modules/auth/actions';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Container,
  Content,
  AnimationContainer,
  Title,
  ColInput,
} from './styles';
import { StoreState } from '../../store/createStore';
import {
  openShowModal,
  viewModal,
} from '../../store/modules/parameter/actions';
import SignUp from '../SignUp';

interface SingUpFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { isSignedIn } = useSelector((state: StoreState) => state.auth);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (data: SingUpFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string()
            .required('Senha obrigatória')
            .min(6, 'No mínimo 6 digitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(
          signInRequest({
            email: data.email,
            password: data.password,
          }),
        );
        // await signIn({
        //   email: data.email,
        //   password: data.password,
        // });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [dispatch],
  );

  const handledRegister = useCallback(() => {
    dispatch(viewModal(SignUp, { width: '50%', height: '80%' }));
  }, [dispatch]);

  useEffect(() => {
    if (isSignedIn) dispatch(openShowModal());
  }, [isSignedIn, dispatch]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="ConectoVarejo" />

          <Form
            initialData={{
              email: 'teste2@conecto.com.br',
              password: '654321',
            }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Title>
              <span>Olá, você não está logado.</span>
              <p>Entre com seu usuário e senha</p>
            </Title>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <Input icon={FiMail} name="email" placeholder="E-mail*" />
            </ColInput>

            <ColInput>
              <Input
                icon={FiLock}
                type="password"
                name="password"
                placeholder="Senha*"
              />
            </ColInput>

            <ColInput>
              <Button
                button={{ type: 'submit' }}
                atributes={{
                  buttonType: 'primary',
                  textColor: '#fff',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
              >
                Entrar
              </Button>
            </ColInput>

            <ColInput>
              <span>- OU -</span>
            </ColInput>

            <ColInput>
              <Button
                button={{ type: 'submit' }}
                atributes={{
                  buttonType: 'outline',
                  textColor: '#474747',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
                icon={FaFacebookSquare}
                icone={{ direction: 'left', size: 36, color: '#0a84ee' }}
              >
                Entrar com Facebook
              </Button>
            </ColInput>

            <ColInput>
              <Button
                button={{ type: 'submit' }}
                atributes={{
                  buttonType: 'outline',
                  textColor: '#474747',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
                icon={FcGoogle}
                icone={{ direction: 'left', size: 36, color: '#0a84ee' }}
              >
                Entrar com Google
              </Button>
            </ColInput>
          </Form>

          <div onClick={handledRegister}>
            <span>Não tem uma conta? Cadastre-se</span>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignIn;
