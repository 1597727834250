import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`;

export const SubTitle = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`;

export const ResultStore = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`;
