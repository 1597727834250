import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { MdClose } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import NavBar from '../../components/Header/NavBar';
import Search from '../../components/Header/Search';
import Menu from '../../components/Header/Menu';
// import Footer from '../../components/Footer';

import {
  Root,
  ContainerMyCart,
  MyCartTitle,
  ProductsList,
  ProductRow,
  ContainerImagem,
  ProductImagem,
  ContainerDetal,
  ProductTitle,
  ProductCategory,
  ProductPrice,
  ContainerQty,
  QtyTitle,
  Botton,
  CotainerActions,
  PlusIcon,
  InputQty,
  MinusIcon,
  ContainerRemove,
  ButtonRemove,
  ContainerActions,
  KeepBuyingBtn,
  CheckoutBtn,
} from './styles';
import { StoreState } from '../../store/createStore';
import {
  checkoutData,
  checkoutProductProps,
  checkoutActionTypes,
} from '../../store/modules/checkout/types';
import {
  checkoutAddPlu,
  checkoutRemovePlu,
  checkoutAddQtyPlu,
  checkoutRemoveQtyPlu,
  checkoutSend,
  checkoutFind,
} from '../../store/modules/checkout/actions';

const Checkout: React.FC = () => {
  const { checkout, summary } = useSelector(
    (state: StoreState) => state.checkout,
  );
  const { user } = useSelector((state: StoreState) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCheckoutActions = useCallback(
    async (data: checkoutData, product?: checkoutProductProps) => {
      console.log('handleCheckoutActions: ', data, product);
      switch (data.type) {
        case checkoutActionTypes.ADD_PLU_CHECKOUT:
          if (product) dispatch(checkoutAddPlu(product));
          break;
        case checkoutActionTypes.REMOVE_PLU_CHECKOUT:
          console.log('summary', summary);
          if (summary?.id) {
            if (product) dispatch(checkoutRemovePlu(summary?.id, product));
          }
          break;
        case checkoutActionTypes.ADD_PLU_QTY_CHECKOUT:
          if (product) dispatch(checkoutAddQtyPlu(product));
          break;
        case checkoutActionTypes.REMOVE_PLU_QTY_CHECKOUT:
          if (product) dispatch(checkoutRemoveQtyPlu(product));
          break;
        case checkoutActionTypes.FINISH_CHECKOUT:
          console.log('FINISH_CHECKOUT ID', summary?.id);
          console.log('FINISH_CHECKOUT checkout', checkout);

          if (!summary?.id) dispatch(checkoutSend(user, checkout));
          else dispatch(checkoutFind(summary?.id, checkout));
          history.push('/summary');
          break;
        default:
      }
    },
    [],
  );

  const handleCheckoutSale = useCallback(() => {
    history.push('/produtos');
  }, []);

  return (
    <Root>
      <ContainerMyCart>
        <MyCartTitle>
          <span>Meu Carrinho</span>
        </MyCartTitle>
        <ProductsList>
          {checkout.products &&
            checkout.products.map(product => (
              <>
                <ProductRow key={product.productId}>
                  <ContainerImagem>
                    <ProductImagem>
                      <img src={product.imageURL} alt={product.name} />
                    </ProductImagem>
                  </ContainerImagem>
                  <ContainerDetal>
                    <ProductTitle>
                      <span>{product.name}</span>
                    </ProductTitle>
                    <ProductCategory>
                      <span>Refrigerante</span>
                    </ProductCategory>
                    <ProductPrice>
                      <span>R$ {product.price}</span>
                    </ProductPrice>
                  </ContainerDetal>
                  <ContainerQty>
                    <QtyTitle>
                      <span>Quantidade</span>
                    </QtyTitle>
                    <CotainerActions>
                      <Botton
                        onClick={() =>
                          handleCheckoutActions(
                            {
                              type: checkoutActionTypes.ADD_PLU_QTY_CHECKOUT,
                            },
                            {
                              productId: product.productId,
                              name: product.name,
                              price: product.price,
                              imageURL: product.imageURL,
                            },
                          )
                        }
                      >
                        <PlusIcon size={24} />
                      </Botton>
                      <InputQty value={product.quantity} />
                      <Botton
                        onClick={() =>
                          handleCheckoutActions(
                            {
                              type: checkoutActionTypes.REMOVE_PLU_QTY_CHECKOUT,
                            },
                            {
                              productId: product.productId,
                              name: product.name,
                              price: product.price,
                            },
                          )
                        }
                      >
                        <MinusIcon />
                      </Botton>
                    </CotainerActions>
                  </ContainerQty>
                  <ContainerRemove>
                    <ButtonRemove
                      onClick={() =>
                        handleCheckoutActions(
                          {
                            type: checkoutActionTypes.REMOVE_PLU_CHECKOUT,
                          },
                          {
                            productId: product.productId,
                          },
                        )
                      }
                    >
                      <span>EXCLUIR PRODUTO</span>
                      <MdClose color="#FFF" />
                    </ButtonRemove>
                  </ContainerRemove>
                </ProductRow>
              </>
            ))}
        </ProductsList>
      </ContainerMyCart>
      <ContainerActions>
        <KeepBuyingBtn onClick={handleCheckoutSale}>
          <span>CONTINUAR COMPRANDO</span>
        </KeepBuyingBtn>
        <CheckoutBtn
          onClick={() =>
            handleCheckoutActions({
              type: checkoutActionTypes.FINISH_CHECKOUT,
            })}
        >
          <span>FINALIZAR COMPRA</span>
        </CheckoutBtn>
      </ContainerActions>
      {/*
        <ContainerSuggestions>
          <SuggestionsProducts>
            <SuggestionTitle>
              <span>Sugestões para você</span>
            </SuggestionTitle>
            <SuggestionProductList>
              <SuggestionProductRow key="1">
                <SuggestionProductItem key="1">
                  <SuggestionProductLeft>
                    <SuggestionProductImage>
                      <img src={LAGUNITAS} alt="LAGUNITAS" />
                    </SuggestionProductImage>
                    <SuggestionProductStar>
                      <FiStar color="var(--star-on)" key={1} />
                      <FiStar color="var(--star-on)" key={2} />
                      <FiStar color="var(--star-on)" key={3} />
                      <FiStar color="var(--star-off)" key={4} />
                      <FiStar color="var(--star-off)" key={5} />
                    </SuggestionProductStar>
                  </SuggestionProductLeft>
                  <SuggestionProductRight>
                    <SuggestionProductAdd>
                      <span>CARRINHO</span>
                    </SuggestionProductAdd>
                  </SuggestionProductRight>
                  <SuggestionProductButtom>
                    <SuggestionProductTitle>
                      <span>Guarana 269ml</span>
                    </SuggestionProductTitle>
                    <SuggestionProductPrice>
                      <span>R$ 2.69</span>
                    </SuggestionProductPrice>
                  </SuggestionProductButtom>
                </SuggestionProductItem>
              </SuggestionProductRow>
            </SuggestionProductList>
          </SuggestionsProducts>
        </ContainerSuggestions> */}
    </Root>
  );
};

export default Checkout;
