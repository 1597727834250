import styled, { css } from 'styled-components';

interface MenuProps {
  open?: boolean;
}

export const Container = styled.div<MenuProps>`
  display: flex;
  flex-direction: row;
  z-index: 9;

  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  ${props =>
    props.open === false &&
    css`
      display: none;
    `}
`;

export const ContainerMenuLeft = styled.div`
  width: 80vw;
  height: 100vh;
`;

export const ContainerMenuRight = styled.div`
  width: 20vw;
  height: 100vh;
`;

export const ContainerMenu = styled.div`
  background-color: #fff;
  width: 80vw;

  max-width: 480px;
  height: 100vh;
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 40px;

  background-color: var(--primary);
  box-shadow: 0 3px 6px rgba(255, 107, 0, 0.36);
`;

export const MenuUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 40px;

  padding-left: 6px;
  padding-right: 6px;
`;

export const MenuName = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  width: 80%;

  color: #fff;
  span {
    font-size: 11px;
  }

  strong {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const MenuShortCuts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const MenuShortCutItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 33%;

  span {
    font-size: 10px;
    color: var(--gray);
    margin-top: 6px;
  }

  svg {
    color: var(--primary);
  }
`;

export const MenuFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* position: absolute; */
  bottom: 0;
  left: 0;

  width: 80vw;
  height: 50px;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.86);
`;

export const MenuAppVersion = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  width: 80vw;
  height: 50px;

  padding-left: 10px;
  font-size: 11px;

  span {
    font-size: 17px;
    color: var(--primary);
  }
`;

export const MenuUserLogout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 80vw;
  height: 50px;

  padding-right: 10px;
`;

export const UserLogoutBtn = styled.div`
  border: 1px solid var(--primary);
  width: 80px;
  height: 30px;
  border-radius: 10px;
  background-color: #ffdfb5;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-right: 6px;
    color: var(--primary);
  }
`;
