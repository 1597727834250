import React, { useState, useCallback, useEffect } from 'react';

import { FOCUS_TYPE } from '@repay/react-credit-card/dist/ReactCreditCard';
import ReactCreditCard from '@repay/react-credit-card';
import { useDispatch, useSelector } from 'react-redux';
import {
  Root,
  ContainerCreateCardCredit,
  ContainerCardCredit,
  ContainerFormCardCredit,
  FormCardCredit,
  FormCardCreditBoxName,
  FormCardCreditBoxNumber,
  FormCardCreditBoxExp,
  FormCardCreditBoxCVC,
  FormCardCreditBoxBTN,
  FormCardCreditTitle,
  FormCardCreditInput,
} from './styles';

import CardPayment from '../../../components/CardPayment';
import {
  userSavePaymentESitef,
  userListPaymentRequest,
} from '../../../store/modules/auth/actions';
import { StoreState } from '../../../store/createStore';

// import Footer from '../../components/Footer';

const Payment: React.FC = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: '',
    number: '',
    expiration: '',
    cvc: '',
  });
  const { token } = useSelector((state: StoreState) => state.auth);

  const handleSubmit = useCallback(data => {
    if (data.number && data.expiration && data.name) {
      const cardPayload = {
        card_number: data.number,
        expiry_date: data.expiration,
        authorizer_id: '2',
        merchant_usn: '3',
        customer_id: '11122211122',
        full_name: data.name,
      };

      dispatch(userSavePaymentESitef(cardPayload, token && token));

      setValues({
        name: '',
        number: '',
        expiration: '',
        cvc: '',
      });
    }
  }, []);

  useEffect(() => {
    dispatch(userListPaymentRequest(token && token));
  }, []);

  const handleChange = useCallback(
    event => {
      const { name, value } = event.target;
      console.log('handleChange: ', name, '|', value);
      setValues(v => ({ ...v, [name]: value }));
    },
    [setValues],
  );

  const [focused, setFocus] = useState<FOCUS_TYPE | undefined>(undefined);
  const handleFocus = useCallback(
    event => setFocus(event.target.name as FOCUS_TYPE),
    [setFocus],
  );
  const handleBlur = useCallback(() => setFocus(undefined), [setFocus]);

  return (
    <Root>
      <div style={{ marginTop: '10px' }} />
      <ContainerCreateCardCredit>
        <ContainerCardCredit>
          <ReactCreditCard {...values} focused={focused} />
        </ContainerCardCredit>
        <ContainerFormCardCredit>
          <FormCardCredit>
            <FormCardCreditBoxNumber>
              <FormCardCreditTitle>
                Numero Cartão de Credito
              </FormCardCreditTitle>
              <FormCardCreditInput
                name="number"
                type="number"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={values.number}
              />
            </FormCardCreditBoxNumber>
            <FormCardCreditBoxName>
              <FormCardCreditTitle>
                Nome no Cartão de Credito
              </FormCardCreditTitle>
              <FormCardCreditInput
                name="name"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={values.name}
              />
            </FormCardCreditBoxName>
            <FormCardCreditBoxExp>
              <FormCardCreditTitle>Vencimento</FormCardCreditTitle>
              <FormCardCreditInput
                name="expiration"
                type="number"
                placeholder="MM/YY"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={values.expiration}
              />
            </FormCardCreditBoxExp>
            <FormCardCreditBoxCVC style={{ marginBottom: '20px' }}>
              <FormCardCreditTitle>CVC</FormCardCreditTitle>
              <FormCardCreditInput
                name="cvc"
                type="number"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={values.cvc}
              />
            </FormCardCreditBoxCVC>
            <FormCardCreditBoxBTN onClick={() => handleSubmit(values)}>
              <span>Salvar</span>
            </FormCardCreditBoxBTN>
          </FormCardCredit>
        </ContainerFormCardCredit>
      </ContainerCreateCardCredit>

      <CardPayment title="Meus Cartões" />
    </Root>
  );
};

export default Payment;
