import styled from 'styled-components';

export const Container = styled.div`
  width: 800px;
  height: 600px;

  margin-left: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerBanner = styled.div`
  width: 100%;
  height: 150px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
`;

export const BannerTitle = styled.div`
  width: 431px;
  height: 60px;
  text-shadow: 0 3px 6px #a70808;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
`;
