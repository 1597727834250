import styled from 'styled-components';

import { layout } from '../../../package.json';

export const Root = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--fontcolor);
  background: #f7f7f7;
`;

export const ContainerMyData = styled.div`
  margin-top: 6px;
  margin-bottom: 16px;

  @media (max-width: ${layout.mobile}) {
    width: 95%;
  }
`;

export const MyDataTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 30px 30px;
  span {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Roboto';
  }
`;

export const MyData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 30px 30px;
  border: 1px solid red;

  width: 85%;
  height: 400px;
`;

export const ContainerPaymentAddress = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

  @media (max-width: ${layout.mobile}) {
    width: 95%;
  }
`;

export const PaymentAddressTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;

  span {
    font-size: 28px;
    font-weight: 600;
  }

  @media (max-width: ${layout.mobile}) {
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
`;

export const ContainerPayment = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;

  width: 60%;

  margin-bottom: 15px;

  @media (max-width: ${layout.mobile}) {
    grid-template-columns: 100%;
    width: 90%;
    margin-bottom: 0px;
  }
`;

export const ContainerPaymentTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    color: var(--gray);
    font-size: 21px;
    font-weight: 800;
    margin-top: 20px;
  }
`;

export const PaymentUpdBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--gray);
  background: var(--gray);
  border-radius: 15px;
  margin-top: 20px;
  width: 190px;
  height: 60px;
  padding: 10px;

  span {
    color: var(--white);
    font-size: 18px;
    margin-bottom: 15px;
  }

  @media (max-width: ${layout.mobile}) {
    height: 40px;
    span {
      line-height: 60px;
    }
  }
`;

export const ContainerPaymentCards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  margin-top: 16px;
`;

export const CardItem = styled.div`
  display: grid;
  grid-template-columns: 30% 60% 10%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    'logo title icon'
    'logo number icon';
`;

export const CardLogo = styled.div`
  grid-area: logo;

  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  img {
    width: 60px;
    background: transparent;
  }
`;

export const CardTitle = styled.div`
  grid-area: title;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    color: var(--gray-light);
    font-size: 16px;
    font-weight: 800;
  }
`;

export const CardNumer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-area: number;
`;

export const CardNumberSec = styled.span`
  color: var(--gray);
  font-weight: 100;
  font-size: 26px;
  margin-top: 16px;
  margin-right: 5px;
`;

export const CardActions = styled.div`
  grid-area: button;
  margin-top: 16px;
  margin-right: 5px;

  span {
    color: var(--primary);
    font-size: 16px;
    cursor: pointer;
  }
`;

export const CardIcon = styled.div`
  grid-area: icon;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

export const ContainerAddress = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;

  width: 60%;

  margin-bottom: 15px;

  @media (max-width: ${layout.mobile}) {
    grid-template-columns: 100%;
    width: 90%;
  }
`;

export const ContainerAddressTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    color: var(--gray);
    font-size: 21px;
    font-weight: 800;
    margin-top: 20px;
  }

  @media (max-width: ${layout.mobile}) {
    margin-bottom: 15px;
  }
`;

export const AddressUpdBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--gray);
  background: var(--gray);
  border-radius: 15px;
  margin-top: 20px;
  width: 190px;
  height: 60px;
  padding: 10px;

  span {
    color: var(--white);
    font-size: 18px;
    margin-bottom: 15px;
  }

  @media (max-width: ${layout.mobile}) {
    height: 40px;
    span {
      line-height: 60px;
    }
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 30%;
  height: 62px;
  margin-bottom: 35px;

  @media (max-width: ${layout.mobile}) {
    width: 80%;
    height: 52px;
  }
`;

export const BackBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid #ff9000;

  width: 300px;
  height: 100%;
  border-radius: 15px;

  background: #ff9000;

  span {
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  @media (max-width: ${layout.mobile}) {
    width: 160px;
    span {
      text-align: center;

      font-size: 12px;
    }
  }
`;

export const LogoutBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--gray);

  width: 300px;
  height: 100%;

  border-radius: 15px;
  margin-left: 100px;

  background: var(--gray);

  span {
    color: #fff;
    width: 300px;
    font-weight: 900;
    font-size: 18px;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: ${layout.mobile}) {
    width: 160px;
    margin-left: 20px;
    span {
      text-align: center;

      font-size: 12px;
    }
  }
`;

export const DeleteAccountBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--btn-canc);

  width: 300px;
  height: 100%;

  border-radius: 15px;

  background: var(--btn-canc);

  span {
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: ${layout.mobile}) {
    width: 160px;
    span {
      text-align: center;
      font-size: 12px;
    }
  }
`;

export const ContainerMyPayments = styled.div`
  border: 1px solid;

  width: 95%;
`;

export const MyPaymentsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  border: 1px solid;
`;

export const PaymentList = styled.div`
  display: flex;
  flex-direction: row;

  border: 1px solid;

  @media (max-width: ${layout.mobile}) {
  }
`;

export const PaymentRow = styled.div``;

export const ContainerMyPayment = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;

  @media (max-width: ${layout.mobile}) {
    justify-content: center;
    width: 100%;
    padding: 10px;
  }
`;

export const MyPaymentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;

  span {
    font-size: 28px;
    font-weight: 600;
  }

  @media (max-width: ${layout.mobile}) {
    padding-bottom: 0px;
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
`;

export const PaymentContainerMore = styled.div`
  display: none;
  justify-content: space-between;

  @keyframes moreaddress {
    0% {
      right: 0px;
    }
    50% {
      right: 20px;
    }
    100% {
      right: 0px;
    }
  }

  @media (max-width: ${layout.mobile}) {
    display: flex;
  }
`;

export const PaymentMore = styled.div`
  font-size: 36px;
  position: relative;
  top: 0;
  right: 0;
  font-size: 36px;
  font-weight: 700;

  animation: moreaddress 1s infinite;
  color: var(--primary);
`;

export const ContainerPaymentLists = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${layout.mobile}) {
    display: -webkit-box;
    overflow-x: auto;
  }
`;

export const PaymentItem = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: repeat(5 * 20px);
  grid-gap: 5px;
  grid-template-areas:
    'title title'
    'logo street'
    'logo neigh'
    'logo citystate'
    'logo zip'
    'logo action';

  margin-bottom: 20px;
  margin-left: 16px;
  min-width: 300px;
  max-width: 400px;

  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 5px;
`;

export const PaymentTitle = styled.div`
  grid-area: title;

  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  span {
    color: var(--gray);
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const PaymentLogo = styled.div`
  grid-area: logo;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;

  margin-top: 16px;
`;

export const PaymentAction = styled.div`
  grid-area: action;
  margin-top: 16px;
  margin-right: 5px;

  span {
    color: var(--primary);
    font-size: 16px;
    cursor: pointer;
  }
`;

export const PaymentItemAdd = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  grid-template-areas: 'logo title';

  margin-bottom: 20px;
  margin-left: 16px;
  min-width: 300px;
  max-width: 400px;

  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 5px;

  justify-content: center;
  align-items: center;
`;

export const PaymentItemAddLogo = styled.div`
  grid-area: logo;
`;

export const PaymentItemAddText = styled.div`
  grid-area: title;
`;
