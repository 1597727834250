import React, { useCallback } from 'react';

import { FiBriefcase, FiHome, FiPlus } from 'react-icons/fi';
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ContainerMyAddress,
  MyAddressTitle,
  AddressTitle,
  AddressMore,
  AddressItemAdd,
  AddressItemAddLogo,
  AddressItemAddText,
  ContainerAddressLists,
  AddressItem,
  AddressLogo,
  AddressStreet,
  AddressNeigh,
  AddressCityState,
  AddressCity,
  AddressState,
  AddressSeparator,
  AddressZip,
  AddressAction,
} from './styles';
import { StoreState } from '../../store/createStore';
import {
  userAddressRequest,
  userAddressSelectedRequest,
} from '../../store/modules/auth/actions';

interface CardAddressProps {
  title: string;
  summary?: boolean;
}

const CardAddress: React.FC<CardAddressProps> = ({
  title,
  summary: summaryFlag,
}) => {
  const { user, token } = useSelector((state: StoreState) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const { summary } = useSelector((state: StoreState) => state.checkout);

  const handleAddress = useCallback(
    address => {
      console.log('handleAddress:', summary?.id);
      if (summary?.id !== undefined)
        dispatch(userAddressRequest(summary?.id, address, token && token));
    },
    [token, dispatch],
  );

  const handleAddressSelected = useCallback(
    address => {
      console.log('handleAddressSelected:', address, token);

      dispatch(userAddressSelectedRequest(address, token && token));
    },
    [token, dispatch],
  );

  const handleAddressADD = useCallback(() => {
    console.log('handleAddressADD');
    history.push('/account/address');
  }, []);

  return (
    <ContainerMyAddress>
      <MyAddressTitle>
        <AddressMore>{!summaryFlag && <FaLongArrowAltLeft />}</AddressMore>
        <span>{title}</span>
        <AddressMore>
          <FaLongArrowAltRight />
        </AddressMore>
      </MyAddressTitle>
      <ContainerAddressLists>
        {user.addresses &&
          user.addresses?.map(address => (
            <AddressItem
              key={address.id}
              selected={address.selected}
              onClick={() => handleAddressSelected(address)}
            >
              <AddressTitle>
                <span>{address.address_name}</span>
              </AddressTitle>
              <AddressLogo>
                <FiHome size="48" color="var(--gray)" />
              </AddressLogo>
              <AddressStreet>
                <span>
                  {address.address}
                  {address.number && `, ${address.number}`}
                </span>
              </AddressStreet>
              <AddressNeigh>
                <span>{address.neighborhood}</span>
              </AddressNeigh>
              <AddressCityState>
                <AddressCity>
                  <span>{address.city}</span>
                </AddressCity>
                <AddressSeparator>-</AddressSeparator>
                <AddressState>
                  <span>{address.state}</span>
                </AddressState>
              </AddressCityState>
              <AddressZip>
                <span>{address.postcode}</span>
              </AddressZip>
              <AddressAction>
                <span>{summaryFlag ? 'Selecione' : 'Alterar'}</span>
              </AddressAction>
            </AddressItem>
          ))}
        <AddressItemAdd onClick={handleAddressADD}>
          <AddressItemAddLogo>
            <FiPlus size="48" color="var(--green)" />
          </AddressItemAddLogo>
          <AddressItemAddText>
            <span>Adicionar Novo Endereço</span>
          </AddressItemAddText>
        </AddressItemAdd>
      </ContainerAddressLists>
    </ContainerMyAddress>
  );
};

export default CardAddress;
