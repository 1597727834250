import styled, { css } from 'styled-components';

import { layout } from '../../../package.json';

interface PaymentItemProps {
  selected?: number;
}

export const ContainerMyPayments = styled.div`
  border: 1px solid;

  width: 95%;
`;

export const MyPaymentsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  border: 1px solid;
`;

export const PaymentList = styled.div`
  display: flex;
  flex-direction: row;

  border: 1px solid;

  @media (max-width: ${layout.mobile}) {
  }
`;

export const PaymentRow = styled.div``;

export const ContainerMyPayment = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;

  @media (max-width: ${layout.mobile}) {
    justify-content: center;
    width: 100%;
    padding: 10px;
  }
`;

export const MyPaymentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;

  span {
    font-size: 28px;
    font-weight: 600;
  }

  @keyframes moreaddress {
    0% {
      right: 0px;
    }
    50% {
      right: 20px;
    }
    100% {
      right: 0px;
    }
  }

  @media (max-width: ${layout.mobile}) {
    justify-content: space-between;
    padding-bottom: 0px;
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
`;

export const PaymentMore = styled.div`
  display: none;
  font-size: 36px;
  position: relative;
  top: 0;
  right: 0;
  font-size: 36px;
  font-weight: 700;

  animation: moreaddress 1s infinite;
  color: var(--primary);
  @media (max-width: ${layout.mobile}) {
    display: block;
  }
`;

export const ContainerPaymentLists = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  @media (max-width: ${layout.mobile}) {
    display: -webkit-box;
    overflow-x: auto;
  }
`;

export const ContainerPaymentItem = styled.div<PaymentItemProps>`
  display: flex;

  margin-left: 10px;

  border: 2px solid white;
  border-radius: 12px;

  :hover {
    border: 2px solid var(--primary);
    transform: scale(1);
  }

  .ReactCreditCard__container {
    margin-left: -180px;

    ${props =>
      props.selected === 0 &&
      css`
        margin-left: 0px;
      `}
  }
`;

export const PaymentItemSelected = styled.div`
  z-index: 1;
  border-bottom: 20px solid transparent;
  border-left: 15px solid transparent;
  border-right: 55px solid transparent;
  padding: 0 8px 0 0;
  height: 0;
  width: 180px;
  position: relative;
  margin: 2em auto;
  transform: rotate(-40deg);

  :before {
    border-bottom: 40px solid var(--primary);
    border-left: 33px solid transparent;
    border-right: 50px solid transparent;
    padding: 0 8px 0 0;
    height: 0;
    width: 105px;
    position: absolute;
    bottom: -15px;
    left: -48px;
    content: '';
    z-index: -1;
  }

  svg {
    font-size: 40px;
    color: var(--white);
    margin-left: 65px;
    margin-top: -25px;
  }
`;

export const PaymentAction = styled.div`
  grid-area: update;
  margin-top: 16px;
  margin-right: 5px;

  span {
    color: var(--primary);
    font-size: 16px;
    cursor: pointer;
  }
`;

export const PaymentItemAdd = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  grid-template-areas: 'logo title';

  margin-bottom: 20px;
  margin-left: 16px;
  min-width: 300px;
  max-width: 400px;

  border: 1px solid var(--cardborder);
  background: var(--cardbg);

  border-radius: 10px;
  padding: 5px;

  justify-content: center;
  align-items: center;

  :hover {
    background: var(--cardhoverbg);
    border: 1px solid var(--cardhoverborder);
    cursor: pointer;
  }
`;

export const PaymentItemAddLogo = styled.div`
  grid-area: logo;
`;

export const PaymentItemAddText = styled.div`
  grid-area: title;
`;
