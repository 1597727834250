/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { checkoutActionTypes } from './types';
import api from '../../../services/api';
import api_esitef from '../../../services/api_esitef';

interface CartProucts {
  product_id: string;
  quantity: string;
}

export function* sendProductCheckout({
  payload,
  type,
}: ActionType<typeof actions.checkoutSend>) {
  try {
    const sendItems: Array<CartProucts> = [];

    payload.checkout.products?.forEach(product => {
      if (product.productId && product.quantity && !product.send) {
        sendItems.push({
          product_id: product.productId.toString(),
          quantity: product.quantity.toString(),
        });
      }
    });

    const { data: checkoutData } = yield call(api.post, 'cart/add', sendItems);

    const addressSel = {
      shipping_method: 'shipment',
      address_id: 0,
      address_type: 'customer',
    };

    const addressRes = payload.user.addresses?.filter(item => {
      if (item.selected === 1) {
        item.name = item.complement;
        item.address_id = item.id.toString();
        item.reference = '';
        item.shipping_method = 'shipment';
        item.address_type = 'customer';
        return item;
      }
    });

    console.log('sendProductCheckout: ', addressRes);

    if (addressRes && addressRes[0]) {
      const { data: checkoutAddressData } = yield call(
        api.post,
        `cart/save-address/${checkoutData.data.id}`,
        addressRes[0],
      );
      yield put(actions.checkoutSendSucess(checkoutAddressData.data.cart));
    } else {
      yield put(actions.checkoutFailure());
    }
  } catch (err) {
    yield put(actions.checkoutFailure());
  }
}

export function* findProductCheckout({
  payload,
  type,
}: ActionType<typeof actions.checkoutFind>) {
  try {
    const sendItems: Array<CartProucts> = [];

    payload.data.products?.forEach(product => {
      if (product.productId && product.quantity && !product.send) {
        sendItems.push({
          product_id: product.productId.toString(),
          quantity: product.quantity.toString(),
        });
      }
    });

    if (sendItems.length) {
      const { data } = yield call(
        api.post,
        `cart/add-product/${payload.id}`,
        sendItems,
      );

      yield put(actions.checkoutSendSucess(data.data));
    } else {
      const { data } = yield call(api.get, `cart/${payload.id}`);
      yield put(actions.checkoutSendSucess(data.data));
    }
  } catch (err) {
    yield put(actions.checkoutFailure());
  }
}

export function* viewProductCheckout({
  payload,
  type,
}: ActionType<typeof actions.checkoutView>) {
  try {
    const { filter } = payload;
  } catch (err) {
    yield put(actions.checkoutFailure());
  }
}

export function* removeProductCheckout({
  payload,
  type,
}: ActionType<typeof actions.checkoutRemovePlu>) {
  try {
    const {
      id,
      data: { productId: cart_item_id },
    } = payload;
    const { data: dataRep } = yield call(
      api.get,
      `cart/remove-item/${id}/${cart_item_id}`,
    );

    yield put(actions.checkoutSendSucess(dataRep.data));
  } catch (err) {
    yield put(actions.checkoutFailure());
  }
}

export function* closeSale({
  payload,
  type,
}: ActionType<typeof actions.closeSale>) {
  try {
    console.log('closeSale: ', payload);

    const paymentData = {
      merchant_usn: '3',
      order_id: payload.id,
      installments: '1',
      installment_type: '4',
      authorizer_id: '2',
      amount:
        payload.sumary.grand_total && Number(payload.sumary.grand_total) * 1000,
    };

    console.log('paymentData:', paymentData);
    // const opts: RequestInit = {
    //   method: 'POST',
    //   headers: {
    //     merchant_id: 'conecto',
    //     merchant_key: '47A0E298E76E771630E8E5E368E23813CBF4BA7766F962FF',
    //   },
    //   body: JSON.stringify(paymentData),
    // };
    // yield call(fetch('https://esitef-homologacao.softwareexpress.com.br/e-sitef/api/v1/transactions', opts));
    // yield call(api_esitef.post, 'transactions', paymentData);
    // yield call(api_esitef.post, 'transactions', paymentData, {
    //   method: 'POST',
    //   withCredentials: false,
    //   headers: {
    //     'Content-Type': 'application/json',
    //     // 'Content-Type': 'application/x-www-form-urlencoded',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Max-Age': '86400',
    //     'X-Powered-By': 'Undertow/1',
    //     merchant_id: 'conecto',
    //     merchant_key: '47A0E298E76E771630E8E5E368E23813CBF4BA7766F962FF',
    //   },
    // });

    yield call(api.post, `cart/save-order/${payload.id}`, {
      email: 'wfelix@conecto.com.br',
    });

    yield put(actions.closeSaleSucess());
  } catch (error) {
    if (error.response) {
      yield put(actions.closeSaleFailure(error.response.data));
    } else if (error.request) {
      console.log('Error1', error.request);
    } else {
      console.log('Error2', error);
    }
  }
}
export function* updateProductQtyCheckout({
  payload,
  type,
}: ActionType<typeof actions.checkoutUpdQtyPlu>) {
  try {
    const { product } = payload;

    console.log(
      'updateProductQtyCheckout: ',
      product.productId,
      product.quantity,
    );
    const qty: { [key: string]: string } = {};
    if (product.productId && product.quantity) {
      qty[product.productId] = product.quantity.toString();
      const arrQty = {
        qty,
      };

      const { data } = yield call(
        api.post,
        `cart/update/${payload.id}`,
        arrQty,
      );

      yield put(actions.checkoutSendSucess(data.data));
    }
  } catch (err) {
    yield put(actions.checkoutFailure());
  }
}

export default all([
  takeLatest(
    checkoutActionTypes.UPD_PLU_QTY_CHECKOUT,
    updateProductQtyCheckout,
  ),
  takeLatest(checkoutActionTypes.REMOVE_PLU_CHECKOUT, removeProductCheckout),
  takeLatest(checkoutActionTypes.FINISH_CHECKOUT, sendProductCheckout),
  takeLatest(checkoutActionTypes.FIND_CHECKOUT, findProductCheckout),
  takeLatest(checkoutActionTypes.VIEW_CHECKOUT, viewProductCheckout),
  takeLatest(checkoutActionTypes.CLOSE_SALE, closeSale),
]);
