import React from 'react';
import { AuthProvider } from './auth';
import { DropDownProvider } from './dropdown';
import { ProductProvider } from './product';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <DropDownProvider>
      <ProductProvider>{children}</ProductProvider>
    </DropDownProvider>
  </AuthProvider>
);
export default AppProvider;
