import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Skeleton from 'react-loading-skeleton';

import { useTranslation } from 'react-i18next';

import { FiHeart, FiShoppingCart, FiUser } from 'react-icons/fi';
import { MdAddShoppingCart } from 'react-icons/md';

import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Root,
  Container,
  ContainerLogo,
  ContainerInputBox,
  ContainerInput,
  ContainerInputResult,
  ContainerInputResultItem,
  ContainerInputResultItemImage,
  ContainerInputResultItemTitle,
  ContainerInputResultItemCategory,
  ContainerInputResultItemPrice,
  ContainerInputResultItemActions,
  ContainerText,
  ContainerBtns,
  Botton,
  ContainerCart,
  ContainerCartTotal,
  CartTotalBest,
  CartTotal,
  BadgeNumber,
} from './styles';
import Input from '../../components/Input';

import superconectoImg from '../../assets/logo_superconecto.png';
import { StoreState } from '../../store/createStore';
import { findProductSearch } from '../../store/modules/product/actions';
import {
  checkoutActionTypes,
  checkoutData,
  checkoutProductProps,
} from '../../store/modules/checkout/types';
import { checkoutAddQtyPlu } from '../../store/modules/checkout/actions';

const Search: React.FC = () => {
  const [searchData, setSearchData] = useState('');
  const { summary, checkout } = useSelector(
    (state: StoreState) => state.checkout,
  );
  const { filters, search, loadingSearch } = useSelector(
    (state: StoreState) => state.product,
  );
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();

  const [t] = useTranslation('common');

  const handleSubmit = useCallback(async () => {
    console.log('clicou...', formRef.current?.getData());
  }, []);

  useEffect(() => {
    console.log('searchData: ', searchData);

    setTimeout(() => {
      filters.term = searchData;
      dispatch(findProductSearch(filters));
    }, 1000);
  }, [searchData]);

  const handleProduct = useCallback(
    async (data: checkoutData, product: checkoutProductProps) => {
      switch (data.type) {
        case checkoutActionTypes.ADD_PLU_QTY_CHECKOUT:
          dispatch(checkoutAddQtyPlu(product));
          break;
        default:
      }
    },
    [],
  );

  return (
    <Root>
      <Container>
        <ContainerLogo>
          <img src={superconectoImg} alt="SuperConecto" />
        </ContainerLogo>
        <ContainerInputBox className="containerinputbox">
          <ContainerInput
            className="containerinput"
            style={{ marginTop: !searchData ? '0' : '13px' }}
          >
            <Form
              // initialData={{
              //   search: 'Heiniko'
              // }}
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <Input
                type="search"
                IconLeft={false}
                name="search"
                placeholder={t('main.search.input')}
                onKeyUp={e => setSearchData(e.currentTarget.value)}
                BorderBottomRadiusNo={!!searchData!}
              />
            </Form>
          </ContainerInput>
          {searchData && (
            <ContainerInputResult>
              {loadingSearch ? (
                <>
                  <ContainerInputResultItem>
                    <Skeleton
                      duration={1}
                      width={730}
                      height={52}
                      style={{ marginLeft: '0px' }}
                    />
                  </ContainerInputResultItem>
                  <ContainerInputResultItem>
                    <Skeleton
                      duration={2}
                      width={730}
                      height={52}
                      style={{ marginLeft: '0px' }}
                    />
                  </ContainerInputResultItem>
                  <ContainerInputResultItem>
                    <Skeleton
                      duration={1}
                      width={730}
                      height={52}
                      style={{ marginLeft: '0px' }}
                    />
                  </ContainerInputResultItem>
                </>
              ) : (
                <>
                  {search &&
                    search.map(product => (
                      <>
                        <ContainerInputResultItem>
                          <ContainerInputResultItemImage>
                            <img
                              src={product?.image_url}
                              alt={product?.short_description}
                            />
                          </ContainerInputResultItemImage>
                          <ContainerInputResultItemTitle>
                            <span>{product?.short_description}</span>
                          </ContainerInputResultItemTitle>
                          <ContainerInputResultItemCategory>
                            <span>{product?.category?.short_description}</span>
                          </ContainerInputResultItemCategory>
                          <ContainerInputResultItemPrice>
                            R$ {Number(product?.price).toFixed(2)}
                          </ContainerInputResultItemPrice>
                          <ContainerInputResultItemActions
                            onClick={() =>
                              handleProduct(
                                {
                                  type:
                                    checkoutActionTypes.ADD_PLU_QTY_CHECKOUT,
                                },
                                {
                                  productId: product?.product_id,
                                  name: product?.short_description,
                                  price: Number(product?.price),
                                  imageURL: product?.image_url,
                                  categoryName:
                                    product?.category?.short_description,
                                },
                              )
                            }
                          >
                            <span>
                              <MdAddShoppingCart size={18} />
                              Comprar
                            </span>
                          </ContainerInputResultItemActions>
                        </ContainerInputResultItem>
                      </>
                    ))}
                </>
              )}
            </ContainerInputResult>
          )}
        </ContainerInputBox>
        <ContainerText>
          <span>{t('main.search.text_store_open')}</span>
        </ContainerText>
        <ContainerBtns>
          <Botton>
            <NavLink to="/account">
              <FiUser />
              <span>Minha Conta</span>
            </NavLink>
          </Botton>
          <Botton>
            <FiHeart />
            <span>Favoritos</span>
          </Botton>
          <Botton>
            <NavLink to="/checkout">
              <BadgeNumber
                number={checkout.products?.length || summary?.items?.length}
              >
                <span>
                  {checkout.products?.length
                    ? checkout.products?.length
                    : summary?.items?.length}
                </span>
              </BadgeNumber>
              <FiShoppingCart />
            </NavLink>
          </Botton>
        </ContainerBtns>
        <ContainerCart>
          <span>{t('main.search.cart_amout')}</span>
          <ContainerCartTotal>
            {summary?.sub_total ||
            (checkout.total && checkout.total > Number(summary?.sub_total)) ? (
              <>
                {summary?.grand_total &&
                summary?.sub_total &&
                summary?.grand_total > summary?.sub_total ? (
                  <>
                    <CartTotalBest>
                      <span>
                        {summary?.grand_total !== undefined ? 'R$' : ''}
                        {summary?.grand_total}
                      </span>
                    </CartTotalBest>
                    <CartTotal>
                      <span>
                        {summary?.sub_total !== undefined ? 'R$' : ''}
                        {summary?.sub_total}
                      </span>
                    </CartTotal>
                  </>
                ) : (
                  <CartTotal>
                    <span>
                      {summary?.sub_total !== undefined ? 'R$' : ''}
                      {summary?.sub_total}
                    </span>
                  </CartTotal>
                )}
              </>
            ) : (
              <>
                <CartTotal>
                  <span>
                    {checkout.total !== undefined ? 'R$' : ''}
                    {checkout.total?.toFixed(2)}
                  </span>
                </CartTotal>
              </>
            )}
          </ContainerCartTotal>
        </ContainerCart>
      </Container>
    </Root>
  );
};

export default Search;
