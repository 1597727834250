/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect } from 'react';

import { MdClose } from 'react-icons/md';
import { NavLink, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { useSelector, useDispatch } from 'react-redux';

import {
  Root,
  ContainerMyCart,
  MyCartTitle,
  ProductsList,
  ProductRow,
  ContainerImagem,
  ProductImagem,
  ContainerDetal,
  ProductTitle,
  ProductCategory,
  ProductPrice,
  ContainerQty,
  QtyTitle,
  Botton,
  CotainerActions,
  PlusIcon,
  InputQty,
  MinusIcon,
  ContainerRemove,
  ButtonRemove,
  ContainerActions,
  KeepBuyingBtn,
  VoidedBtn,
  FinishBtn,
} from './styles';
import CardPayment from '../../components/CardPayment';
import CardAddress from '../../components/CardAddress';
import { StoreState } from '../../store/createStore';
import {
  checkoutActionTypes,
  checkoutData,
  checkoutProductProps,
  checkoutDataSuccess,
} from '../../store/modules/checkout/types';
import {
  checkoutAddPlu,
  checkoutUpdQtyPlu,
  checkoutRemovePlu,
  checkoutAddQtyPlu,
  checkoutRemoveQtyPlu,
  closeSale,
} from '../../store/modules/checkout/actions';
import { userPaymentRequest } from '../../store/modules/auth/actions';

const Summary: React.FC = () => {
  const { summary, loading, error, errorData } = useSelector(
    (state: StoreState) => state.checkout,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const handleProduct = useCallback(
    async (
      data: checkoutData,
      product?: checkoutProductProps,
      summary?: checkoutDataSuccess,
    ) => {
      console.log('handleProduct: ', data, product, summary);
      switch (data.type) {
        case checkoutActionTypes.ADD_PLU_CHECKOUT:
          if (product) dispatch(checkoutAddPlu(product));
          break;
        case checkoutActionTypes.REMOVE_PLU_CHECKOUT:
          if (summary?.id && product)
            dispatch(checkoutRemovePlu(summary.id, product));
          break;
        case checkoutActionTypes.ADD_PLU_QTY_CHECKOUT:
          if (product) dispatch(checkoutAddQtyPlu(product));
          break;
        case checkoutActionTypes.REMOVE_PLU_QTY_CHECKOUT:
          if (product) dispatch(checkoutRemoveQtyPlu(product));
          break;
        case checkoutActionTypes.CLOSE_SALE:
          if (summary?.id) await dispatch(closeSale(summary?.id, summary));

          // const paymentData = {
          //   merchant_usn: '3',
          //   order_id: 255,
          //   installments: '1',
          //   installment_type: '4',
          //   authorizer_id: '2',
          //   amount: 10000,
          // };

          // const opts: RequestInit = {
          //   method: 'POST',
          //   mode: 'no-cors',
          //   headers: {
          //     'Content-Type': 'application/json',
          //     merchant_id: 'conecto',
          //     merchant_key: '47A0E298E76E771630E8E5E368E23813CBF4BA7766F962FF',
          //   },
          //   body: JSON.stringify(paymentData),
          // };

          // fetch(
          //   'https://esitef-homologacao.softwareexpress.com.br/e-sitef/api/v1/transactions',
          //   opts,
          // );
          // xhr.setRequestHeader('Access-Control-Allow-Origin', '*'); //also tried https://xyz.com
          // xhr.setRequestHeader('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
          // xhr.setRequestHeader('Access-Control-Allow-Headers', ' Origin, Content-Type, X-Auth-Token');
          // xhr.setRequestHeader("Access-Control-Allow-Credentials","true");

          // try {
          //   const response = await fetch(
          //     'https://esitef-homologacao.softwareexpress.com.br/e-sitef/api/v1/transactions',
          //     {
          //       method: 'POST',
          //       credentials: 'include',
          //       headers: {
          //         'Content-Type': 'application/json',
          //         merchant_id: 'conecto',
          //         merchant_key:
          //           '47A0E298E76E771630E8E5E368E23813CBF4BA7766F962FF',
          //       },
          //       body: JSON.stringify(paymentData),
          //     },
          //   );

          //   console.log('response', response);
          // } catch (error) {
          //   const message = 'Something went wrong';
          //   console.log('error: ', error);
          //   return { status: 'error', message };
          // }

          // fetch(
          //   'https://esitef-homologacao.softwareexpress.com.br/e-sitef/api/v1/transactions',
          //   {
          //     credentials: 'include',

          //     mode: 'no-cors',
          //     method: 'POST',
          //     headers: { 'Content-Type': 'application/json' },
          //     body: JSON.stringify(paymentData),
          //   },
          // )
          //   .then(result => console.log('success====:', result))
          //   .catch(error => console.log('error============:', error));
          // console.log('checkoutActionTypes.CLOSE_SALE: ', error);
          break;
        case checkoutActionTypes.UPD_PLU_QTY_CHECKOUT:
          if (summary?.id && product)
            dispatch(checkoutUpdQtyPlu(summary?.id, product));
          break;
        case checkoutActionTypes.VOIDED_SALE:
          history.push('/');
          break;

        default:
      }
    },
    [],
  );

  useEffect(() => {
    // if (!errorData) history.push('/');
    console.log('valid error: ', errorData, error);
    if (errorData?.code === 'E000004' && error) {
      if (summary?.id) {
        dispatch(userPaymentRequest(summary?.id, 'esitef'));
        if (summary?.id) dispatch(closeSale(summary?.id, summary));
      }
    }
  }, [errorData, error]);

  useEffect(() => {
    console.log('summary?.order_save: ', summary?.order_save);
    if (summary?.order_save === true) history.push('/');
  }, [summary]);
  return (
    <Root>
      <ContainerMyCart>
        <>
          {loading ? (
            <>
              <MyCartTitle>
                <span>
                  Pedido #{' '}
                  <>
                    <Skeleton duration={1} width={53} height={29} key="1" />
                  </>
                </span>
              </MyCartTitle>
              <ProductsList>
                <ProductRow key="1">
                  <Skeleton duration={1} width={1490} height={120} key="2" />
                </ProductRow>
                <ProductRow key="2">
                  <Skeleton duration={1} width={1490} height={120} key="3" />
                </ProductRow>
              </ProductsList>
            </>
          ) : (
            <>
              {error && errorData && (
                <>
                  <h1 style={{ color: '#000' }}>{errorData.data}</h1>
                </>
              )}
              <MyCartTitle>
                <span>Pedido # {summary?.id}</span>
              </MyCartTitle>
              <ProductsList>
                {summary?.items &&
                  summary.items.map(product => (
                    <>
                      <ProductRow key={product.product?.product_id}>
                        <ContainerImagem>
                          <ProductImagem>
                            <img
                              src={product.product?.image_url}
                              alt={product.short_description}
                            />
                          </ProductImagem>
                        </ContainerImagem>
                        <ContainerDetal>
                          <ProductTitle>
                            <span>{product.product?.short_description}</span>
                          </ProductTitle>
                          <ProductCategory>
                            <span>
                              {product.product?.category?.short_description}
                            </span>
                          </ProductCategory>
                          <ProductPrice>
                            <span>R$ {Number(product.price).toFixed(2)}</span>
                          </ProductPrice>
                        </ContainerDetal>
                        <ContainerQty>
                          <QtyTitle>
                            <span>Quantidade</span>
                          </QtyTitle>
                          <CotainerActions>
                            <Botton
                              onClick={() =>
                                handleProduct(
                                  {
                                    type:
                                      checkoutActionTypes.UPD_PLU_QTY_CHECKOUT,
                                  },
                                  {
                                    productId: product.cart_item_id?.toString(),
                                    name: product.short_description,
                                    price: Number(product.price),
                                    quantity: Number(product.quantity) + 1,
                                  },
                                  summary,
                                )
                              }
                            >
                              <PlusIcon size={24} />
                            </Botton>
                            <InputQty
                              value={Number(product.quantity).toFixed(3)}
                            />
                            <Botton
                              onClick={() =>
                                handleProduct(
                                  {
                                    type:
                                      checkoutActionTypes.UPD_PLU_QTY_CHECKOUT,
                                  },
                                  {
                                    productId: product.cart_item_id?.toString(),
                                    name: product.short_description,
                                    price: Number(product.price),
                                    quantity: Number(product.quantity) - 1,
                                  },
                                  summary,
                                )
                              }
                            >
                              <MinusIcon />
                            </Botton>
                          </CotainerActions>
                        </ContainerQty>
                        <ContainerRemove>
                          <ButtonRemove
                            onClick={() =>
                              handleProduct(
                                {
                                  type: checkoutActionTypes.REMOVE_PLU_CHECKOUT,
                                },
                                {
                                  productId: product.cart_item_id?.toString(),
                                },
                              )
                            }
                          >
                            <span>EXCLUIR PRODUTO</span>
                            <MdClose color="#FFF" />
                          </ButtonRemove>
                        </ContainerRemove>
                      </ProductRow>
                    </>
                  ))}
              </ProductsList>
            </>
          )}
        </>
      </ContainerMyCart>

      {summary?.id && (
        <>
          <CardPayment title="Forma de Pagamento" summary />
          <CardAddress title="Endereço de Entrega" summary />
        </>
      )}

      <ContainerActions>
        <FinishBtn
          onClick={() =>
            handleProduct(
              {
                type: checkoutActionTypes.CLOSE_SALE,
              },
              {},
              summary,
            )
          }
        >
          <span>FINALIZAR COMPRA</span>
        </FinishBtn>
      </ContainerActions>

      <ContainerActions>
        <KeepBuyingBtn>
          <NavLink to="/produtos">
            <span>CONTINUAR COMPRANDO</span>
          </NavLink>
        </KeepBuyingBtn>
        <VoidedBtn
          onClick={() =>
            handleProduct({
              type: checkoutActionTypes.VOIDED_SALE,
            })
          }
        >
          <span>CANCELAR COMPRA</span>
        </VoidedBtn>
      </ContainerActions>
    </Root>
  );
};

export default Summary;
