import React, { useCallback } from 'react';

import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { MenuCategoryItem, MenuCategoryItemTitle } from './styles';

import {
  findCategorySubRequest,
  openCategory,
} from '../../../store/modules/category/actions';
import { openMenuBase } from '../../../store/modules/parameter/actions';
import { StoreState } from '../../../store/createStore';
import { CategoryData } from '../../../store/modules/category/types';

const CategoryItem: React.FC<{
  categoryData: CategoryData;
  submenu?: number;
}> = ({ categoryData: { id, short_description, open, root }, submenu }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state: StoreState) => state.product);

  const handledOpenCategory = useCallback(
    category_id => {
      dispatch(openCategory(category_id));
      dispatch(findCategorySubRequest({ id: category_id }));
    },
    [dispatch],
  );

  const handledFindProductByCategory = useCallback(
    category_id => {
      filters.categoryId = category_id;
      dispatch(openMenuBase());
    },
    [dispatch, filters],
  );

  return (
    <>
      <MenuCategoryItem
        onClick={() =>
          root ? handledFindProductByCategory(id) : handledOpenCategory(id)
        }
      >
        <MenuCategoryItemTitle main submenu={submenu}>
          <span>{short_description}</span>
          {root === true ? (
            <>
              <MdKeyboardArrowRight size={17} color="#ccc" />
            </>
          ) : (
            <>
              {open ? (
                <MdKeyboardArrowUp size={17} color="#ccc" />
              ) : (
                <MdKeyboardArrowDown size={17} color="#ccc" />
              )}
            </>
          )}
        </MenuCategoryItemTitle>
      </MenuCategoryItem>
    </>
  );
};

export default CategoryItem;
