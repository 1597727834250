/* eslint-disable no-case-declarations */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { checkoutState, checkoutAction, checkoutActionTypes } from './types';

const INITIAL_STATE: checkoutState = {
  checkout: {
    products: [],
    total: 0,
    discount: 0,
    countProduct: 0,
  },
  summary: {
    order_save: false,
  },
  error: false,
  loading: false,
};

export default function Checkout(
  state = INITIAL_STATE,
  action: checkoutAction,
): checkoutState {
  switch (action.type) {
    case checkoutActionTypes.ADD_PLU_CHECKOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case checkoutActionTypes.REMOVE_PLU_CHECKOUT:
      let removeTotalItem = 0;
      let totalCheckout = 0;

      if (state.checkout.total) totalCheckout = state.checkout.total;

      state.checkout.products?.filter(item => {
        if (item.productId === action.payload.data.productId) {
          if (item.quantity && item.price) {
            removeTotalItem = item.quantity * item.price;
          }
          return item;
        }
      });

      const filteredItems = state.checkout.products?.filter(
        item => item.productId !== action.payload.data.productId,
      );

      return {
        ...state,
        checkout: {
          products: filteredItems,
          total:
            totalCheckout - removeTotalItem < 0
              ? 0
              : totalCheckout - removeTotalItem,
          countProduct: state.checkout.countProduct
            ? state.checkout.countProduct - 1
            : state.checkout.countProduct,
        },
        loading: false,
        error: false,
      };
    case checkoutActionTypes.ADD_PLU_QTY_CHECKOUT:
      let newTotal = 0;
      if (action.payload.data.price) newTotal = action.payload.data.price * 1;

      if (state.checkout.total === undefined) {
        return {
          ...state,
          checkout: {
            products: [
              {
                productId: action.payload.data.productId,
                quantity: 1,
                name: action.payload.data.name,
                price: action.payload.data.price,
                imageURL: action.payload.data.imageURL,
                categoryName: action.payload.data.categoryName,
                send: false,
              },
            ],
            total: newTotal,
          },
          loading: false,
          error: false,
        };
      }

      const isNew = state.checkout.products?.find(
        item => item.productId === action.payload.data.productId,
      );

      if (isNew) {
        state.checkout.products?.forEach(item => {
          if (item.productId === action.payload.data.productId)
            if (item.quantity === 0 || item.quantity) item.quantity += 1;
        });
      } else {
        state.checkout.products?.push({
          productId: action.payload.data.productId,
          quantity: 1,
          name: action.payload.data.name,
          price: action.payload.data.price,
          imageURL: action.payload.data.imageURL,
          categoryName: action.payload.data.categoryName,
          send: false,
        });

        return {
          ...state,
          checkout: {
            products: state.checkout.products,
            total: state.checkout.total + newTotal,
            countProduct: state.checkout.countProduct
              ? state.checkout.countProduct + 1
              : 1,
          },
          loading: false,
          error: false,
        };
      }

      return {
        ...state,
        loading: false,
        checkout: {
          ...state.checkout,
          products: state.checkout.products,
          total: state.checkout.total + newTotal,
        },
        error: false,
      };

    case checkoutActionTypes.UPD_PLU_QTY_CHECKOUT:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case checkoutActionTypes.REMOVE_PLU_QTY_CHECKOUT:
      let newTotalRemove = 0;
      if (action.payload.data.price)
        newTotalRemove = action.payload.data.price * 1;

      if (state.checkout.total === undefined) {
        return {
          ...state,
          checkout: {
            products: [
              {
                productId: action.payload.data.productId,
                quantity: 1,
              },
            ],
            total: newTotalRemove,
          },
          loading: false,
          error: false,
        };
      }

      state.checkout.products?.forEach(item => {
        if (item.productId === action.payload.data.productId && item.quantity)
          item.quantity -= 1;
      });

      return {
        ...state,
        loading: false,
        checkout: {
          products: state.checkout.products,
          total: state.checkout.total - newTotalRemove,
        },
        error: false,
      };
    case checkoutActionTypes.VIEW_CHECKOUT:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          productIdView: action.payload.filter.productId,
        },
        loading: false,
        error: false,
      };
    case checkoutActionTypes.FAILURE_CHECKOUT:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case checkoutActionTypes.FINISH_CHECKOUT:
      return {
        ...state,
        summary: {
          order_save: false,
        },
        loading: true,
        error: false,
      };
    case checkoutActionTypes.FINISH_CHECKOUT_SUCCESS:
      state.checkout.products?.filter(item => {
        if (!item.send) item.send = true;
      });

      console.log('FINISH_CHECKOUT_SUCCESS: ', action.payload);
      return {
        ...state,
        checkout: {
          ...state.checkout,
          total: Number(action.payload.data.grand_total),
        },
        summary: {
          ...state.summary,
          id: action.payload.data.id && action.payload.data.id,
          grand_total: Number(action.payload.data.grand_total).toFixed(2),
          sub_total: Number(action.payload.data.sub_total).toFixed(2),
          items: action.payload.data.items,
          order_save: false,
        },
        errorData: undefined,
        loading: false,
        error: false,
      };
    case checkoutActionTypes.FIND_CHECKOUT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case checkoutActionTypes.FIND_CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case checkoutActionTypes.CLOSE_SALE:
      return {
        ...state,
        errorData: undefined,
        loading: true,
        error: false,
      };
    case checkoutActionTypes.CLOSE_SALE_SUCCESS:
      return {
        ...state,
        checkout: {},
        summary: {
          order_save: true,
        },
        errorData: undefined,
        loading: true,
        error: false,
      };
    case checkoutActionTypes.CLOSE_SALE_FAILURE:
      return {
        ...state,
        errorData: action.payload.error,
        loading: false,
        error: true,
      };
    case checkoutActionTypes.PAYMENT_ESITEF_REQUEST:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case checkoutActionTypes.PAYMENT_ESITEF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case checkoutActionTypes.PAYMENT_ESITEF_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
