import React from 'react';
import { useTranslation } from 'react-i18next';

import { FiMenu, FiChevronDown, FiHome, FiUser } from 'react-icons/fi';
import { FaShoppingCart } from 'react-icons/fa';

import { NavLink } from 'react-router-dom';
import {
  Root,
  Container,
  Title,
  MenuListIten,
  MenuItem,
  ContainerMobile,
} from './styles';
import CategoryMenu from '../../components/CategoryMenu';

const Menu: React.FC = () => {
  const [t] = useTranslation('common');
  return (
    <Root>
      <Container>
        <Title>
          <FiMenu size={18} color="#fff" />
          <span>Categorias</span>
          <FiChevronDown size={18} color="#fff" />
        </Title>

        <MenuListIten>
          <MenuItem>Marca 01</MenuItem>
          <MenuItem>Marca 02</MenuItem>
          <MenuItem>Marca 03</MenuItem>
          <MenuItem>Marca 04</MenuItem>
          <MenuItem>Marca 05</MenuItem>
        </MenuListIten>
      </Container>
      <ContainerMobile>
        <MenuItem>
          <NavLink to="/">
            <FiHome size={32} color="#ff9d00" />
            <span>Início</span>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/checkout">
            <FaShoppingCart size={32} color="#ff9d00" />
            <span>Pedidos</span>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/account">
            <FiUser size={32} color="#ff9d00" />
            <span>Conta</span>
          </NavLink>
        </MenuItem>
      </ContainerMobile>
    </Root>
  );
};

export default Menu;
