import styled from 'styled-components';

import { color, layout } from '../../../package.json';

export const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 2px 3px var(--primary);
  background-color: ${color.background};

  @media (max-width: ${layout.desktop}) {
    display: flex;
  }
`;

export const Container = styled.div`
  width: 80%;
  height: 51px;

  display: flex;
  align-items: center;

  border-top: 1px solid #c6c6c6;

  @media (max-width: ${layout.mobile}) {
    display: none;
  }
`;

export const ContainerMobile = styled.div`
  width: 100%;
  height: 67px;

  display: none;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;

  border-top: 1px solid var(--primary);
  bottom: 0;
  left: 0;
  background-color: ${color.background};

  @media (max-width: ${layout.mobile}) {
    display: flex;
    z-index: 90;

    a {
      text-decoration: none;
    }
  }
`;

export const Title = styled.div`
  width: 330px;
  height: 54px;

  background-color: var(--primary);
  color: var(--menucolor);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px;

  span {
    font-size: 22px;
  }
`;

export const MenuListIten = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  flex-direction: row;
`;

export const MenuItem = styled.span`
  width: 110px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  color: #646464;
  border-left: 1px solid #fffff0;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: ${layout.mobile}) {
    svg {
      margin-right: 16px;
    }
  }

  :hover {
    color: var(--primary);
    cursor: pointer;
  }
`;
