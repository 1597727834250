/* eslint-disable camelcase */
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type ProductAction = ActionType<typeof actions>;

/**
 * Action types
 */
export enum ProductActionTypes {
  LOAD_REQUEST = '@product/LOAD_REQUEST',
  LOAD_SUCCCES = '@product/LOAD_SUCCCES',
  LOAD_FAILURE = '@product/LOAD_FAILURE',
  RESPONSE_SUCCCES = '@product/RESPONSE_SUCCCES',
  SEARCH_REQUEST = '@product/SEARCH_REQUEST',
  SEARCH_REQUEST_SUCCESS = '@product/SEARCH_REQUEST_SUCCESS',
  DETAILS = '@product/DETAILS',
}

export interface FindProductProps {
  categoryId?: string;
  priceMin?: number;
  priceMax?: number;
  moreProducts?: number;
  order?: string;
  term?: string;
}

export interface productData {
  type?: string;
  products?: ProductData;
}
export interface ResponseProps {
  to?: string;
  total?: string;
}

export interface CategoryData {
  id?: string;
  short_description: string;
  long_description: string;
}

export interface GalleryImages {
  small_image_url?: string;
  medium_image_url?: string;
  large_image_url?: string;
  original_image_url?: string;
}

export interface ProductData {
  base_qty: string;
  gallery_images: GalleryImages[];
  id: string;
  image_url: string;
  info_to_customer: string;
  long_description: string;
  max_qty: string;
  min_qty: string;
  price: string;
  product_id: string;
  short_description: string;
  sku: string;
  un: string;
  stars?: number;
  cart_item_id?: number;
  discount_amount?: string;
  quantity?: string;
  product?: ProductData;
  category?: CategoryData;
}

export interface ProductState {
  readonly products: ProductData[];
  readonly productDetalails: ProductData;
  readonly search: ProductData[];
  readonly response: ResponseProps;
  readonly filters: FindProductProps;
  readonly loading: boolean;
  readonly loadingSearch: boolean;
  readonly error: boolean;
}
