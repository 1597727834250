import React from 'react';

import { Container } from './styles';
import MenuCategory from './MenuCategory';
import MenuFilter from './MenuFilter';

interface FilterProps {
  categoryList?: boolean | false;
  categoryFilter?: boolean;
}

const Filter: React.FC<FilterProps> = ({
  categoryList = false,
  categoryFilter = false,
}) => {
  return (
    <Container
      className="filter_menu"
      categoryList={categoryList}
      categoryFilter={categoryFilter}
    >
      {categoryFilter && <MenuFilter />}
    </Container>
  );
};

export default Filter;
