import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

import { layout } from '../../../package.json';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  margin-left: 8px;
`;

interface ContainerProps {
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
  BgColor?: string;
  Color?: string;
  Padding?: string;
  Border?: string;
  BorderBottomRadiusNo?: boolean;
  error?: boolean;
}

export const ContainerInput = styled.div<ContainerProps>`
  background: #fff;
  border: 1px solid #e1e1e1;
  color: #666360;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  height: 50px;



  ${props =>
    props.BgColor &&
    css`
      background: ${props.BgColor};
    `}


  ${props =>
    props.Padding &&
    css`
      padding: ${props.Padding};
    `}

  ${props =>
    props.Border &&
    css`
      border: ${props.Border};
    `}

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.error &&
    css`
      & + div {
        margin-top: 8px;
      }
    `}

  ${props =>
    props.isFocused &&
    css`
      border: 1px solid var(--primary);
      color: var(--primary);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--primary);
    `}

  ${props =>
    props.isErrored &&
    css`
      border: 1px solid #c53030;
      color: #c53030;
    `}

  ${props =>
    props.BorderBottomRadiusNo &&
    css`
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    `}

  input {
    flex: 1;
    background: transparent;
    border: none;
    color: var(--primary);
    ${props =>
      props.Color &&
      css`
        color: ${props.Color};
      `}
    padding-left: 16px;
    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }

  /* @media (max-width: ${layout.desktop}) {
    width: 80vw;
  } */

`;

export const ContainerCheck = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;

  input {
    width: 25px;
    height: 25px;
    border-radius: 20px;
  }

  span {
    width: 100%;
    font-size: 16px;
  }
`;

export const ContainerError = styled.div`
  display: flex;
  justify-content: flex-start;

  width: 100%;
  height: 25px;

  span {
    color: #c53030;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin-right: 0;
  }

  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
