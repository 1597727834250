import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  ContainerNav,
  RootModal,
  ContainerModal,
  ModalHeader,
  ModalContent,
} from './styles';
import BaseMenu from '../BaseMenu';
import NavBar from '../NavBar';
import Search from '../Search';
import Menu from '../Menu';
import { StoreState } from '../../store/createStore';
import {
  openShowModal,
  viewModal,
} from '../../store/modules/parameter/actions';
import SignUpStep01 from '../../pages/SignUp/SignUpStep01';

const Master: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { openModal, viewModal: ModalBody, viewModalProps } = useSelector(
    (state: StoreState) => state.parameter,
  );

  const handleModalShow = useCallback(() => {
    dispatch(openShowModal());
  }, [dispatch]);

  useEffect(() => {
    if (openModal) dispatch(openShowModal());
    // dispatch(openShowModal(true));
    // dispatch(viewModal(SignUpStep01, { width: '50%', height: '80%' }));
  }, [dispatch]);

  return (
    <Container>
      {openModal && (
        <RootModal>
          <ContainerModal onClick={handleModalShow}>
            <ModalHeader />
          </ContainerModal>
          <ModalContent parameter={viewModalProps}>
            {ModalBody && <ModalBody />}
          </ModalContent>
        </RootModal>
      )}
      <ContainerNav>
        <BaseMenu />
        <NavBar />
        <Search />
        <Menu />
      </ContainerNav>
      {children}
      {/* <ContainerFooter /> */}
    </Container>
  );
};

export default Master;
