import React, { useRef, useEffect, useCallback } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons/lib';

interface Props extends InputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const InputMask: React.FC<Props> = ({ name, icon: Icon, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  // const beforeMaskedStateChange = useCallback(
  //   (newState, oldState, userInput) => {
  //     const { value, selection } = newState;
  //     const cursorPosition = selection ? selection.start : null;

  //     console.log(
  //       'beforeMaskedStateChange: ',
  //       value,
  //       selection,
  //       cursorPosition,
  //       value.length,
  //     );

  //     return {
  //       value,
  //       selection,
  //     };
  //   },
  //   [],
  // );

  return (
    <ReactInputMask
      ref={inputRef}
      defaultValue={defaultValue}
      // beforeMaskedValueChange={beforeMaskedStateChange}
      {...rest}
    />
  );
};

export default InputMask;
