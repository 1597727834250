import React, { createContext, useContext, useCallback, useState } from 'react';

interface DropDownProviderProps {
  align?: 'left' | 'right';
}

interface DropDownContextData {
  openMenu(align: DropDownProviderProps): void;
  isShowLeft: boolean;
  isShowRight: boolean;
}

const DropDownContext = createContext<DropDownContextData>(
  {} as DropDownContextData,
);

const DropDownProvider: React.FC = ({ children }) => {
  const [isShowLeftT, setisShowLeftT] = useState(false);
  const [isShowRightT, setisShowRightT] = useState(false);

  const openMenu = useCallback(
    ({ align }: DropDownProviderProps) => {
      console.log(align, isShowLeftT);

      if (align === 'left') setisShowLeftT(!isShowLeftT);
      console.log(align, isShowLeftT);
    },
    [isShowLeftT],
  );

  return (
    <DropDownContext.Provider
      value={{ openMenu, isShowLeft: isShowLeftT, isShowRight: isShowRightT }}
    >
      {children}
    </DropDownContext.Provider>
  );
};

function useDropDown(): DropDownContextData {
  const context = useContext(DropDownContext);

  if (!context) {
    throw new Error('useDropDown must be used within a useDropDown');
  }

  return context;
}

export { DropDownProvider, useDropDown };
