/* eslint-disable camelcase */
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { ProductData } from '../product/types';

export type checkoutAction = ActionType<typeof actions>;

/**
 * Action types
 */
export enum checkoutActionTypes {
  ADD_PLU_CHECKOUT = '@checkout/ADD_PLU_CHECKOUT',
  REMOVE_PLU_CHECKOUT = '@checkout/REMOVE_PLU_CHECKOUT',
  ADD_PLU_QTY_CHECKOUT = '@checkout/ADD_PLU_QTY_CHECKOUT',
  REMOVE_PLU_QTY_CHECKOUT = '@checkout/REMOVE_PLU_QTY_CHECKOUT',
  UPD_PLU_QTY_CHECKOUT = '@checkout/UPD_PLU_QTY_CHECKOUT',
  FAILURE_CHECKOUT = '@checkout/FAILURE_CHECKOUT',
  FINISH_CHECKOUT = '@checkout/FINISH_CHECKOUT',
  FINISH_CHECKOUT_SUCCESS = '@checkout/FINISH_CHECKOUT_SUCCESS',
  FIND_CHECKOUT = '@checkout/FIND_CHECKOUT',
  FIND_CHECKOUT_SUCCESS = '@checkout/FIND_CHECKOUT_SUCCESS',
  CLOSE_SALE = '@checkout/CLOSE_SALE',
  CLOSE_SALE_SUCCESS = '@checkout/CLOSE_SALE_SUCCESS',
  CLOSE_SALE_FAILURE = '@checkout/CLOSE_SALE_FAILURE',
  VIEW_CHECKOUT = '@checkout/VIEW_CHECKOUT',
  VOIDED_SALE = '@checkout/VOIDED_SALE',
  VOIDED_SALE_SUCCESS = '@checkout/VOIDED_SALE_SUCCESS',
  VOIDED_SALE_FAILURE = '@checkout/VOIDED_SALE_FAILURE',
  PAYMENT_ESITEF_REQUEST = '@checkout/PAYMENT_ESITEF_REQUEST',
  PAYMENT_ESITEF_SUCCESS = '@checkout/PAYMENT_ESITEF_SUCCESS',
  PAYMENT_ESITEF_FAILURE = '@checkout/PAYMENT_ESITEF_FAILURE',
}

export interface checkoutProductProps {
  productId?: string;
  name?: string;
  price?: number;
  quantity?: number;
  imageURL?: string;
  categoryName?: string;
  send?: boolean;
}

export interface checkoutData {
  type?: string;
  products?: checkoutProductProps[];
  total?: number;
  discount?: number;
  productIdView?: string;
  countProduct?: number | 0;
}

export interface checkoutDataSuccess {
  id?: string;
  grand_total?: string;
  sub_total?: string;
  items?: ProductData[];
  order_save?: boolean;
}

export interface errorData {
  code: string;
  message: string;
  data: string;
}

export interface checkoutState {
  readonly checkout: checkoutData;
  readonly summary?: checkoutDataSuccess;
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorData?: errorData;
}
