/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { ParameterActionTypes } from './types';

export function* openMenu({}: ActionType<typeof actions>) {
  try {
    // yield put(actions.findCategorySuccess(data.data));
  } catch (err) {
    // yield put(actions.findCategoryFailure());
  }
}

export default all([takeLatest(ParameterActionTypes.OPEN_MENU, openMenu)]);
