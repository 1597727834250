export enum routesEndpoints {
  HOME = '/',
  PRODUCTS = '/produtos',
  PRODUCT = '/produto',
  CHECKOUT = '/checkout',
  PAINEL = '/panel',
  SUMMARY = '/summary',
  ACCOUNT = '/account',
  ACCOUNT_PAYMENT = '/account/payment',
  ACCOUNT_ADDRESS = '/account/address',
  ACCOUNT_CREATE = '/account/create',
  SIGNIN = '/signin',
  SIGNUP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  VERIFICATION_MAIL = '/verification-mail',
  CONFIRMATION_MAIL = '/confirmation-mail',
  STORE_LOCATOR = '/store-locator',
}
