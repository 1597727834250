import styled, { css } from 'styled-components';

import { color, layout } from '../../../package.json';

interface BadgeNumberProps {
  number?: number;
}

export const Root = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;

  background-color: ${color.background};
  color: #e2e2e2;
`;

export const Container = styled.div`
  width: 80%;
  height: 80px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${layout.desktop}) {
    width: 100%;
  }
`;

export const ContainerLogo = styled.div`
  width: 400px;
  height: 75px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 260px;
  }

  @media (max-width: ${layout.desktop}) {
    display: none;
  }
`;

export const ContainerInputBox = styled.div`
  display: flex;

  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  width: 40%;
  /* height: 240px; */
  z-index: 8;

  @media (max-width: ${layout.desktop_wide}) {
    width: 100%;
  }
`;

export const ContainerInput = styled.div`
  width: 70%;
  height: 75px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 8;

  @media (max-width: ${layout.desktop_wide}) {
    width: 100%;
  }
`;

export const ContainerInputResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 30vw;
  height: 100%;

  z-index: 8;
  background: #f2f2f2;

  border: 1px solid var(--primary);
  border-top: none;
  border-radius: 10px;
  border-top-left-radius: unset;
  border-top-right-radius: unset;

  @media (max-width: ${layout.desktop_wide}) {
    width: 100%;
  }
`;

export const ContainerInputResultItem = styled.div`
  display: grid;

  justify-content: center;
  align-items: center;

  grid-template-areas:
    'imagem title title button'
    'imagem category price button';

  width: 95%;
  height: 60px;
  margin-top: 6px;

  color: var(--fontcolor);
  background: var(--white);

  :last-child {
    margin-bottom: 10px;
  }
`;

export const ContainerInputResultItemImage = styled.div`
  grid-area: imagem;

  display: flex;
  align-items: center;

  width: 60px;
  height: 60px;

  img {
    width: 50px;
    height: 50px;
  }
`;

export const ContainerInputResultItemTitle = styled.div`
  grid-area: title;

  display: flex;
  justify-content: flex-start;

  width: 500px;
  height: 30px;
`;

export const ContainerInputResultItemPrice = styled.div`
  grid-area: price;

  display: flex;
  justify-content: center;

  width: 245px;
  height: 30px;
`;

export const ContainerInputResultItemCategory = styled.div`
  grid-area: category;

  display: flex;
  justify-content: flex-start;

  width: 245px;
  height: 30px;
`;

export const ContainerInputResultItemActions = styled.div`
  grid-area: button;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 130px;
  height: 40px;
  border: 1px solid green;
  background: green;
  border-radius: 10px;
  margin-top: 10px;

  cursor: pointer;

  span {
    border-radius: 5px;
    color: var(--white);
  }

  svg {
    margin-right: 10px;
  }
`;

export const ContainerText = styled.div`
  width: 250px;
  height: 75px;

  display: flex;
  place-items: center;

  span {
    color: var(--primary);
    font-size: 18px;
  }

  @media (max-width: ${layout.desktop}) {
    display: none;
  }

  @media (max-width: ${layout.desktop_wide}) {
    width: 260px;
    span {
      font-size: 12px;
    }
  }
`;

export const ContainerBtns = styled.div`
  height: 75px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #363636;
    font-size: 36px;
    margin-right: 21px;
    :hover {
      color: var(--primary);
      cursor: pointer;
    }
  }

  @media (max-width: ${layout.desktop}) {
    display: none;
  }

  @media (max-width: ${layout.desktop_wide}) {
    svg {
      color: #363636;
      font-size: 28px;
      margin-right: 21px;
      :hover {
        color: var(--primary);
        cursor: pointer;
      }
    }
  }
`;

export const Botton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 120px;
  height: 75px;

  span {
    color: #999;
  }

  a {
    text-decoration: none;
    font-size: 13px;
  }
`;

export const ContainerCart = styled.div`
  width: 320px;
  height: 75px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  padding: 16px 16px;

  span {
    color: #303030;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    width: 250px;
    height: 15px;
    color: #303030;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
  }

  @media (max-width: ${layout.desktop}) {
    display: none;
  }

  @media (max-width: ${layout.desktop_wide}) {
    span {
      color: #303030;
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

export const BadgeNumber = styled.div<BadgeNumberProps>`
  width: 25px;
  height: 25px;
  border-radius: 25px;

  border: 1px solid var(--primary);

  position: absolute;
  margin-left: 23px;
  margin-top: -12px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--primary-light);

  span {
    color: #fff;
    font-size: 12px;
    font-weight: 800;
  }

  ${props =>
    props.number === undefined &&
    css`
      display: none;
    `}
`;

export const ContainerCartTotal = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const CartTotalBest = styled.div`
  span {
    font-size: 13px;
    margin-left: 6px;
    color: red;
    text-decoration: line-through;
  }
`;

export const CartTotal = styled.div`
  span {
    font-size: 16px;
    font-weight: 800;
    color: green;
    text-decoration: unset;
  }
`;
