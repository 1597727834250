import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  margin: 80px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;

  width: 100%;
  max-width: 1500px;
`;

const appearFronRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-450px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;
  animation: ${appearFronRight} 1s;

  img {
    width: 470px;
  }

  form {
    margin: 40px 0;
    text-align: center;
    width: 60%;

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.7s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }

    & + div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 100%;
      margin: 0;
      margin-bottom: 16px;

      > div {
        margin-left: 16px;
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 16px;

  font-family: Montserrat;
  font-style: normal;

  span {
    color: #000000;
    margin-left: 16px;
    font-size: 45px;
    font-weight: 700;
  }

  p {
    color: #474747;
    font-size: 36px;
    font-weight: 400;
  }
`;

export const ColInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 8px;
`;
