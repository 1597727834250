import React from 'react';
import { Switch } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Route from './Route';

import { routesEndpoints } from './endpoint';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Panel from '../pages/Panel';
import ProductList from '../pages/ProductList';
import ProductView from '../pages/ProductView';
import ForgotPassword from '../pages/ForgotPassword';
import Checkout from '../pages/Checkout';
import Summary from '../pages/Summary';
import Home from '../pages/Home';
import Account from '../pages/Account';
import AccountCreate from '../pages/Account/Create';
import { StoreState } from '../store/createStore';
import Payment from '../pages/Account/Payment';

import MobileHome from '../pages/MobileHome';
import Master from '../layout/Master';
import SignUpStep04 from '../pages/SignUp/SignUpStep04';
import SignUpStep05 from '../pages/SignUp/SignUpStep05';
import StoreLocator from '../pages/StoreLocator';
import AccountAddress from '../pages/Account/Address';

const Routes: React.FC = () => {
  const { openMenu } = useSelector((state: StoreState) => state.parameter);

  return (
    <Master>
      {openMenu ? (
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }} />
      ) : (
        <Switch>
          <>
            <Route path={routesEndpoints.HOME} exact component={Home} />
            <Route path="/mobile" exact component={MobileHome} />
            <Route
              path={routesEndpoints.PRODUCTS}
              exact
              component={ProductList}
            />
            <Route
              path={routesEndpoints.PRODUCT}
              exact
              component={ProductView}
            />
            <Route
              path={routesEndpoints.CHECKOUT}
              exact
              component={Checkout}
              isPrivate
            />
            <Route
              path={routesEndpoints.SUMMARY}
              exact
              component={Summary}
              isPrivate
            />
            <Route
              path={routesEndpoints.ACCOUNT}
              exact
              component={Account}
              isPrivate
            />
            <Route
              path={routesEndpoints.ACCOUNT_PAYMENT}
              exact
              component={Payment}
              isPrivate
            />
            <Route
              path={routesEndpoints.ACCOUNT_ADDRESS}
              exact
              component={AccountAddress}
              isPrivate
            />
            <Route
              path={routesEndpoints.ACCOUNT_CREATE}
              exact
              component={AccountCreate}
              isPrivate
            />
            <Route path={routesEndpoints.SIGNIN} exact component={SignIn} />
            <Route path={routesEndpoints.SIGNUP} exact component={SignUp} />
            <Route path={routesEndpoints.PAINEL} exact component={Panel} />
            <Route
              path={routesEndpoints.FORGOT_PASSWORD}
              exact
              component={ForgotPassword}
            />
            <Route
              path={routesEndpoints.VERIFICATION_MAIL}
              exact
              component={SignUpStep04}
            />
            <Route
              path={routesEndpoints.CONFIRMATION_MAIL}
              exact
              component={SignUpStep05}
            />
            <Route
              path={routesEndpoints.STORE_LOCATOR}
              exact
              component={StoreLocator}
            />
          </>
        </Switch>
      )}
    </Master>
  );
};

export default Routes;
