/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import axios from 'axios';
import * as actions from './actions';
import { AuthActionStep, AuthActionTypes, UserAddresses } from './types';
import api from '../../../services/api';
import api_esitef from '../../../services/api_esitef';

export function* signIn({ payload }: ActionType<typeof actions.signInRequest>) {
  try {
    const { email, password } = payload;

    const { data } = yield call(api.post, 'customer/login?token=true', {
      email,
      password,
    });

    console.log('signIn: ');

    yield put(
      actions.signInSuccess({
        token: data.data.token,
        user: {
          data: data.data.customer,
          addresses: data.data.customer.address,
          methods: data.data.customer.methods,
        },
      }),
    );
  } catch (err) {
    yield put(actions.signInFailure());
  }
}

export function* userPayment({
  payload,
}: ActionType<typeof actions.userPaymentRequest>) {
  try {
    console.log('userPayment payload:', payload);
    const payment = {
      payment: {
        method: payload.method,
      },
    };

    const { data } = yield call(
      api.post,
      `cart/save-payment/${payload.cart_id}`,
      payment,
    );
  } catch (err) {
    console.log(err);
  }
}

export function* userPaymentSelected({
  payload,
}: ActionType<typeof actions.userPaymentSelectedRequest>) {
  try {
    console.log('userPaymentSelected payload:', payload);
    const { data } = yield call(
      api.post,
      `methods/selected?token=${payload.token}`,
      {
        method_id: payload.method_id,
      },
    );

    const { data: methods } = yield call(
      api.get,
      `methods?token=${payload.token}`,
    );

    yield put(actions.userSavePaymentESitefSuccess(methods.data.methods));
  } catch (err) {
    console.log(err);
  }
}

export function* userAddress({
  payload,
}: ActionType<typeof actions.userAddressRequest>) {
  try {
    console.log('userAddress payload:', payload);

    if (payload) {
      payload.address.cart_id = payload.cart_id;
      payload.address.address_type = 'customer';
      payload.address.address_id = payload.address.id.toString();
      payload.address.name = payload.address.address_name;
      payload.address.shipping_method = 'shipment';
    }

    if (payload.address) {
      yield call(
        api.post,
        `cart/save-address/${payload.cart_id}`,
        payload.address,
      );

      const { data } = yield call(
        api.post,
        `addresses/selected?token=${payload.tokenUser}`,
        {
          address_id: payload.address.address_id,
        },
      );

      if (data.status === 200) {
        console.log('userAddress: selected', data);

        yield put(actions.userAddressSuccess(payload.address.address_id));
      }
    }
  } catch (err) {
    if (err.response.status === 401) yield put(actions.signInFailure());
    console.log(err);
  }
}

export function* userAddressSelected({
  payload,
}: ActionType<typeof actions.userAddressSelectedRequest>) {
  try {
    console.log('userAddressSelected: ', payload);
    const { data } = yield call(
      api.post,
      `addresses/selected?token=${payload.token}`,
      {
        address_id: payload.address.id,
      },
    );

    console.log('userAddressSelected: res', data);
    yield put(actions.userAddressSuccess(payload.address.id.toString()));
  } catch (err) {
    console.log(err);
  }
}

export function* userCreatePaymentESitef({
  payload,
}: ActionType<typeof actions.userSavePaymentESitef>) {
  try {
    console.log('userCreatePaymentESitef: ', payload);

    // const { data: responseESitef } = yield call(api_esitef.post, '/cards', {
    //   card: {
    //     expiry_date: payload.card.expiry_date,
    //     number: payload.card.card_number,
    //   },
    //   authorizer_id: payload.card.authorizer_id,
    //   customer_id: payload.card.customer_id,
    //   full_name: payload.card.full_name,
    //   merchant_usn: payload.card.merchant_usn,
    // });

    const responseESitef = {
      card: {
        token: '4d5sa4d56as4d5asd4as56d4as65d4sad65ad6d65sa456a4das56d4',
      },
      store: {
        nita: '454d6sa4da4a6d',
      },
    };

    console.log('responseESitef:', responseESitef);

    yield call(api.post, `methods/create?token=${payload.token}`, {
      method: 'esitef',
      card_number: payload.card.card_number,
      full_name: payload.card.full_name,
      expiry_date: payload.card.expiry_date,
      tef_token: responseESitef.card.token,
      tef_nita: responseESitef.store.nita,
    });

    const { data: methods } = yield call(
      api.get,
      `methods?token=${payload.token}`,
    );

    yield put(actions.userSavePaymentESitefSuccess(methods.data.methods));
  } catch (err) {
    console.log(err);
  }
}

export function* userListPayments({
  payload,
}: ActionType<typeof actions.userListPaymentRequest>) {
  try {
    console.log('userListPayments: ', payload);
    const { data } = yield call(api.get, `methods?token=${payload.token}`);

    yield put(actions.userListPaymentSuccess(data.data.methods));
  } catch (err) {
    console.log(err);
  }
}

export function* customerCreate({
  payload,
}: ActionType<typeof actions.customerCreateRequest>) {
  try {
    console.log('customerCreate: ', payload);

    const {
      data: { status, message, data },
    } = yield call(api.post, 'customer/register', payload.customer);

    console.log('customerCreate: res ', status, message, data);
    if (status === 202) yield put(actions.customerIsExist(message));
    if (status === 200)
      yield put(actions.customerCreateSuccess(data, payload.step));
  } catch (err) {
    console.log('customerCreate: Error|', err);
  }
}

export function* customerUpdate({
  payload,
}: ActionType<typeof actions.customerUpdateRequest>) {
  try {
    console.log('customerUpdate : ', payload);

    const {
      data: { status, message, data },
    } = yield call(
      api.put,
      `customer/profile?token=${payload.token}`,
      payload.customer,
    );

    console.log('customerUpdate: res ', status, message, data);
    // if (status === 202) yield put(actions.customerIsExist(message));
    if (status === 200) {
      yield put(actions.customerCreateSuccess(data, payload.step));
      if (payload.step === 'CST_STEP_04') {
        console.log('customerUpdate CST_STEP_04:', payload);
        const { data } = yield call(api.post, 'customer/login?token=true', {
          email: payload.customer.email,
          password: payload.customer.new_password,
        });
        yield put(
          actions.signInSuccess({
            token: data.data.token,
            user: {
              data: {
                first_name: data.data.customer.name,
                avatar:
                  'https://scontent.fcgh2-1.fna.fbcdn.net/v/t31.0-8/16707534_10202841213467847_6263367063774066423_o.jpg?_nc_cat=110&_nc_sid=09cbfe&_nc_ohc=3F8lVfRuvDgAX_XWPm6&_nc_ht=scontent.fcgh2-1.fna&oh=c5ee1b7d5595a803df0f9c675298fef2&oe=5F90DE79',
              },
              addresses: data.data.customer.address,
              methods: data.data.customer.methods,
            },
          }),
        );
      }
    }
  } catch (err) {
    console.log('customerCreate: Error|', err);
  }
}

export function* customerSendMailVerification({
  payload,
}: ActionType<typeof actions.customerSendMailRequest>) {
  try {
    console.log('customerUpdate : ', payload);

    const requestMail = {
      email_phone: payload.email,
      name: payload.name,
    };
    const {
      data: { status, message, data },
    } = yield call(api.post, 'verification/mail', requestMail);

    console.log('customerSendMailVerification: res:', status, message, data);
  } catch (err) {
    console.log('customerCreate: Error|', err);
  }
}

export function* customerConfirmMailVerification({
  payload,
}: ActionType<typeof actions.customerConfirmMailRequest>) {
  try {
    console.log('customerConfirmMailVerification : ', payload);

    const requestMail = {
      email_phone: payload.email,
      conf_code: payload.conf_code,
    };

    console.log('customerConfirmMailVerification: requestMail:', requestMail);
    const {
      data: { status, message, data },
    } = yield call(api.post, 'verification/mail-confirmation', requestMail);

    console.log('customerConfirmMailVerification: res:', status, message, data);
    yield put(actions.customerConfirmMailSuccess());
  } catch (err) {
    console.log('customerConfirmMailVerification: Error|', err);
  }
}

export function* findCEPRequest({
  payload,
}: ActionType<typeof actions.customerCEPRequest>) {
  try {
    console.log('findCEPRequest : ', payload);

    const requestCEP = {
      cep: payload.postcode,
    };

    console.log('findCEPRequest: requestMail:', requestCEP);
    const {
      data: { status, message, data },
    } = yield call(api.post, 'addresses/search/cep', requestCEP);

    console.log('findCEPRequest: res:', status, message, data);
    yield put(actions.customerCEPSuccess(data));
  } catch (err) {
    console.log('findCEPRequest: Error|', err);
  }
}

export function* saveAddressRequest({
  payload,
}: ActionType<typeof actions.customerSaveAddrewssRequest>) {
  try {
    console.log('saveAddressRequest : ', payload);

    const addressRequest: UserAddresses = {
      id: 0,
      address_id: '',
      country: 'BR',
      country_name: 'Brasil',
      ...payload.address,
    };

    console.log('saveAddressRequest->addressRequest: ', addressRequest);

    const {
      data: { status, message, data },
    } = yield call(
      api.post,
      `addresses/create?token=${payload.token}`,
      addressRequest,
    );

    console.log('saveAddressRequest: res:', status, message, data);
    yield put(actions.customerSaveAddrewssSuccess(data));
  } catch (err) {
    console.log('saveAddressRequest: Error|', err);
  }
}

export default all([
  takeLatest(AuthActionTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthActionTypes.PAYMENT_REQUEST, userPayment),
  takeLatest(AuthActionTypes.PAYMENT_SEL_REQUEST, userPaymentSelected),
  takeLatest(AuthActionTypes.ADDRESS_REQUEST, userAddress),
  takeLatest(AuthActionTypes.CARD_ESITEF_REQUEST, userCreatePaymentESitef),
  takeLatest(AuthActionTypes.CARD_LIST_REQUEST, userListPayments),
  takeLatest(AuthActionTypes.CUSTOMER_CREATE_REQUEST, customerCreate),
  takeLatest(AuthActionTypes.CUSTOMER_UPDATE_REQUEST, customerUpdate),
  takeLatest(AuthActionTypes.CUSTOMER_SEND_MAIL, customerSendMailVerification),
  takeLatest(
    AuthActionTypes.CUSTOMER_CONFIRM_MAIL,
    customerConfirmMailVerification,
  ),
  takeLatest(AuthActionTypes.CUSTOMER_CEP_REQUEST, findCEPRequest),
  takeLatest(
    AuthActionTypes.CUSTOMER_CREATE_ADDRESS_REQUEST,
    saveAddressRequest,
  ),
  takeLatest(AuthActionTypes.ADDRESS_SELECTED_REQUEST, userAddressSelected),
]);
