import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

import { layout } from '../../../package.json';

export const Root = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  margin-top: 8px;
  > div {
    margin-left: 8px;
  }
`;

export const ContainerTitle = styled.div`
  margin-bottom: 8px;
`;

export const ContainerOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  width: 100%;

  label {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      width: 25px;
      height: 25px;
      border-radius: 20px;
    }

    span {
      width: 100%;
      font-size: 16px;
      margin-left: 5px;
    }
  }
`;

export const Option = styled.label``;
