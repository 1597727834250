import React from 'react';

import * as firebase from 'firebase';
import 'firebase/messaging';

import { toast } from 'react-toastify';

import { Root, ContainerProducts } from './styles';

import Filter from '../../components/Header/Filter';
import ProdutctsList from '../../components/ProdutctsList';
import SlideProduct from '../../components/SlideProduct';

const Home: React.FC = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyD7-d6E4nPTNNT2xhPFqBqJLm_M-dyjTmU',
    authDomain: 'projeto-omni-291913.firebaseapp.com',
    databaseURL: 'https://projeto-omni-291913.firebaseio.com',
    projectId: 'projeto-omni-291913',
    storageBucket: 'projeto-omni-291913.appspot.com',
    messagingSenderId: '765706788865',
    appId: '1:765706788865:web:ef64dad9b7b383cb842000',
    measurementId: 'G-M3SBGERZEB',
  };

  if (!firebase.apps.length) {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  }

  const messaging = firebase.messaging();
  messaging
    .requestPermission()
    .then(async function () {
      const token = await messaging.getToken();

      console.log('getToken(): ', token);
    })
    .catch(function (err) {
      console.log('Unable to get permission to notify.', err);
    });

  navigator.serviceWorker.addEventListener('message', message => {
    console.log('serviceWorker addEventListener. ', message.data);
    const key = Object.keys(message.data);
    // uhuhu codigo que nem eu sei como funcionou hahahaha...topo
    const data = message.data[key[1]];

    console.log('data: ', data.data);
    toast.success(data.data.title, {
      position: 'top-right',
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  });

  return (
    <Root>
      <Filter categoryList categoryFilter={false} />
      <SlideProduct />
      <ContainerProducts>
        <ProdutctsList />
      </ContainerProducts>
    </Root>
  );
};

export default Home;
