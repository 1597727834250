import React, { useState } from 'react';

// import GoogleMapReact from 'google-map-react';
import MapGL from 'react-map-gl';
import { Marker } from 'react-map-gl';

import { Root, Container, Title, SubTitle, ResultStore } from './styles';

interface AnyReactComponentProps {
  text: string;
  lat?: number;
  lng?: number;
}

const AnyReactComponent: React.FC<AnyReactComponentProps> = ({ text }) => (
  <div>{text}</div>
);

const TOKEN =
  'pk.eyJ1Ijoid2ZlbGl4IiwiYSI6ImNraDJhb25obzBkb20yc2szY3Rxa2NwOGoifQ.STl1kjw-u9FSaxZK-h1Qbw';

const StoreLocator: React.FC = () => {
  const [location, setLocation] = useState({
    viewport: {
      latitude: -23.497529,
      longitude: -46.8536804,
      zoom: 12.8,
      bearing: 0,
      pitch: 0,
    },
  });

  const [location2, setLocation2] = useState({
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  });

  return (
    <Root>
      <Container>
        <Title>Localize uma Loja</Title>
        <SubTitle>Encontre a loja Super Conecto mais próxima de você!</SubTitle>
        <ResultStore>Lojas Encontradas (3)</ResultStore>
        <div style={{ height: '60vh', width: '80%' }}>
          {/* <MapGL
            width="100%"
            height="500px"
            {...location.viewport}
            mapStyle="mapbox://styles/mapbox/dark-v9"
            mapboxApiAccessToken={TOKEN}
            onViewportChange={viewport => setLocation({ viewport })}
          >
            <Marker key={1} longitude={-23.497529} latitude={-46.8536804}>
              <span
                style={{
                  fontSize: '18px',
                  padding: '5px',
                  background: '#fc6963',
                }}
              >
                Conecto Sistemas
              </span>
            </Marker>
          </MapGL> */}
          {/* <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCqad-HO86gnD-k-uLrU6SUDuheXR7BK9U',
            }}
            defaultCenter={location.center}
            defaultZoom={location.zoom}
          >
            <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            />
          </GoogleMapReact> */}
        </div>
      </Container>
    </Root>
  );
};

export default StoreLocator;
