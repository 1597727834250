/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { ProductActionTypes } from './types';
import api from '../../../services/api';

export function* findProducts({
  payload,
  type,
}: ActionType<typeof actions.findProductRequest>) {
  try {
    const { filters } = payload;

    if (!filters.categoryId) filters.categoryId = '1';
    if (!filters.moreProducts) filters.moreProducts = 18;
    if (!filters.order) filters.order = 'desc';

    const { data } = yield call(
      api.get,
      `product/search?category=${filters.categoryId}&term=&limit=${filters.moreProducts}&mode=left&page=1&sort=price&order=${filters.order}&price=${filters.priceMin},${filters.priceMax}`,
    );

    const response = {
      to: data.data.products.to,
      total: data.data.products.total,
    };

    yield put(actions.findProductSuccess(data.data.products.data));
    yield put(actions.responseSuccess(response));
  } catch (err) {
    yield put(actions.findProductFailure());
  }
}

export function* searchProducts({
  payload,
  type,
}: ActionType<typeof actions.findProductSearch>) {
  try {
    const { filters } = payload;

    if (!filters.categoryId) filters.categoryId = '1';
    if (!filters.order) filters.order = 'desc';

    const { data } = yield call(
      api.get,
      `product/searchall?term=${filters.term}&limit=3&mode=left&page=1&sort=price&order=${filters.order}&price=${filters.priceMin},${filters.priceMax}`,
    );

    const response = {
      to: data.data.products.to,
      total: data.data.products.total,
    };

    yield put(actions.findProductSearchSuccess(data.data.products.data));
    yield put(actions.responseSuccess(response));
  } catch (err) {
    yield put(actions.findProductFailure());
  }
}

export default all([
  takeLatest(ProductActionTypes.LOAD_REQUEST, findProducts),
  takeLatest(ProductActionTypes.SEARCH_REQUEST, searchProducts),
]);
