import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import superMarket from '../../assets/SUPERMARKET.png';

import { Container } from './styles';

const SlideProduct: React.FC = () => {
  return (
    <Container>
      <Carousel showThumbs={false} autoPlay infiniteLoop>
        <div>
          <img alt="" src={superMarket} />
          <p className="legend">Legend 1</p>
        </div>
        <div>
          <img alt="" src={superMarket} />
          <p className="legend">Legend 2</p>
        </div>
        <div>
          <img alt="" src={superMarket} />
          <p className="legend">Legend 3</p>
        </div>
        <div>
          <img alt="" src={superMarket} />
          <p className="legend">Legend 4</p>
        </div>
        <div>
          <img alt="" src={superMarket} />
          <p className="legend">Legend 5</p>
        </div>
        <div>
          <img alt="" src={superMarket} />
          <p className="legend">Legend 6</p>
        </div>
      </Carousel>
    </Container>
  );
};

export default SlideProduct;
