import styled from 'styled-components';

import { FiPlus, FiMinus } from 'react-icons/fi';

import { layout } from '../../../package.json';

interface ContainerBoxProps {
  width?: string;
  height?: string;
  border?: boolean;
  borderColor?: string;
}

interface TabProps {
  active?: boolean;
}

export const Root = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--fontcolor);
  background: #f7f7f7;

  .mg-b-16 {
    margin-bottom: 16px;
  }
`;

export const ClearRow = styled.div`
  clear: inherit;
`;

export const ContainerMyCart = styled.div`
  margin-top: 6px;
  margin-bottom: 16px;

  @media (max-width: ${layout.mobile}) {
    width: 95%;
  }
`;

export const MyCartTitle = styled.div`
  margin: 10px 10px;
  span {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Roboto';
  }
`;

export const ContainerSuggestions = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;

  @media (max-width: ${layout.mobile}) {
    width: 95%;
  }
`;

export const ProductsList = styled.div`
  @media (max-width: ${layout.mobile}) {
    width: 100%;
  }
`;

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 1500px;
  height: 130px;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  background: #fff;

  @media (max-width: ${layout.mobile}) {
    width: 100%;
    height: 105px;
  }
`;

export const ContainerImagem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 195px;
  height: 100%;

  @media (max-width: ${layout.mobile}) {
    width: 95px;
    height: 100%;
  }
`;

export const ProductImagem = styled.div`
  width: 115px;
  border-radius: 15px;

  img {
    border-radius: 15px;
    width: 112px;
    max-width: 112px;
    max-height: 112px;
  }

  @media (max-width: ${layout.mobile}) {
    width: 83px;
    img {
      border-radius: 15px;
      width: 80px;
    }
  }
`;

export const ContainerDetal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  width: 35%;
  height: 100%;
`;

export const ProductTitle = styled.div`
  padding: 6px;

  span {
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: ${layout.mobile}) {
    width: 250px;
    span {
      font-size: 11px;
    }
  }
`;

export const ProductCategory = styled.div`
  padding: 6px;

  span {
    color: var(--gray-light);
    font-size: 17px;
    font-weight: 400;
  }

  @media (max-width: ${layout.mobile}) {
    span {
      font-size: 10px;
    }
  }
`;

export const ProductPrice = styled.div`
  padding: 6px;

  span {
    color: var(--primary);
    font-size: 22px;
    font-weight: 600;
  }
  @media (max-width: ${layout.mobile}) {
    span {
      font-size: 16px;
    }
  }
`;

export const CotainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 50%;
  height: 40px;

  background: var(--gray-light);
  border: 1px solid var(--gray-light);
  border-radius: 15px;
  margin-top: 6px;

  @media (max-width: ${layout.mobile}) {
    width: 100%;
    height: 30px;
    padding: 10px;
  }
`;

export const ContainerQty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 25%;
  height: 20%;

  @media (max-width: ${layout.mobile}) {
    margin-top: 33px;
  }
`;

export const QtyTitle = styled.div`
  span {
    font-size: 19px;
    font-weight: 500;
  }

  @media (max-width: ${layout.mobile}) {
    span {
      font-size: 11px;
    }
  }
`;

export const PlusIcon = styled(FiPlus)``;

export const InputQty = styled.input`
  width: 100px;
  height: 100%;
  color: var(--gray);
  text-align: center;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: ${layout.mobile}) {
    font-size: 14px;
    width: 30px;
  }
`;

export const MinusIcon = styled(FiMinus)``;

export const ContainerRemove = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 25%;
  height: 100%;

  @media (max-width: ${layout.mobile}) {
    width: 10%;
    height: 100%;
    margin-top: 16px;
    margin-left: 16px;
    span {
      display: none;
    }
  }
`;

export const ButtonRemove = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 42px;

  border: 1px solid red;
  border-radius: 15px;
  background: red;
  margin-top: 32px;

  cursor: pointer;

  span {
    color: #fff;
    font-size: 16px;
    font-weight: 800;
  }

  svg {
    display: none;
  }

  @media (max-width: ${layout.mobile}) {
    width: 35px;
    height: 35px;

    margin-top: 0px;
    border-radius: 35px;

    svg {
      display: block;
    }
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 62px;

  @media (max-width: ${layout.mobile}) {
    width: 80%;
    height: 52px;
  }
`;

export const KeepBuyingBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid #ff9000;

  width: 300px;
  height: 100%;
  border-radius: 15px;
  padding: 24px 15px 24px 15px;

  background: #ff9000;
  cursor: pointer;

  span {
    color: #fff;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: ${layout.mobile}) {
    span {
      text-align: center;
      width: 140px;
      font-size: 12px;
    }
  }
`;

export const CheckoutBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid #009900;

  width: 300px;
  height: 100%;

  margin-left: 100px;

  border-radius: 15px;
  padding: 10px;
  padding: 24px 15px 24px 15px;

  background: #009900;
  cursor: pointer;

  span {
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: ${layout.mobile}) {
    margin-left: 20px;
    span {
      text-align: center;
      width: 140px;
      font-size: 12px;
    }
  }
`;

export const SuggestionsProducts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${layout.mobile}) {
    width: 100%;
  }
`;

export const SuggestionTitle = styled.div`
  width: 100%;
  margin: 5px 5px;

  span {
    font-size: 22px;
    font-weight: 400;
  }
`;

export const SuggestionProductList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SuggestionProductRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background: var(--white);

  @media (max-width: 1900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1630px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1420px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1210px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${layout.mobile}) {
    grid-template-columns: 1fr 1fr;
    margin-left: 5px;
  }
`;

export const SuggestionProductItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70% 30%;
  grid-template-areas:
    'imagem button'
    'detals detals';
  grid-gap: 10px;

  margin-left: 5px;
  margin-bottom: 5px;

  border-radius: 5px;
  background: var(--white);

  @media (max-width: ${layout.mobile}) {
    height: 190px;
  }
`;

export const SuggestionProductLeft = styled.div`
  grid-area: imagem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white);
`;

export const SuggestionProductRight = styled.div`
  grid-area: button;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white);
`;

export const SuggestionProductButtom = styled.div`
  grid-area: detals;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--white);
  height: 100%;
`;

export const SuggestionProductImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 15px;
    width: 112px;
  }

  @media (max-width: ${layout.mobile}) {
    img {
      width: 85px;
      height: 90px;
    }
  }
`;

export const SuggestionProductStar = styled.div`
  margin-top: 6px;
`;

export const SuggestionProductAdd = styled.div`
  background: #ff9000;

  padding: 5px;
  border-radius: 5px;
  span {
    font-size: 12px;
    color: var(--white);
    font-weight: 600;
  }
`;

export const SuggestionProductTitle = styled.div`
  margin: 6px 6px;
  span {
    font-size: 15px;
  }
`;

export const SuggestionProductPrice = styled.div`
  margin: 6px 6px;

  span {
    font-size: 18px;
    color: var(--primary);
    font-weight: 600;
  }
`;

export const Botton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 23px;
  height: 23px;

  cursor: pointer;
`;
