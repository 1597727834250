import styled, { css } from 'styled-components';

export const MenuGategory = styled.div`
  margin-top: 16px;
`;

export const MenuGategoryTitle = styled.div`
  border-bottom: 1px solid #ccc;
  padding-right: 6px;
  padding-left: 6px;
  padding-bottom: 6px;

  span {
    color: var(--primary);
    font-weight: 700;
  }
`;

export const MenuGategoryList = styled.ul``;

export const MenuGategoryItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  border-bottom: 1px solid #ccc;

  width: 100%;
  height: 40px;
  line-height: 30px;
`;

interface MenuGategoryItemTitleProps {
  main?: boolean;
  submenu?: number;
}

export const MenuGategoryItemTitle = styled.div<MenuGategoryItemTitleProps>`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 6px;

  span {
    font-size: 13px;
    font-weight: 500;
    color: #847979;
    ${props =>
      props.main &&
      css`
        font-weight: 800;
      `}

    ${props =>
      props.submenu &&
      css`
        padding-left: ${props.submenu}px;
        color: #a9a7a7;
      `}
  }
`;
