import { combineReducers } from 'redux';

import { StoreState } from '../createStore';

import auth from './auth/reducer';
import product from './product/reducer';
import category from './category/reducer';
import checkout from './checkout/reducer';
import parameter from './parameter/reducer';

export default combineReducers<StoreState>({
  auth,
  product,
  category,
  checkout,
  parameter,
});
