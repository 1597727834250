/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { ParameterAction, ParameterState, ParameterActionTypes } from './types';

const INITIAL_STATE: ParameterState = {
  isShowLeft: false,
  isShowRight: false,
  openMenu: false,
};

export default function parameter(
  state = INITIAL_STATE,
  action: ParameterAction,
): ParameterState {
  switch (action.type) {
    case ParameterActionTypes.OPEN_MENU:
      return {
        ...state,
        isShowLeft:
          action.payload.data.side === 'left' ? !state.isShowLeft : false,
        isShowRight:
          action.payload.data.side === 'right' ? !state.isShowRight : false,
      };
    case ParameterActionTypes.OPEN_MENU_BASE:
      console.log('OPEN_MENU_BASE');
      return {
        ...state,
        openMenu: !state.openMenu,
      };
    case ParameterActionTypes.OPEN_MODAL:
      return {
        ...state,
        openModal: action.payload.openForce ? true : !state.openModal,
      };
    case ParameterActionTypes.VIEW_MODAL:
      return {
        ...state,
        viewModal: action.payload.content,
        viewModalProps: action.payload.props,
      };
    default:
      return state;
  }
}
