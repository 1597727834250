import React, { useCallback, useEffect, useRef } from 'react';
import {
  FiArrowLeftCircle,
  FiArrowRightCircle,
  FiMail,
  FiUser,
} from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.png';

import { useAuth } from '../../hooks/auth';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Container,
  Content,
  ContainerStep,
  AnimationContainer,
  Title,
  ColInput,
} from './styles';
import { viewModal } from '../../store/modules/parameter/actions';

import SignUpStep01 from './SignUpStep01';
import { CustomerData, AuthActionStep } from '../../store/modules/auth/types';
import {
  customerClear,
  customerCreateRequest,
} from '../../store/modules/auth/actions';
import { StoreState } from '../../store/createStore';

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signUp } = useAuth();

  const dispatch = useDispatch();
  const { error, message, step } = useSelector(
    (state: StoreState) => state.auth,
  );

  const handleSubmit = useCallback(
    async (data: CustomerData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.password = 'mudar123';
        console.log('SignUp handleSubmit: ', data);
        // dispatch(viewModal(SignUpStep01, { width: '50%', height: '80%' }));
        // await signUp({
        //   email: data.email,
        //   password: data.password,
        // });

        dispatch(customerCreateRequest(data, AuthActionStep.CST_STEP_02));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [formRef, dispatch, signUp],
  );

  useEffect(() => {
    console.log('useEffect message: ', message);
    if (error && message) {
      toast.error(message, {
        position: 'top-right',
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(customerClear());
    }
  }, [error, message]);

  useEffect(() => {
    console.log('useEffect message: ', message);
    if (step === 'CST_STEP_02') {
      dispatch(customerClear());
      dispatch(viewModal(SignUpStep01, { width: '50%', height: '80%' }));
    }
  }, [step]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="ConectoVarejo" />

          <Form
            initialData={{
              first_name: 'Angela',
              last_name: 'Camilo',
              email: 'wfelix@conecto.com.br',
            }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Title>
              <span>Quero me Cadastrar</span>
            </Title>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <Input icon={FiUser} name="first_name" placeholder="Nome*" />
              <Input icon={FiUser} name="last_name" placeholder="Sobrenome" />
            </ColInput>

            <ColInput>
              <Input icon={FiMail} name="email" placeholder="E-mail*" />
            </ColInput>

            <ColInput>
              <Button
                button={{ type: 'submit' }}
                atributes={{
                  buttonType: 'primary',
                  textColor: '#fff',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
              >
                Continuar
              </Button>
            </ColInput>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
