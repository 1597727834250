import React, { useCallback, useEffect, useRef } from 'react';
import { FiUser, FiMail } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getValidationErrors from '../../../utils/getValidationErrors';

import logoImg from '../../../assets/logo.png';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import {
  Container,
  Content,
  AnimationContainer,
  Title,
  ColInput,
} from './styles';

import { CustomerData } from '../../../store/modules/auth/types';
import { customerConfirmMailRequest } from '../../../store/modules/auth/actions';

import { StoreState } from '../../../store/createStore';
import { routesEndpoints } from '../../../routes/endpoint';

const SignUpStep04: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user: {
      data: { first_name, last_name, email },
    },
    mailConfirm,
  } = useSelector((state: StoreState) => state.auth);

  const handleSubmit = useCallback(
    async (data: CustomerData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          conf_code: Yup.string()
            .required('Codigo obrigatório')
            .min(6, 'No mínimo 6 digitos')
            .max(6, 'No maximo 6 digitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        console.log('handleSubmit:', data);

        console.log('handleSubmit init');
        if (data.conf_code && email) {
          console.log('handleSubmit send');
          dispatch(customerConfirmMailRequest(data.conf_code, email));
          console.log('handleSubmit response');
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          console.log('handleSubmit errors:', errors);
        } else {
          console.log('handleSubmit error:', error);
        }
      }
    },
    [dispatch, email],
  );

  useEffect(() => {
    if (mailConfirm) history.push(routesEndpoints.CONFIRMATION_MAIL);
  }, [mailConfirm]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="ConectoVarejo" />
          <Form
            initialData={{ conf_code: '385113' }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Title>
              <span>Confirmação de endereço de e-mail</span>
            </Title>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <p>
                Olá <b>{`${first_name} ${last_name}`}</b>, enviamos um código
                para o endereço de e-mail <b>{` ${email} `}</b> que você
                forneceu, por favor, digite o código abaixo para que possamos
                confirmar a validade do seu endereço de e-mail.
              </p>
            </ColInput>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <Input icon={FiUser} name="conf_code" placeholder="Codigo*" />
            </ColInput>

            <ColInput>
              <Button
                button={{ type: 'submit' }}
                atributes={{
                  buttonType: 'success',
                  textColor: '#fff',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
              >
                Confirmar
              </Button>
            </ColInput>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <h3>Não recebeu nosso e-mail? Clique para re-enviar.</h3>
            </ColInput>

            <ColInput>
              <Button
                button={{ type: 'submit' }}
                atributes={{
                  buttonType: 'warning',
                  textColor: '#000',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
              >
                RE-ENVIAR
              </Button>
            </ColInput>

            <ColInput>
              <div style={{ marginBottom: '30px' }} />
            </ColInput>

            <ColInput>
              <h3>Quer receber o código em outro e-mail?</h3>
            </ColInput>

            <ColInput>
              <Input
                icon={FiMail}
                name="email"
                placeholder="Endereço de e-mail"
              />
              <Button
                button={{ type: 'button' }}
                atributes={{
                  buttonType: 'primary',
                  textColor: '#fff',
                  textSize: '30px',
                  borderColor: '#dddddd',
                }}
              >
                RE-ENVIAR
              </Button>
            </ColInput>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUpStep04;
