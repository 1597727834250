import React, { useCallback, useState } from 'react';

import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';

import {
  IoMdArrowDropdown,
  IoMdClose,
  IoMdArrowDropright,
} from 'react-icons/io';

import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  MenuCategoryFilter,
  MenuBreadcrumb,
  MenuBreadcrumbItem,
  MenuBreadcrumbDivider,
  FilterSelectTitle,
  FilterGroupBox,
  FilterGroup,
  FilterTitle,
  FilterOption,
  FilterInput,
  FilterClear,
  FilterRanger,
} from './styles';

import { findProductRequest } from '../../../../store/modules/product/actions';

import { StoreState } from '../../../../store/createStore';

const MenuFilter: React.FC = () => {
  const [sliderValue, setSliderValue] = useState({ min: 1, max: 1600 });

  const dispacth = useDispatch();
  const { filters } = useSelector((state: StoreState) => state.product);
  const { short_description } = useSelector(
    (state: StoreState) => state.category,
  );

  const hanleadInputRangesChange = useCallback(({ min, max }) => {
    setSliderValue({ min, max });
  }, []);

  const hanleadInputRanges = useCallback(async ({ min, max }) => {
    try {
      filters.priceMax = max;
      filters.priceMin = min;
      // dispacth(findProductRequest(filters));
    } catch (error) {
      console.log('hanleadInputRanges: error', error);
    }
  }, []);

  return (
    <Container>
      <MenuCategoryFilter>
        <MenuBreadcrumb>
          <MenuBreadcrumbItem>Home</MenuBreadcrumbItem>
          <MenuBreadcrumbDivider>/</MenuBreadcrumbDivider>
          <MenuBreadcrumbItem>Categorias</MenuBreadcrumbItem>
          <MenuBreadcrumbDivider>/</MenuBreadcrumbDivider>
          <MenuBreadcrumbItem>Cervejas</MenuBreadcrumbItem>
        </MenuBreadcrumb>
        <FilterSelectTitle>
          <span>{short_description}</span>
        </FilterSelectTitle>
        <FilterGroupBox>
          <FilterGroup>
            <FilterTitle>
              <IoMdArrowDropdown size="24" color="#303030" />
              <span>Categorias Relacionadas</span>
            </FilterTitle>
            <FilterOption>
              <span>Vinhos</span>
            </FilterOption>
            <FilterOption>
              <span>Whisky</span>
            </FilterOption>
          </FilterGroup>

          <FilterGroup>
            <FilterTitle>
              <IoMdArrowDropdown size="24" color="#303030" />
              <span>Filtrar por</span>
            </FilterTitle>
            <FilterInput>
              <input placeholder="Digite aqui" />
              <IoMdClose size="24" />
            </FilterInput>
            <FilterClear>
              <span>Limpar Filtros</span>
            </FilterClear>
          </FilterGroup>

          <FilterGroup>
            <FilterTitle>
              <IoMdArrowDropdown size="24" color="#303030" />
              <span>Preço</span>
            </FilterTitle>
            <FilterRanger>
              <InputRange
                step={2}
                maxValue={1600}
                minValue={0}
                formatLabel={value => `R$ ${value.toFixed(2)}`}
                value={sliderValue}
                onChange={value => hanleadInputRangesChange(value)}
                onChangeComplete={value => hanleadInputRanges(value)}
              />
            </FilterRanger>
          </FilterGroup>
          <FilterGroup>
            <FilterTitle>
              <IoMdArrowDropright size="24" color="#303030" />
              <span>Fabricante / Marca </span>
            </FilterTitle>
          </FilterGroup>
        </FilterGroupBox>
      </MenuCategoryFilter>
    </Container>
  );
};

export default MenuFilter;
