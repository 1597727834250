import React from 'react';

import { ContainerBanner, BannerTitle } from './styles';

import wineImg from '../../assets/WINE.png';

const Banner: React.FC = () => {
  return (
    <ContainerBanner>
      <img src={wineImg} alt="" />
      <BannerTitle>vinhos: 20% off</BannerTitle>
    </ContainerBanner>
  );
};

export default Banner;
