/* eslint-disable react/prop-types */
import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { IconBaseProps } from 'react-icons/lib';

import {
  Root,
  Container,
  ButtonAtributesProps,
  ContainerIcone,
} from './styles';

interface IconeProps {
  direction?: 'left' | 'right';
  size?: number | 25;
  color?: string | '#000';
}

type ButtonProps = {
  button: ButtonHTMLAttributes<HTMLButtonElement>;
  atributes?: ButtonAtributesProps;
  icon?: React.ComponentType<IconBaseProps>;
  icone?: IconeProps;
};

const Button: React.FC<ButtonProps> = ({
  children,
  button: { ...rest },
  atributes,
  icon: Icon,
  icone,
}) => {
  return (
    <Root {...atributes}>
      <Container type="button" {...rest} {...atributes}>
        {Icon && icone && icone.direction === 'left' ? (
          <ContainerIcone>
            <Icon
              size={icone.size && icone.size}
              color={icone.color && icone.color}
            />
          </ContainerIcone>
        ) : (
          <div />
        )}
        {children}
        {Icon && icone && icone.direction === 'right' ? (
          <ContainerIcone>
            <Icon
              size={icone.size && icone.size}
              color={icone.color && icone.color}
            />
          </ContainerIcone>
        ) : (
          <div />
        )}
      </Container>
    </Root>
  );
};

export default Button;
