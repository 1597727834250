/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { action } from 'typesafe-actions';

import { CategoryActionTypes, FindCategoryProps, CategoryData } from './types';

export function findCategoryRequest() {
  return action(CategoryActionTypes.LOAD_REQUEST);
}

export function findCategorySuccess(data: CategoryData[]) {
  return action(CategoryActionTypes.LOAD_SUCCCES, {
    data,
  });
}

export function findCategoryFailure() {
  return action(CategoryActionTypes.LOAD_FAILURE);
}

export function findCategorySubRequest(filters: FindCategoryProps) {
  return action(CategoryActionTypes.LOAD_REQUEST_SUB, {
    filters,
  });
}

export function findCategorySubSuccess(
  data: CategoryData[],
  parent_id: string,
  short_description: string,
) {
  return action(CategoryActionTypes.LOAD_SUCCCES_SUB, {
    data,
    parent_id,
    short_description,
  });
}

export function findCategorySubFailure() {
  return action(CategoryActionTypes.LOAD_FAILURE_SUB);
}

export function openCategory(category_id: string) {
  return action(CategoryActionTypes.CATEGORY_OPEN, {
    category_id,
  });
}

export function openCategorySub(category_id: string) {
  return action(CategoryActionTypes.CATEGORY_SUB_OPEN, {
    category_id,
  });
}
