import styled from 'styled-components';

import { layout } from '../../../../package.json';

export const Root = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--fontcolor);
  background: #f7f7f7;
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 16px;

  font-family: Montserrat;
  font-style: normal;

  span {
    color: #000000;
    margin-left: 16px;
    font-size: 45px;
    font-weight: 700;
  }

  p {
    color: #474747;
    font-size: 36px;
    font-weight: 400;
  }
`;

export const ColInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 8px;

  input.postcode {
    width: 250px !important;
    height: 50px !important;
    border: 1px solid var(--primary) !important;
    border-radius: 12px !important;
    font-size: 32px !important;
    text-align: center;
`;
