import styled, { css } from 'styled-components';

interface ContainerBoxProps {
  width?: string;
  height?: string;
  border?: boolean;
  borderColor?: string;
}

interface TabProps {
  active?: boolean;
}

export const Root = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--fontcolor);

  .mg-b-16 {
    margin-bottom: 16px;
  }
`;

export const ContainerBox = styled.div<ContainerBoxProps>`
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;

  position: relative;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}

  ${props =>
    props.border &&
    css`
      border: 1px solid red;
    `};

  ${props =>
    props.borderColor &&
    css`
      border: 1px solid ${props.borderColor};
    `};
`;

export const ContainerProductImage = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

export const ContainerProductImageSlug = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  width: 160px;
`;

export const ProductImageSlugItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 150px;

  margin-bottom: 6px;
  border: 1px solid #ccc;
  /* background: #000; */

  img {
    margin-top: 10px;
    width: 140px;
    max-width: 135px;
    max-height: 135px;
  }
`;

export const ContainerProductImagePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 90%;
  height: 530px;
  margin-left: 6px;

  /* background: #000; */
  border: 1px solid #ccc;

  img {
    margin-top: 10px;
    width: 525px;
    max-width: 500px;
    max-height: 500px;
  }
`;

export const ContainerProduct = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

export const ContainerProductDetails = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const DetailsStar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const DetailsEvaluation = styled.div`
  margin-left: 10px;
  font-size: 12px;
`;

export const DetailsSKU = styled.div`
  color: var(--gray-light);
`;

export const ContainerProductTitle = styled.div`
  margin-top: 16px;

  span {
    color: var(--gray);
    font-size: 26px;
    font-weight: 600;
  }
`;

export const ContainerProductStock = styled.div`
  margin-top: 16px;

  span {
    color: var(--green);
  }
`;

export const ContainerProductActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  width: 100%;
  height: 100px;
  border-radius: 15px;

  margin-top: 16px;

  border: 2px solid #e2e2e2;
`;

export const ContainerPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 420px;
  height: 100px;

  span:first-child {
    color: red;
    font-size: 25px;
    margin-right: 16px;
    text-decoration: line-through;
  }

  span {
    color: #404040;
    font-size: 35px;
    font-weight: 700;
  }

  span:last-child {
    color: var(--gray);
    font-size: 18px;
    margin-right: 16px;
    margin-left: 6px;
  }
`;

export const AddCart = styled.div`
  width: 180px;
  height: 60px;
  border-radius: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--primary-light);
  span {
    color: #fff;
    font-weight: 600;
    font-size: 22px;
  }

  :hover {
    background: var(--primary);
    border: 1px solid var(--primary);
    cursor: pointer;
  }
`;

export const FavoriteCart = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #e2e2e2;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    :hover {
      color: var(--primary);
      cursor: pointer;
    }
  }

  :hover {
    color: var(--primary);
    border: 1px solid var(--primary);
    cursor: pointer;
  }
`;

export const ContainerProductFeatured = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 16px;
  height: 100;
`;

export const ContainerTabs = styled.div`
  width: 50%;
`;

export const Tab = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  border-bottom: 3px solid var(--primary);
`;

export const TabTitle = styled.div<TabProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 50px;
  font-weight: 700;
  font-size: 20px;

  ${props =>
    props.active &&
    css`
      background: var(--primary);
      color: #fff;
    `}

  :hover {
    background: var(--primary);
    color: #fff;
    cursor: pointer;
  }
`;

export const TabContent = styled.div`
  padding: 20px;
`;

export const ContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const ContainerTitle = styled.div`
  font-size: 26px;
`;
