/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';

import {
  AiFillStar,
  AiOutlineStar,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { FaCartPlus, FaEye } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Root,
  Container,
  Image,
  ContentStar,
  Title,
  Amount,
  Action,
  Botton,
} from './styles';
import {
  checkoutAddPlu,
  checkoutRemovePlu,
  checkoutAddQtyPlu,
  checkoutRemoveQtyPlu,
  checkoutView,
} from '../../store/modules/checkout/actions';
import {
  checkoutData,
  checkoutActionTypes,
  checkoutProductProps,
} from '../../store/modules/checkout/types';
import { StoreState } from '../../store/createStore';
import {
  productData,
  ProductData,
  ProductActionTypes,
} from '../../store/modules/product/types';
import { detailsProduct } from '../../store/modules/product/actions';

interface ProductItemProps {
  product?: ProductData;
  width?: number | 195;
  height?: number | 270;
  loading?: boolean;
}

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  width,
  height,
  loading,
}) => {
  const [productQty, setProductQty] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const { checkout, summary } = useSelector(
    (state: StoreState) => state.checkout,
  );

  useEffect(() => {
    checkout.products?.forEach(item => {
      if (item.productId === product?.product_id && item.quantity)
        setProductQty(item.quantity);
    });
  }, [checkout]);

  const handleProduct = useCallback(
    async (data: checkoutData, product: checkoutProductProps) => {
      switch (data.type) {
        case checkoutActionTypes.ADD_PLU_CHECKOUT:
          dispatch(checkoutAddPlu(product));
          break;
        case checkoutActionTypes.REMOVE_PLU_CHECKOUT:
          if (summary?.id) dispatch(checkoutRemovePlu(summary?.id, product));
          break;
        case checkoutActionTypes.ADD_PLU_QTY_CHECKOUT:
          dispatch(checkoutAddQtyPlu(product));
          break;
        case checkoutActionTypes.REMOVE_PLU_QTY_CHECKOUT:
          dispatch(checkoutRemoveQtyPlu(product));
          break;
        case checkoutActionTypes.VIEW_CHECKOUT:
          dispatch(checkoutView(product));
          history.push('/produto');
          break;
        default:
      }
    },
    [],
  );

  const handleDetailsProduct = useCallback(
    async (data: productData, product: productData) => {
      console.log('handleDetailsProduct: ', product);
      switch (data.type) {
        case ProductActionTypes.DETAILS:
          if (product.products) dispatch(detailsProduct(product.products));
          history.push('/produto');
          break;

        default:
      }
    },
    [],
  );

  return (
    <Root>
      {loading ? (
        <Skeleton duration={2} width={width} height={height} />
      ) : (
        <Container>
          <Image>
            <img src={product?.image_url} alt={product?.short_description} />
          </Image>
          <ContentStar>
            <AiFillStar size={19} color="#ffb503" />
            <AiFillStar size={19} color="#ffb503" />
            <AiFillStar size={19} color="#ffb503" />
            <AiFillStar size={19} color="#ffb503" />
            <AiOutlineStar size={19} color="#999" />
          </ContentStar>
          <Title>
            <span>{product?.short_description}</span>
          </Title>
          <Amount>R$ {Number(product?.price).toFixed(2)}</Amount>
          <Action className="action">
            <Botton
              onClick={() =>
                handleProduct(
                  { type: checkoutActionTypes.REMOVE_PLU_QTY_CHECKOUT },
                  {
                    productId: product?.product_id,
                    name: product?.short_description,
                    price: Number(product?.price),
                  },
                )}
            >
              <AiOutlineMinus color="red" />
            </Botton>
            <input type="number" value={productQty} />
            <Botton
              onClick={() =>
                handleProduct(
                  { type: checkoutActionTypes.ADD_PLU_QTY_CHECKOUT },
                  {
                    productId: product?.product_id,
                    name: product?.short_description,
                    price: Number(product?.price),
                    imageURL: product?.image_url,
                    categoryName: product?.category?.short_description,
                  },
                )}
            >
              <AiOutlinePlus color="green" />
            </Botton>
            <Botton
              onClick={() =>
                handleProduct(
                  { type: checkoutActionTypes.ADD_PLU_QTY_CHECKOUT },
                  {
                    productId: product?.product_id,
                    name: product?.short_description,
                    price: Number(product?.price),
                    imageURL: product?.image_url,
                    categoryName: product?.category?.short_description,
                  },
                )}
            >
              <FaCartPlus color="green" />
            </Botton>
            <Botton
              onClick={() =>
                handleDetailsProduct(
                  { type: ProductActionTypes.DETAILS },
                  { products: product && product },
                )}
            >
              <FaEye color="#4d79ff" />
            </Botton>
          </Action>
        </Container>
      )}
    </Root>
  );
};

export default ProductItem;
