/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { action } from 'typesafe-actions';

import {
  checkoutActionTypes,
  checkoutProductProps,
  checkoutData,
  checkoutDataSuccess,
  errorData,
} from './types';
import { User } from '../auth/types';

export function checkoutAddPlu(data: checkoutProductProps) {
  return action(checkoutActionTypes.ADD_PLU_CHECKOUT, {
    data,
  });
}

export function checkoutRemovePlu(id: string, data: checkoutProductProps) {
  return action(checkoutActionTypes.REMOVE_PLU_CHECKOUT, {
    id,
    data,
  });
}

export function checkoutAddQtyPlu(data: checkoutProductProps) {
  return action(checkoutActionTypes.ADD_PLU_QTY_CHECKOUT, {
    data,
  });
}

export function checkoutUpdQtyPlu(id: string, product: checkoutProductProps) {
  return action(checkoutActionTypes.UPD_PLU_QTY_CHECKOUT, {
    id,
    product,
  });
}

export function checkoutRemoveQtyPlu(data: checkoutProductProps) {
  return action(checkoutActionTypes.REMOVE_PLU_QTY_CHECKOUT, {
    data,
  });
}

export function checkoutView(filter: checkoutProductProps) {
  return action(checkoutActionTypes.VIEW_CHECKOUT, {
    filter,
  });
}

export function checkoutSend(user: User, checkout: checkoutData) {
  return action(checkoutActionTypes.FINISH_CHECKOUT, {
    user,
    checkout,
  });
}

export function checkoutSendSucess(data: checkoutDataSuccess) {
  return action(checkoutActionTypes.FINISH_CHECKOUT_SUCCESS, {
    data,
  });
}

export function checkoutFailure() {
  return action(checkoutActionTypes.FAILURE_CHECKOUT);
}

export function checkoutFind(id: string, data: checkoutData) {
  return action(checkoutActionTypes.FIND_CHECKOUT, {
    id,
    data,
  });
}

export function checkoutFindSucess(data: checkoutDataSuccess) {
  return action(checkoutActionTypes.FIND_CHECKOUT_SUCCESS, {
    data,
  });
}

export function closeSale(id: string, sumary: checkoutDataSuccess) {
  return action(checkoutActionTypes.CLOSE_SALE, {
    id,
    sumary,
  });
}

export function closeSaleSucess() {
  return action(checkoutActionTypes.CLOSE_SALE_SUCCESS);
}

export function closeSaleFailure(error: errorData) {
  return action(checkoutActionTypes.CLOSE_SALE_FAILURE, {
    error,
  });
}

export function paymentESitefRequest() {
  return action(checkoutActionTypes.PAYMENT_ESITEF_REQUEST);
}

export function paymentESitefSuccess() {
  return action(checkoutActionTypes.PAYMENT_ESITEF_SUCCESS);
}

export function paymentESitefFailure() {
  return action(checkoutActionTypes.PAYMENT_ESITEF_FAILURE);
}
