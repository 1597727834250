import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { FiUser } from 'react-icons/fi';
import { writevSync } from 'fs';
import { Root, Title, ColInput } from './styles';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import CardAddress from '../../../components/CardAddress';
import { UserAddresses } from '../../../store/modules/auth/types';
import InputMask from '../../../components/InputMask';
import {
  customerCEPRequest,
  customerCEPClear,
  customerSaveAddrewssRequest,
} from '../../../store/modules/auth/actions';
import { StoreState } from '../../../store/createStore';

const AccountAddress: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const [postcode, setPostcode] = useState('');

  const { address, token } = useSelector((state: StoreState) => state.auth);

  const handleSubmit = useCallback(async (data: UserAddresses) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        postcode: Yup.string()
          .required('CEP obrigatório')
          .min(9, 'No mínimo 6 digitos')
          .max(9, 'No maximo 6 digitos'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      console.log('handleSubmit:', data);

      dispatch(customerSaveAddrewssRequest(data, token));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        console.log('handleSubmit errors:', errors);
      } else {
        console.log('handleSubmit error:', error);
      }
    }
  }, []);

  const handleCEPChange = useCallback(newState => {
    const { value, selection } = newState;

    if (selection.end === 9) {
      setPostcode(value);
    }

    return {
      value,
      selection,
    };
  }, []);

  useEffect(() => {
    dispatch(customerCEPClear());

    setTimeout(() => {
      if (postcode) dispatch(customerCEPRequest(postcode));
    }, 300);

    console.log('postcode: ', postcode);
  }, [dispatch, postcode]);

  useEffect(() => {
    if (formRef.current && address?.address) {
      formRef.current.setData({
        postcode: address.postcode,
        address: address?.address,
        neighborhood: address?.neighborhood,
        state: address.state,
        city: address?.city,
      });
    }
  }, [formRef, address]);

  return (
    <Root>
      <Form
        // initialData={{ postcode: '06422070' }}
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <Title>
          <span>Cadastro de endereço</span>
        </Title>
        <ColInput>
          <div style={{ marginBottom: '30px' }} />
        </ColInput>
        <ColInput>
          <InputMask
            mask="99999-999"
            icon={FiUser}
            name="postcode"
            placeholder="CEP*"
            beforeMaskedValueChange={handleCEPChange}
            className="postcode"
          />
        </ColInput>

        <ColInput>
          <div style={{ marginBottom: '30px' }} />
        </ColInput>
        {address && (
          <>
            <ColInput>
              <Input
                icon={FiUser}
                name="name"
                placeholder="Nome do Endereço*"
              />
            </ColInput>

            <ColInput>
              <Input
                icon={FiUser}
                name="address"
                placeholder="Endereço/Logradouro.*"
              />
            </ColInput>

            <ColInput>
              <Input icon={FiUser} name="number" placeholder="Número*" />
              <Input
                icon={FiUser}
                name="complement"
                placeholder="Complemento"
              />
            </ColInput>

            <ColInput>
              <Input icon={FiUser} name="neighborhood" placeholder="Bairro*" />
              <Input icon={FiUser} name="city" placeholder="Cidade*" />
            </ColInput>

            <ColInput>
              <Input icon={FiUser} name="state" placeholder="Estado*" />
            </ColInput>
          </>
        )}
        <ColInput>
          <Button
            button={{ type: 'submit' }}
            atributes={{
              buttonType: 'primary',
              textColor: '#fff',
              textSize: '30px',
              borderColor: '#dddddd',
            }}
          >
            Salvar
          </Button>
        </ColInput>
      </Form>

      <CardAddress title="Meus endereços" />
    </Root>
  );
};

export default AccountAddress;
