import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type CategoryAction = ActionType<typeof actions>;

/**
 * Action types
 */
export enum CategoryActionTypes {
  LOAD_REQUEST = '@category/LOAD_REQUEST',
  LOAD_SUCCCES = '@category/LOAD_SUCCCES',
  LOAD_FAILURE = '@category/LOAD_FAILURE',
  LOAD_REQUEST_SUB = '@category/LOAD_REQUEST_SUB',
  LOAD_SUCCCES_SUB = '@category/LOAD_SUCCCES_SUB',
  LOAD_FAILURE_SUB = '@category/LOAD_FAILURE_SUB',
  CATEGORY_OPEN = '@category/CATEGORY_OPEN',
  CATEGORY_SUB_OPEN = '@category/CATEGORY_SUB_OPEN',
}

export interface FindCategoryProps {
  id?: string;
  short_description?: string;
}

export interface CategoryData {
  id: string;
  short_description: string;
  open?: boolean;
  childrens?: CategoryData[];
  root: boolean;
}

export interface CategoryState {
  readonly categorys: CategoryData[];
  readonly parent_id: string;
  readonly short_description: string;
  readonly categorysSub: CategoryData[];
  readonly filters: FindCategoryProps;
  readonly loading: boolean;
  readonly error: boolean;
}
