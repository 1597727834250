import React from 'react';

import { NavLink } from 'react-router-dom';

import {
  Root,
  ContainerMyData,
  MyDataTitle,
  MyData,
  ContainerActions,
  BackBtn,
  LogoutBtn,
  DeleteAccountBtn,
} from './styles';

import CardPayment from '../../components/CardPayment';
import CardAddress from '../../components/CardAddress';

const Account: React.FC = () => {
  return (
    <Root>
      <ContainerMyData>
        <MyDataTitle>
          <span>Minha Conta</span>
        </MyDataTitle>
        <MyData />
      </ContainerMyData>

      <CardPayment title="Meus Pagamentos" />
      <CardAddress title="Meus Endereços" />

      <ContainerActions>
        <BackBtn>
          <span>VOLTAR</span>
        </BackBtn>
        <LogoutBtn>
          <NavLink to="/">
            <span>FAZER LOGOUT</span>
          </NavLink>
        </LogoutBtn>
      </ContainerActions>

      <ContainerActions>
        <DeleteAccountBtn>
          <span>EXCLUIR CONTA</span>
        </DeleteAccountBtn>
      </ContainerActions>
    </Root>
  );
};

export default Account;
