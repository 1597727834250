import styled from 'styled-components';

import { color, layout } from '../../../package.json';

export const Root = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: ${color.background};
`;

export const ContainerProducts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  padding-left: 318px;
  padding-top: 10px;

  @media (max-width: ${layout.mobile}) {
    padding-left: 0px;
  }

  @media (max-width: ${layout.desktop}) {
  }

  @media (max-width: ${layout.desktop_wide}) {
  }
`;

export const ContainerFilter = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 90%;
  height: 40px;

  @media (max-width: ${layout.mobile}) {
    display: flex;
    padding-left: 0px;
  }
`;

export const FilterCategory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: var(--gray);
  width: 70%;

  span {
    width: 95%;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 6px;
  }
`;

export const FilterCategoryTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: var(--primary);
  width: 70%;

  span {
    font-size: 12px;
    cursor: pointer;
  }
`;

export const FilterAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  border: 1px solid var(--primary);
  border-radius: 15px;
  color: var(--primary);
  width: 30%;

  span {
    margin-left: 6px;
    cursor: pointer;
  }
`;
