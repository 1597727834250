import React, { useState, useEffect, useCallback } from 'react';

import { FaStar, FaRegHeart } from 'react-icons/fa';

import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux';
import {
  Root,
  ContainerBox,
  ContainerProductImage,
  ContainerProductImageSlug,
  ProductImageSlugItem,
  ContainerProductImagePreview,
  ContainerProduct,
  ContainerProductDetails,
  DetailsStar,
  DetailsEvaluation,
  DetailsSKU,
  ContainerProductTitle,
  ContainerProductStock,
  ContainerProductActions,
  ContainerPrice,
  AddCart,
  FavoriteCart,
  ContainerProductFeatured,
  ContainerTabs,
  Tab,
  TabTitle,
  TabContent,
  ContainerTitle,
  ContainerSection,
} from './styles';

import {
  ContainerProductsResult,
  ContainerProductsResultRow,
} from '../ProductList/styles';
import Banner from '../../components/Banner';
import { StoreState } from '../../store/createStore';
import {
  checkoutData,
  checkoutProductProps,
  checkoutActionTypes,
} from '../../store/modules/checkout/types';
import { checkoutAddQtyPlu } from '../../store/modules/checkout/actions';

const ProductView: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { productDetalails } = useSelector(
    (state: StoreState) => state.product,
  );

  const handleProduct = useCallback(
    async (data: checkoutData, product: checkoutProductProps) => {
      switch (data.type) {
        case checkoutActionTypes.ADD_PLU_QTY_CHECKOUT:
          dispatch(checkoutAddQtyPlu(product));
          break;
        default:
      }
    },
    [],
  );
  useEffect(() => {
    setLoading(false);
  }, [productDetalails]);

  return (
    <Root>
      <ContainerBox width="80%" height="100%">
        <ContainerProductImage>
          <ContainerProductImageSlug>
            {loading ? (
              <Skeleton duration={2} width={140} height={134} />
            ) : (
              <>
                {productDetalails.gallery_images &&
                  productDetalails.gallery_images.map(image => (
                    <>
                      <ProductImageSlugItem>
                        <img
                          src={image.small_image_url}
                          alt={productDetalails.short_description}
                        />
                      </ProductImageSlugItem>
                    </>
                  ))}
              </>
            )}
          </ContainerProductImageSlug>
          {loading ? (
            <Skeleton duration={2} width={859} height={528} />
          ) : (
            <ContainerProductImagePreview>
              <img
                src={productDetalails.image_url}
                alt={productDetalails.short_description}
              />
            </ContainerProductImagePreview>
          )}
        </ContainerProductImage>
        <ContainerProduct>
          <ContainerProductDetails>
            {loading ? (
              <Skeleton
                duration={2}
                width={239}
                height={18}
                className="mg-b-16"
              />
            ) : (
              <DetailsStar>
                <FaStar color="var(--star-on)" />
                <FaStar color="var(--star-on)" />
                <FaStar color="var(--star-on)" />
                <FaStar color="var(--star-on)" />
                <FaStar color="var(--star-off)" />
                <DetailsEvaluation>
                  <span>1 avaliação deste produto</span>
                </DetailsEvaluation>
              </DetailsStar>
            )}
            {loading ? (
              <Skeleton
                duration={2}
                width={158}
                height={18}
                className="mg-b-16"
              />
            ) : (
              <DetailsSKU>
                <span>
                  <b>SKU:</b> {productDetalails.sku}
                </span>
              </DetailsSKU>
            )}
          </ContainerProductDetails>
          {loading ? (
            <Skeleton
              duration={2}
              width={814}
              height={31}
              className="mg-b-16"
            />
          ) : (
            <ContainerProductTitle>
              <span>{productDetalails.short_description}</span>
            </ContainerProductTitle>
          )}
          {loading ? (
            <Skeleton
              duration={2}
              width={814}
              height={18}
              className="mg-b-16"
            />
          ) : (
            <ContainerProductStock>
              <span>Disponivel</span>
            </ContainerProductStock>
          )}
          {loading ? (
            <Skeleton
              duration={2}
              width={814}
              height={100}
              className="mg-b-16"
            />
          ) : (
            <ContainerProductActions>
              <ContainerPrice>
                <span>R$ {Number(productDetalails.price).toFixed(2)}</span>
                <span>R$ {Number(productDetalails.price).toFixed(2)}</span>
                <span>/ {productDetalails.un}</span>
              </ContainerPrice>
              <AddCart
                onClick={() =>
                  handleProduct(
                    { type: checkoutActionTypes.ADD_PLU_QTY_CHECKOUT },
                    {
                      productId: productDetalails?.product_id,
                      name: productDetalails?.short_description,
                      price: Number(productDetalails?.price),
                      imageURL: productDetalails?.image_url,
                      categoryName:
                        productDetalails?.category?.short_description,
                    },
                  )}
              >
                <span>CARRINHO</span>
              </AddCart>
              <FavoriteCart>
                <FaRegHeart size={24} />
              </FavoriteCart>
            </ContainerProductActions>
          )}
          {loading ? (
            <Skeleton
              duration={2}
              width={814}
              height={250}
              className="mg-b-16"
            />
          ) : (
            <ContainerProductFeatured>
              <Banner />
            </ContainerProductFeatured>
          )}
        </ContainerProduct>
      </ContainerBox>
      <ContainerBox width="80%" height="100%">
        <ContainerTabs>
          <Tab>
            <TabTitle active>Descrição</TabTitle>
            <TabTitle>Avaliações</TabTitle>
          </Tab>
          {loading ? (
            <Skeleton
              duration={2}
              width={1018}
              height={250}
              className="mg-b-16"
            />
          ) : (
            <TabContent>
              <p>{productDetalails.long_description}</p>
            </TabContent>
          )}
        </ContainerTabs>
      </ContainerBox>
      <ContainerBox width="80%" height="100%">
        <ContainerSection>
          <ContainerTitle>
            <span>Visualizado Recentemente</span>
          </ContainerTitle>
          <ContainerProductsResult>
            <ContainerProductsResultRow>
              {/* <ProductItem
                  key="1"
                  productId="1"
                  image=""
                  title="Produto 01"
                  price={Number('15.56')}
                  width={1800}
                  height={260}
                  loading={loading}
                /> */}
            </ContainerProductsResultRow>
          </ContainerProductsResult>
        </ContainerSection>
      </ContainerBox>

      <ContainerBox width="80%" height="100%">
        <ContainerSection>
          <ContainerTitle>
            <span>Produtos Relacionados</span>
          </ContainerTitle>
          <ContainerProductsResult>
            <ContainerProductsResultRow>
              {/* <ProductItem
                  key="1"
                  productId="1"
                  image=""
                  title="Produto 01"
                  price={Number('15.56')}
                  width={1800}
                  height={260}
                  loading={loading}
                /> */}
            </ContainerProductsResultRow>
          </ContainerProductsResult>
        </ContainerSection>
      </ContainerBox>
    </Root>
  );
};

export default ProductView;
