/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { action } from 'typesafe-actions';

import {
  ProductActionTypes,
  FindProductProps,
  ResponseProps,
  ProductData,
} from './types';

export function findProductRequest(filters: FindProductProps) {
  return action(ProductActionTypes.LOAD_REQUEST, {
    filters,
  });
}

export function findProductSuccess(data: ProductData[]) {
  return action(ProductActionTypes.LOAD_SUCCCES, {
    data,
  });
}

export function findProductSearch(filters: FindProductProps) {
  return action(ProductActionTypes.SEARCH_REQUEST, {
    filters,
  });
}

export function findProductSearchSuccess(data: ProductData[]) {
  return action(ProductActionTypes.SEARCH_REQUEST_SUCCESS, {
    data,
  });
}

export function detailsProduct(data: ProductData) {
  return action(ProductActionTypes.DETAILS, {
    data,
  });
}

export function responseSuccess(data: ResponseProps) {
  return action(ProductActionTypes.RESPONSE_SUCCCES, {
    data,
  });
}

export function findProductFailure() {
  return action(ProductActionTypes.LOAD_FAILURE);
}
