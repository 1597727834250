import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface ProductsProps {
  id: string;
  short_description: string;
  price: string;
  image_url: string;
}

interface FilerProductByCatedoryBk {
  currentPage: string;
  data: ProductsProps[];
  firstPageURL: string;
  from: string;
  lastPage: string;
  lastPageURL: string;
  nextPageURL: string;
  path: string;
  perPage: string;
  prevPageURL: string;
  to: string;
  total: string;
}

interface FilerProductByCatedory {
  current_page: number;
  data: ProductsProps[];
  from: string;
  path: string;
  to: string;
  total: string;
}

interface FilerProductByCatedoryToPage {
  page?: number;
  sort?: string;
  order?: string;
  minvalue?: number;
  maxvalue?: number;
}

interface CategoryFilterProps {
  category: {
    id?: string;
    childrens?: string;
    category_name?: string;
  };
}

interface CategoryProps {
  id: string;
  short_description: string;
}

interface CategoryDataProps {
  category?: string;
  categorySub?: string;
}

interface ProductContextData {
  pageCurrent: number;
  setFilter(filter: CategoryFilterProps): Promise<void>;
  getCatedory(): Promise<CategoryProps[]>;
  getCatedorySub(categorys: CategoryDataProps): Promise<CategoryProps[]>;
  getProductList(
    page?: FilerProductByCatedoryToPage,
  ): Promise<FilerProductByCatedory>;
}

const ProductContext = createContext<ProductContextData>(
  {} as ProductContextData,
);

const ProductProvider: React.FC = ({ children }) => {
  const [pageCurrentData, setPageCurrentData] = useState(0);
  const [dataCategory, setDataCategory] = useState<CategoryDataProps>(() => {
    const category = localStorage.getItem('@ConectoVarejo:category');

    if (category) {
      return { category };
    }
    return {} as CategoryDataProps;
  });

  const [dataFilter, setDataFilter] = useState<CategoryFilterProps>(() => {
    const filter = localStorage.getItem('@ConectoVarejo:filter');

    if (filter) {
      return JSON.parse(filter);
    }

    console.log('dataFilter: ', filter);
    return {} as CategoryFilterProps;
  });

  const [dataCategorySub, setDataCategorySub] = useState<CategoryDataProps>(
    () => {
      const categorySub = localStorage.getItem('@ConectoVarejo:categorySub');

      if (categorySub) {
        return { categorySub };
      }
      return {} as CategoryDataProps;
    },
  );

  const getCatedory = useCallback(async () => {
    console.log('getCatedory after:', dataCategory.category);

    if (dataCategory.category) {
      return JSON.parse(dataCategory.category);
    }

    const response = await api.get('category/categories');

    localStorage.setItem(
      '@ConectoVarejo:category',
      JSON.stringify(response.data.data),
    );

    return response.data.data;
  }, []);

  const getCatedorySub = useCallback(async ({ categorySub }) => {
    console.log('getCatedorySub after:', dataCategorySub.categorySub);

    const response = await api.get(`category/categories/${categorySub}`);

    return response.data.data.childrens;
  }, []);

  const setFilter = useCallback(async ({ category }) => {
    console.log('setFilter...', category);
    await localStorage.setItem(
      '@ConectoVarejo:filter',
      JSON.stringify({ category }),
    );
    setDataFilter(category);
  }, []);

  const getProductList = useCallback(
    async ({ page, sort, order, minvalue, maxvalue }) => {
      console.log('getProductList...', dataFilter.category.childrens);

      if (minvalue == undefined) minvalue = 1;
      if (maxvalue == undefined) maxvalue = 99999;
      // const pageCurrent = await localStorage.getItem(
      //   '@ConectoVarejo:pageCurrent',
      // );
      // await localStorage.setItem('@ConectoVarejo:pageCurrent', page);

      // const pageCurrentSel = Number(pageCurrent) + 1;

      // await localStorage.setItem(
      //   '@ConectoVarejo:pageCurrent',
      //   JSON.stringify(pageCurrentSel),
      // );
      // console.log('getProductList: pageCurrentSel ', pageCurrentSel);

      const response = await api.get(
        // `product/search?category=000000000103&term=`,
        // `product/search?category=1&category=1&term=&limit=18&mode=&page=${pageCurrentSel}&sort=${sort}&order=${order}`,
        `product/search?category=1&term=&limit=18&mode=list&page=${page}&sort=${sort}&order=${order}&price=${minvalue},${maxvalue}`,
      );

      return response.data.data.products;
    },
    [],
  );

  return (
    <ProductContext.Provider
      value={{
        pageCurrent: pageCurrentData,
        setFilter,
        getCatedory,
        getCatedorySub,
        getProductList,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

function useProduct(): ProductContextData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be used within an ProductProvider');
  }
  return context;
}

export { ProductProvider, useProduct };
